import React, { useCallback, useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import styles from "./buffers.module.css";

import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import {
  addQueryParams,
  filterObject,
  isEmpty,
  removeQueryParams,
} from "../../utils/utils";

import { useSearchParams } from "react-router-dom";

import {
  Desktop,
  Mobile,
  Tablet,
} from "../../Componenets/react-responsive/responsiveDevices";
import ReactTable from "../../Componenets/ReactTable/ReactTable";

import { useSelector } from "react-redux";
import {
  useCreateBufferMutation,
  useGetBuffersQuery,
  useUpdateBufferMutation,
} from "../../service/buffersService";
import Pagination from "../../Componenets/Pagination/Pagination";
import { Add, EditIcon } from "../../Componenets/Icons/Icons";
import AddBuffers from "./AddBuffers";
import TableButton from "../../Componenets/TableButtons/TableButton";
import { IoMdAddCircle } from "react-icons/io";
import { SweetAlerts } from "../../Componenets/SweetAlerts/SweetAlerts";
import { useGetRegionsQuery } from "../../service/regionsService";

const MySwal = withReactContent(Swal);

const getDynamicUrl = (obj) => {
  const filterObj = filterObject(obj);
  let refresh = addQueryParams(filterObj);
  return refresh;
};

const BuffersScreen = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(50);
  const [filteredPosts, setFilteredPosts] = useState([]);
  // const [regions, setRegions] = useState();

  const region = useSelector((state) => state.region);

  // const [skip, setSkip] = useState(true);

  const {
    data: regionData,
    error: regionError,
    isLoading: regionLoading,
  } = useGetRegionsQuery();

  const [searchParams] = useSearchParams();
  const srchTxt = searchParams.get("searchText");
  const countCycleVal = searchParams.get("countCycle");

  const [searchText, setSearchText] = useState(srchTxt);
  const [countCycle, setCountCycle] = useState(countCycleVal);

  const { data, error, isFetching } = useGetBuffersQuery({
    region,
    url: getDynamicUrl({
      count_cycle: countCycle,
      search: searchText,
      page: currentPage,
      size: postsPerPage,
    }),
  });

  const [createBuffer, responseInfo] = useCreateBufferMutation();
  const [updateBuffer, updateInfo] = useUpdateBufferMutation();

  useEffect(() => {
    if (data && data.items) {
      if (data.items.length > 0) {
        setFilteredPosts([...data.items]);
      } else {
        setFilteredPosts([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const obj = {
      countCycle,
      searchText,
    };
    const filterObj = filterObject(obj);
    let url = window.location.href;
    url = removeQueryParams(url);
    let refresh = addQueryParams(filterObj, url);
    window.history.replaceState({ path: refresh }, "", refresh);
  }, [countCycle, searchText]);

  useEffect(() => {
    const obj = {
      searchText,
    };
    const filterObj = filterObject(obj);
    addQueryParams(filterObj);
  }, [searchText]);

  const manageBuffers = useCallback(
    (data, action = "add") => {
      MySwal.fire({
        title: action === "add" ? `Add Buffers` : "Edit Buffers",
        html: (
          <AddBuffers data={data} region={region} regionData={regionData} />
        ),
        confirmButtonText: `Save`,
        focusConfirm: false,
        showLoaderOnConfirm: true,
        showCloseButton: true,
        preConfirm: () => {
          const newBuffer = validateBuffers();
          if (newBuffer) {
            newBuffer.region_id = region;
            if (action === "add") {
              return createBuffer([newBuffer]).then((response) => {
                if (response.error) {
                  Swal.showValidationMessage(
                    `Request failed: ${response.error.data.detail}`
                  );
                }
              });
            } else {
              newBuffer.buffer_id = data.buffer_id;
              return updateBuffer(newBuffer).then((response) => {
                if (response.error) {
                  Swal.showValidationMessage(
                    `Request failed: ${response.error.data.detail}`
                  );
                }
              });
            }
          }
        },
      });
    },
    [createBuffer, region, regionData, updateBuffer]
  );

  useEffect(() => {
    SweetAlerts(responseInfo, `Buffer added successfully`);
  }, [responseInfo]);

  useEffect(() => {
    SweetAlerts(updateInfo, `Buffer updated successfully`);
  }, [updateInfo]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Buffer ID",
        accessor: "buffer_id",
      },
      {
        Header: "Count Cycle",
        accessor: "count_cycle",
      },
      {
        Header: "Days",
        accessor: "days",
      },
      {
        Header: "Source Inventory Id",
        accessor: "source_inventory_id",
      },
      {
        Header: "Store Id",
        accessor: "store_id",
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell }) => (
          <>
            <span
              onClick={() => manageBuffers(cell.row.original, "edit")}
              style={{ cursor: "pointer" }}
            >
              <EditIcon />
            </span>
          </>
        ),
        disableSortBy: true,
      },
    ],
    [manageBuffers]
  );

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Buffers"} />
        <div className="card-box">
          <Row className="mt-3 no-gutters">
            <Col lg={3} md={9} xs={6}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>

            <Col xs={3}>
              <label
                className={styles.distName}
                style={{ fontSize: "16px", fontWeight: 400 }}
              >
                Count Cycle
                <select
                  name="datatable_length"
                  aria-controls="datatable"
                  className={`custom-select custom-select-sm  form-control form-control-sm ${styles.countCycle}`}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setCountCycle(e.target.value);
                  }}
                  value={countCycle}
                >
                  <option value="">Select</option>
                  <option value="daily">Daily</option>
                  <option value="period">Period</option>
                  <option value="weekly">Weekly</option>
                  <option value="non recipe">Non Recipe</option>
                </select>
              </label>
            </Col>

            <Desktop>
              <Col xs={3}>
                <div className={styles.searchWrapper}>
                  <SearchFilter
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Desktop>

            <Mobile>
              <Col xs={12}>
                <div className="mt-3 mb-3">
                  <SearchFilter
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Mobile>
            <Col xs={3}>
              <Tablet>
                <TableButton handleClick={manageBuffers}>
                  <IoMdAddCircle /> <span> Add New</span>
                </TableButton>
              </Tablet>
              <Mobile>
                <div onClick={manageBuffers} className="addNewBtn">
                  <Add />
                </div>
              </Mobile>
            </Col>
          </Row>
          {isFetching || regionLoading ? (
            <Loader className="mt-2 " />
          ) : error || regionError ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="mt-3">
                  <ReactTable
                    columns={columns}
                    data={filteredPosts}
                    pageRows={postsPerPage}
                    totalPost={data && data.total}
                    showPagination={false}
                  />
                  <Pagination
                    onPageChange={setCurrentPage}
                    totalCount={data && data.total}
                    siblingCount={1}
                    currentPage={currentPage}
                    pageSize={postsPerPage}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

const validateBuffers = () => {
  const countCycle = document.getElementById("countCycle").value;
  const days = parseInt(document.getElementById("days").value, 10);
  // const region = parseInt(document.getElementById("regionId").value, 10);

  const sourceInventoryId = parseInt(
    document.getElementById("souceInventoryId").value,
    10
  );
  const storeId = parseInt(document.getElementById("storeId").value, 10);

  if (isEmpty(countCycle)) {
    return MySwal.showValidationMessage(`Please select count cycle`);
  }

  if (!days || isNaN(days)) {
    return MySwal.showValidationMessage(
      `Please enter a valid number for days count`
    );
  }
  // if (!region || isNaN(region)) {
  //   return MySwal.showValidationMessage(`Please select a region`);
  // }

  if (!storeId || isNaN(storeId)) {
    return MySwal.showValidationMessage(
      `Please enter a valid number for store id`
    );
  }

  const obj = {
    count_cycle: countCycle,
    store_id: storeId,
    days: days,
    // region_id: region,
    ...(!isNaN(sourceInventoryId) && {
      source_inventory_id: sourceInventoryId,
    }),
  };

  return obj;
};

export default BuffersScreen;
