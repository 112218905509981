import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpApi } from "../constants/constants";
import { userAccess } from "../utils/utils";

export const roStoresApi = createApi({
  reducerPath: "roStoresApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpApi,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  endpoints: (builder) => ({
    addWithUpdateRoStores: builder.mutation({
      query: ({ params, body }) => ({
        url: `supply/roactivation/?${params}`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "roStores", id: "roStores" }],
    }),
    getActivationStatusForStores: builder.query({
      query: (params) => `supply/activationstatus/?${params}`,
      refetchOnMountOrArgChange: true, // Add this line
      providesTags: ["roStores"],
    }),
  }),
});

export const {
  useAddWithUpdateRoStoresMutation,
  useLazyGetActivationStatusForStoresQuery,
} = roStoresApi;

//test
