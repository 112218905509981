import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const WarningSweetAlerts = (msg, type = "warning") => {
  return MySwal.fire(
    "",
    <p style={{ textAlign: "center" }}>
      {msg
        ? msg
        : " You don't have permission to perform this action. Please contact admin."}
    </p>,
    "warning"
  );
};
