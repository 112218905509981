import React from "react";
import { Col, Row } from "react-bootstrap";
import { DOTS, usePagination } from "../../utils/usePaginations";
import styles from "./pagination.module.css";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Row className="tble-footer-wrapper mt-3">
      <Col xs={12} sm={6} md={6}>
        <p className={styles.page_msg}>
          {" "}
          Showing {currentPage * pageSize - pageSize + 1} to{" "}
          {totalCount > currentPage * pageSize
            ? currentPage * pageSize
            : totalCount}{" "}
          of {totalCount} entries
        </p>
      </Col>
      <Col xs={6} sm={6} md={6} className="text-center">
        {/* {["sm"].map((breakpoint) => (
          <>
            <ListGroup
              key={breakpoint}
              horizontal={breakpoint}
              className={`my-2 ${styles.pagination_container}`}
            >
              <ListGroup.Item
                className={`${styles.pagination_item} ${
                  currentPage === 1 ? styles.disabled : ""
                }`}
                onClick={onPrevious}
              >
                &#8249; Prev
              </ListGroup.Item>
              {paginationRange.map((pageNumber) => {
                if (pageNumber === DOTS) {
                  return (
                    <ListGroup.Item
                      className={`dots ${styles.pagination_item}`}
                    >
                      {" "}
                      &#8230;
                    </ListGroup.Item>
                  );
                }

                return (
                  <ListGroup.Item
                    className={`${styles.pagination_item} ${
                      pageNumber === currentPage ? styles.selected : ""
                    }`}
                    onClick={() => onPageChange(pageNumber)}
                  >
                    {" "}
                    {pageNumber}
                  </ListGroup.Item>
                );
              })}
              <ListGroup.Item
                className={`${styles.pagination_item} ${
                  currentPage === lastPage ? styles.disabled : ""
                }`}
                onClick={onNext}
              >
                Next &#8250;
              </ListGroup.Item>
            </ListGroup> */}

        {/* </>
        ))} */}
        <ul className={styles.pagination_container}>
          <li
            // className={classnames(styles.pagination_item, {
            //   disabled: currentPage === 1,
            // })}
            className={`${styles.pagination_item} ${
              currentPage === 1 ? styles.disabled : ""
            }`}
            onClick={onPrevious}
          >
            &#8249; Prev
          </li>
          {paginationRange.map((pageNumber) => {
            if (pageNumber === DOTS) {
              return (
                <li
                  key={pageNumber}
                  className={`dots ${styles.pagination_item}`}
                >
                  &#8230;
                </li>
              );
            }

            return (
              <li
                className={`${styles.pagination_item} ${
                  pageNumber === currentPage ? styles.selected : ""
                }`}
                key={pageNumber}
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </li>
            );
          })}
          <li
            className={`${styles.pagination_item} ${
              currentPage === lastPage ? styles.disabled : ""
            }`}
            onClick={onNext}
          >
            Next &#8250;
          </li>
        </ul>
      </Col>
    </Row>
  );
};

export default Pagination;
