import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import { useLazyGetDayOrdersQuery } from "../../service/orderService";
import { isEmpty } from "../../utils/utils";

import exportFromJSON from "export-from-json";
import { BsCloudDownload } from "react-icons/bs";
import { filter } from "smart-array-filter";
import ReactTable from "../../Componenets/ReactTable/ReactTable";
import TableButton from "../../Componenets/TableButtons/TableButton";
import { setLineItemsTxt } from "../../service/filterSlice";

const LineItems = () => {
  const dispatch = useDispatch();
  // const tableRef = useRef(null);

  const { stores } = useParams();

  const [setCurrentPage] = useState(1);
  const [lineItems, setLineItems] = useState([]);
  // const [customLoading, setCustomLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const region = searchParams.get("region_id");
  const chainedStoreId = searchParams.get("chained_store_id");
  const distId = searchParams.get("distributor_id");
  const dType = searchParams.get("d_type");
  const orderDate = searchParams.get("order_date");
  const delDate = searchParams.get("delivery_date");

  // const [formattedDateRange, setFormattedDateRange] = useState([
  //   getTodayDate(),
  //   getTodayDate(),
  // ]);

  const { lineItemsTxt } = useSelector((state) => state.filterSlice);
  const orderData = useSelector((state) => state.orders);

  const [postsPerPage, setPostPerPage] = useState(20);
  const [filteredPosts, setFilteredPosts] = useState([]);

  const [searchText, setSearchText] = useState(lineItemsTxt);

  const [trigger, result] = useLazyGetDayOrdersQuery();

  const columns = React.useMemo(
    () => [
      {
        Header: "GIN",
        accessor: "GIN",
      },
      {
        Header: "	Item description",
        accessor: "DESCR",
      },
      {
        Header: "Quantity",
        accessor: "ORDER_QTY",
      },
      {
        Header: "JIT Start to JIT End Date",
        accessor: "",
        Cell: ({ cell }) =>
          `${cell.row.original.JIT_START_DATE}  -  ${cell.row.original.JIT_END_DATE}`,
      },

      {
        Header: "Buffer End Date",
        accessor: "BUFFER_END_DATE",
      },
      {
        Header: "JIT Depletions	",
        accessor: "JIT_DEPLETION",
        Cell: ({ cell }) =>
          cell.row.original.JIT_DEPLETION &&
          Number(cell.row.original.JIT_DEPLETION).toFixed(2),
      },
      {
        Header: "Total Depletions	",
        accessor: "DEPLETION",
        Cell: ({ cell }) =>
          cell.row.original.JIT_DEPLETION &&
          Number(cell.row.original.JIT_DEPLETION).toFixed(2),
      },
      {
        Header: "Count Cycle",
        accessor: "COUNT_CYCLE",
      },
      {
        Header: "Last Known Count",
        accessor: "LAST_NON_NULL_DATE",
      },
    ],
    []
  );

  useEffect(() => {
    if (orderData && orderData.length > 0) {
      const order = orderData.find(
        (item) =>
          item.chained_store_id === chainedStoreId &&
          item.d_type === dType &&
          item.delivery_date === delDate &&
          item.distributor_id === Number(distId) &&
          item.order_date === orderDate
      );
      // setFilteredPosts(order[0].line_items);

      setLineItems(order.line_items);
    } else {
      async function fetchMyAPI() {
        const { isSuccess, data } = await trigger({
          region,
          store: stores,
          startDate: orderDate.split("T")[0] ? orderDate.split("T")[0] : null,
        });

        if (isSuccess && data && data.length > 0) {
          const order = data.filter(
            (item) =>
              item.chained_store_id === chainedStoreId &&
              item.d_type === dType &&
              item.delivery_date === delDate &&
              item.distributor_id === Number(distId) &&
              item.order_date === orderDate
          );
          // setFilteredPosts(order[0].line_items);
          setLineItems(order[0].line_items);
        }
      }
      fetchMyAPI();
    }
  }, [
    chainedStoreId,
    dType,
    delDate,
    distId,
    orderData,
    orderDate,
    region,
    stores,
    trigger,
  ]);

  useEffect(() => {
    if (lineItems && lineItems.length > 0) {
      setFilteredPosts(lineItems);
    }
  }, [lineItems]);

  useEffect(() => {
    dispatch(setLineItemsTxt(searchText));
    if (!isEmpty(searchText) && lineItems && lineItems.length > 0) {
      const notificationsArr = [];
      lineItems.forEach((item) => notificationsArr.push(item));
      const result = notificationsArr.map((obj) =>
        Object.fromEntries(
          Object.entries(obj).map(([key, val]) => [key, String(val)])
        )
      );
      const matchingRes = filter(result, {
        keywords: searchText, // search for any field that contains the "Do" string
        includePaths: [
          "GIN",
          "DESCR",
          "ORDER_QTY",
          "JIT_START_DATE",
          "JIT_END_DATE",
          "BUFFER_END_DATE",
          "JIT_DEPLETION",
          "DEPLETION",
          "COUNT_CYCLE",
          "LAST_NON_NULL_DATE",
        ],
        excludedPaths: [],
        caseSensitive: false,
      });

      setFilteredPosts(matchingRes);
    } else if (isEmpty(searchText)) {
      setFilteredPosts(lineItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const onDownload = (e) => {
    e.preventDefault();
    const fileName = "Order Items";
    const exportType = exportFromJSON.types.csv;

    exportFromJSON({ data: lineItems, fileName, exportType });
  };

  return (
    <div className="mt-5">
      <Container>
        <PageTitle title={"Line Items"} />
        <div className="card-box">
          <Row className="mt-2 no-gutters">
            <Col xs={4}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>

            <Col xs={4}>
              <div style={{ float: "right" }}>
                <SearchFilter
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </div>
            </Col>
            <Col xs={4}>
              <Link
                title="Download"
                to={`#`}
                rel="noreferrer"
                style={{ margin: "5px" }}
                onClick={(e) => onDownload(e)}
              >
                <TableButton>
                  <BsCloudDownload style={{ marginRight: "3px" }} />
                  Download
                </TableButton>
              </Link>
            </Col>
          </Row>
          {result.status === "pending" ? (
            <Loader />
          ) : result.status === "rejected" ? (
            <>
              <Alert variant="danger mt-4">{result.error.data.detail}</Alert>
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="mt-2">
                  <ReactTable
                    columns={columns}
                    data={filteredPosts}
                    pageRows={postsPerPage}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

export default LineItems;
