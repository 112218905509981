// import {
//   BarElement,
//   CategoryScale,
//   Chart as ChartJS,
//   Legend,
//   LinearScale,
//   PointElement,
//   Title,
//   Tooltip,
// } from "chart.js";
import React, { useCallback, useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
// import { Bar, getElementAtEvent } from "react-chartjs-2";
// import DatePicker from "react-datepicker";
import { BsArrowRightShort } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
import {
  Analysis,
  RemoveBookmark,
  SupplyDemand,
} from "../../Componenets/Icons/Icons";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import TableButton from "../../Componenets/TableButtons/TableButton";
import TableTitle from "../../Componenets/TableTitle/TableTitle";
import {
  useGetBookmarkedStoresQuery,
  useRemoveBookmarkMutation,
} from "../../service/bookmarksService";
// import { useGetNotificationsQuery } from "../../service/notificationsService";
// import { useGetStoresQuery } from "../../service/storeService";
import { convert, getTodayDate } from "../../utils/utils";
import styles from "./homescreen.module.css";
// import NotificationTable from "./NotificationTable";

import Cookies from "js-cookie";
import "react-datepicker/dist/react-datepicker.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
// import { store } from "../../app/store";
// import {
//   Mobile,
//   Tablet,
// } from "../../Componenets/react-responsive/responsiveDevices";
import { saveDashboardDate } from "../../service/filterSlice";
import ReactTable from "../../Componenets/ReactTable/ReactTable";

// const MySwal = withReactContent(Swal);

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   PointElement
// );

const HomeScreen = (props) => {
  const region = useSelector((state) => state.region);
  // const chartRef = useRef();
  // const [bookmarkedStores, setBookmarkedStores] = useState([]);
  const [paramsChanged, setParamsChanged] = useState(false);
  const { dashboardDate } = useSelector((state) => state.filterSlice);
  const dispatch = useDispatch();

  const [barData, setBarData] = useState();
  const [skip, setSkip] = useState(true);
  const [pollingInterval, setPollingInterval] = useState(60000);

  const [todayDate, setTodayDate] = useState(dashboardDate);
  const [date, setDate] = useState(getTodayDate());
  // const [maxUpdatedTime, setMaxUpdatedTime] = useState("");

  const userManagementId = Cookies.get("user_management_id");

  useEffect(() => {
    if (userManagementId && region) setSkip(false);
  }, [userManagementId, region]);

  useEffect(() => {
    if (region) {
      setParamsChanged(true);
    }
  }, [region]);

  useEffect(() => {
    dispatch(saveDashboardDate(todayDate));
    let now = new Date();
    const today = new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    );
    setBarData(undefined);
    setDate(convert(todayDate));
    if (new Date(todayDate).getTime() < today.getTime()) {
      setPollingInterval(0);
    } else {
      setPollingInterval(60000);
    }
  }, [dispatch, todayDate]);

  // const {
  //   data: storeData,
  //   isFetching: storeFetching,
  //   error: storeError,
  // } = useGetStoresQuery(region);

  const {
    data: bkData,
    isFetching,
    error,
  } = useGetBookmarkedStoresQuery(
    { region, userId: userManagementId },
    { skip }
  );

  // const {
  //   data: notificationData,
  //   error: notificationError,
  //   isLoading: notificationLoading,
  //   isFetching: notificationFetching,
  // } = useGetNotificationsQuery(
  //   { region, date },
  //   { pollingInterval: pollingInterval }
  // );

  // useEffect(() => {
  //   if (!notificationFetching) {
  //     setParamsChanged(false);
  //   }
  // }, [notificationFetching]);

  const [removeBk] = useRemoveBookmarkMutation();

  const removeBookmark = useCallback(
    (id) => {
      removeBk({
        type: "stores",
        value: id,
        user_management_id: userManagementId,
        region,
      });
    },
    [region, removeBk, userManagementId]
  );

  // useEffect(() => {
  //   if (notificationData && notificationData.length > 0) {
  //     let mostRecentDate = new Date(
  //       Math.max.apply(
  //         null,
  //         notificationData.map((e) => {
  //           return new Date(e.update_date_time);
  //         })
  //       )
  //     );

  //     const maxDate = notificationData.filter((e) => {
  //       let d = new Date(e.update_date_time);
  //       return d.getTime() === mostRecentDate.getTime();
  //     })[0];

  //     if (!maxUpdatedTime || maxUpdatedTime !== maxDate.update_date_time) {
  //       setMaxUpdatedTime(maxDate.update_date_time);

  //       let labels = [];
  //       let datasets = [
  //         {
  //           borderColor: "rgba(0,0,0,0)",
  //           borderWidth: 10,
  //           barThickness: 110,
  //           maxBarThickness: 120,
  //           label: "Running",
  //           data: [],
  //           backgroundColor: "#258cf9",
  //           stack: "Stack 1",
  //         },
  //         {
  //           borderColor: "rgba(0,0,0,0)",
  //           borderWidth: 10,
  //           barThickness: 110,
  //           maxBarThickness: 140,
  //           label: "Completed",
  //           data: [],
  //           backgroundColor: "#3e913a",
  //           stack: "Stack 2",
  //         },
  //         {
  //           borderColor: "rgba(0,0,0,0)",
  //           borderWidth: 10,
  //           barThickness: 110,
  //           maxBarThickness: 140,
  //           label: "Failed",
  //           data: [],
  //           backgroundColor: "#dd3b3b",
  //           stack: "Stack 3",
  //         },
  //       ];
  //       const obj = {};
  //       notificationData.forEach((item, i) => {
  //         if (!obj[item.topic]) {
  //           obj[item.topic] = {};
  //         }
  //         if (!labels.includes(item.topic)) labels.push(item.topic);

  //         obj[item.topic]["running"] =
  //           item.running +
  //           (obj[item.topic].running ? obj[item.topic].running : 0);

  //         obj[item.topic]["completed"] =
  //           item.completed +
  //           (obj[item.topic].completed ? obj[item.topic].completed : 0);

  //         obj[item.topic]["failed"] =
  //           item.failed + (obj[item.topic].failed ? obj[item.topic].failed : 0);
  //       });
  //       for (var prop in obj) {
  //         datasets[0].data.push(obj[prop].running);
  //         datasets[1].data.push(obj[prop].completed);
  //         datasets[2].data.push(obj[prop].failed);
  //       }

  //       setBarData({ labels, datasets });
  //     }
  //   } else if (notificationData && notificationData.length === 0) {
  //     setBarData([]);
  //   }
  // }, [maxUpdatedTime, notificationData]);

  // store data filter
  // useEffect(() => {
  //   if (storeData && storeData.length > 0 && bkData && bkData.length > 0) {
  //     // eslint-disable-next-line array-callback-return
  //     const bkStores = storeData.filter((item) => {
  //       if (bkData.includes(item.store_id)) {
  //         return item;
  //       }
  //     });
  //     setBookmarkedStores(bkStores);
  //   } else if (
  //     storeData &&
  //     storeData.length > 0 &&
  //     bkData &&
  //     bkData.length === 0
  //   ) {
  //     setBookmarkedStores([]);
  //   }
  // }, [bkData, storeData]);

  // const onClick = (event) => {
  //   const element = getElementAtEvent(chartRef.current, event);

  //   // const status =
  //   //   barData.datasets[element[0].datasetIndex].label.toLowerCase() === "failed"
  //   //     ? "Hung"
  //   //     : barData.datasets[element[0].datasetIndex].label.toLowerCase();

  //   const topic = barData && barData.labels[element[0].index];

  //   const notifications = notificationData.filter(
  //     (item) => item.topic.toLowerCase() === topic.toLowerCase()
  //   );

  //   MySwal.fire({
  //     html: (
  //       <Provider store={store}>
  //         <NotificationTable
  //           notifications={notifications}
  //           region={region}
  //           handleClose={handleClose}
  //         />
  //       </Provider>
  //     ),
  //     showCloseButton: true,
  //     focusConfirm: false,
  //     allowOutsideClick: true,
  //     showConfirmButton: false,
  //     preConfirm: () => {},
  //     customClass: {
  //       container: styles.notification_table,
  //       closeButton: styles.close_btn,
  //     },
  //   });
  // };

  const navigate = useNavigate();

  // const handleClose = (id) => {
  //   Swal.close();
  //   navigate(`/config-editor/${region}/${id}`);
  // };

  // const options = {
  //   indexAxis: "y",
  //   events: ["mousemove", "mouseout", "click", "touchstart", "touchmove"],

  //   plugins: {
  //     title: {
  //       display: true,
  //     },
  //     tooltip: {
  //       position: "nearest",
  //     },
  //     legend: {
  //       display: true,

  //       position: "bottom",
  //     },
  //   },
  //   responsive: true,
  //   interaction: {
  //     mode: "index",
  //   },
  // };

  const oneWeekFromNow = new Date();
  oneWeekFromNow.setDate(oneWeekFromNow.getDate() - 6);

  const columns = React.useMemo(
    () => [
      {
        Header: "Chain ID	",
        accessor: "chained_store_id",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Franchise",
        accessor: "franchisee",
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell }) => (
          <>
            <RemoveBookmark
              handleClick={removeBookmark}
              id={cell.row.original.store_id}
            />
            <Analysis />
            <SupplyDemand
              link={`/stores/${region}/${cell.row.original.chained_store_id}/`}
            />
          </>
        ),
        disableSortBy: true,
      },
    ],
    [region, removeBookmark]
  );

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <Row>
          <div xs={12}>
            <PageTitle isHome="true" title={"Dashboard"} />
            <Alert variant="primary mt-4">No notifications to show</Alert>
            {/* <div className="card-box"> */}
            {/* <Row>
                <Col xs={10}></Col>
                <Tablet>
                  <Col xs={3} sm={2} className="text-end">
                    <DatePicker
                      selected={todayDate}
                      onChange={(date) => setTodayDate(date)}
                      className={"datePickerIp"}
                      maxDate={new Date()}
                      minDate={oneWeekFromNow}
                    />
                  </Col>
                </Tablet>
              </Row> */}
            {/* {notificationLoading || !barData || paramsChanged ? (
                <Loader />
              ) : notificationError ? (
                <>
                  <Alert variant="danger mt-4">
                    Some Error occured. Please contant admin
                  </Alert>
                </>
              ) : ( */}
            {/* <Col> */}
            {/* <div className="row">
                    <div className="col-12">
                      <Tablet>
                        {notificationData &&
                          notificationData.length > 0 &&
                          barData &&
                          Object.keys(barData).length > 0 && (
                            <Bar
                              type="bar"
                              datasetIdKey="id"
                              ref={chartRef}
                              options={options}
                              data={barData}
                              onClick={onClick}
                            />
                          )}
                        {notificationData &&
                          Object.keys(notificationData).length === 0 && (
                            <Alert variant="primary mt-4">
                              No cron job found for this date
                            </Alert>
                          )}
                      </Tablet>
                      <Mobile>
                        <NotificationTable
                          notifications={notificationData}
                          region={region}
                          handleClose={handleClose}
                          mobile={true}
                          todayDate={todayDate}
                          setTodayDate={setTodayDate}
                          oneWeekFromNow={oneWeekFromNow}
                        />
                      </Mobile>
                    </div>
                  </div> */}
            {/* </Col> */}
            {/* )} */}
            {/* </div> */}
          </div>
        </Row>

        <Row className="mt-5">
          <Col xs={12} md={6}>
            <div className="card-box">
              <Row>
                <Col xs={10} className="align-self-center">
                  <TableTitle title={"Bookmarked Stores"} />
                </Col>
                <Col xs={2}>
                  <Link to="/stores">
                    <TableButton>
                      <span>View All</span>
                      <BsArrowRightShort />
                    </TableButton>
                  </Link>
                </Col>
              </Row>
              {isFetching ? (
                <Loader />
              ) : error ? (
                <>
                  <Alert variant="danger mt-4">
                    Some Error occured. Please contant admin
                  </Alert>
                </>
              ) : bkData && bkData.length > 0 ? (
                <div
                  className={`table-responsive mt-3 ${styles.bookmark_wrapper}`}
                >
                  <ReactTable
                    columns={columns}
                    data={bkData}
                    pageRows={10000}
                    showPagination={false}
                  />
                </div>
              ) : (
                <Alert variant="primary mt-4">
                  No bookmarks found for this region
                </Alert>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeScreen;
