import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpBaseUrl } from "../constants/constants";

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: ({ region, date }) =>
        `notification/processes?region_id=${region}&date=${date}`,
      providesTags: (result) => {
        const res =
          result && result.length > 0
            ? [
                ...result.map(({ process_id }) => ({
                  type: "Notifications",
                  process_id,
                })),
                { type: "Notifications", process_id: "LIST" },
              ]
            : [{ type: "Notifications", process_id: "LIST" }];

        return res;
      },
    }),
  }),
});

export const { useGetNotificationsQuery } = notificationsApi;
