import React, { useEffect, useRef, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import { useGetSubNotificationsQuery } from "../../service/supplyDmdNotificationService";
import { convert, getTodayDate } from "../../utils/utils";
import styles from "./supplydemand.module.css";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import Loader from "../../Componenets/Loader/Loader";
import {
  Mobile,
  Tablet,
} from "../../Componenets/react-responsive/responsiveDevices";
import { saveSupplyDate } from "../../service/filterSlice";
import NotificationTable from "./NotificationTable";
import TableButton from "../../Componenets/TableButtons/TableButton";

const MySwal = withReactContent(Swal);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement
);

const SupplyDemand = () => {
  const region = useSelector((state) => state.region);
  const chartRef = useRef();
  const [pollingInterval, setPollingInterval] = useState(60000);
  const { supplyDmdDate } = useSelector((state) => state.filterSlice);
  const dispatch = useDispatch();
  const [barData, setBarData] = useState();
  const [todayDate, setTodayDate] = useState(supplyDmdDate);
  const [date, setDate] = useState(getTodayDate());
  const [paramsChanged, setParamsChanged] = useState(false);

  const [maxUpdatedTime, setMaxUpdatedTime] = useState("");

  useEffect(() => {
    if (region) {
      setParamsChanged(true);
    }
  }, [region]);

  useEffect(() => {
    dispatch(saveSupplyDate(todayDate));
    let now = new Date();
    const today = new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    );
    setBarData(undefined);
    setDate(convert(todayDate));
    if (new Date(todayDate).getTime() < today.getTime()) {
      setPollingInterval(0);
    } else {
      setPollingInterval(60000);
    }
  }, [dispatch, todayDate]);

  const {
    data: notificationData,
    error: notificationError,
    isLoading: notificationLoading,
    isFetching: notificationFetching,
  } = useGetSubNotificationsQuery(
    { region, date },
    { pollingInterval: pollingInterval }
  );

  useEffect(() => {
    if (!notificationFetching) {
      setParamsChanged(false);
    }
  }, [notificationFetching]);

  useEffect(() => {
    console.log(notificationData);
    if (
      notificationData &&
      Object.keys(notificationData).length > 0 &&
      notificationData.data &&
      notificationData.data.length > 0
    ) {
      // let mostRecentDate = new Date(
      //   Math.max.apply(
      //     null,
      //     notificationData.map((e) => {
      //       return new Date(e.update_date_time);
      //     })
      //   )
      // );

      // const maxDate = notificationData.filter((e) => {
      //   let d = new Date(e.update_date_time);
      //   return d.getTime() === mostRecentDate.getTime();
      // })[0];

      if (
        !maxUpdatedTime ||
        maxUpdatedTime !== notificationData.max_update_date
      ) {
        setMaxUpdatedTime(notificationData.max_update_date);
        setParamsChanged(false);
        let labels = [];
        let datasets = [
          {
            borderColor: "rgba(0,0,0,0)",
            borderWidth: 10,
            barThickness: 110,
            maxBarThickness: 120,
            label: "Running",
            data: [],
            backgroundColor: "#258cf9",
            stack: "Stack 1",
          },
          {
            borderColor: "rgba(0,0,0,0)",
            borderWidth: 10,
            barThickness: 110,
            maxBarThickness: 140,
            label: "Completed",
            data: [],
            backgroundColor: "#3e913a",
            stack: "Stack 2",
          },
          {
            borderColor: "rgba(0,0,0,0)",
            borderWidth: 10,
            barThickness: 110,
            maxBarThickness: 140,
            label: "Failed",
            data: [],
            backgroundColor: "#dd3b3b",
            stack: "Stack 3",
          },
        ];
        const obj = {};

        notificationData.data.forEach((item, i) => {
          if (!obj[item.topic]) {
            obj[item.topic] = {};
          }

          if (!labels.includes(item.topic)) labels.push(item.topic);

          if (item.status === "Failed") {
            obj[item.topic]["failed"] =
              1 + (obj[item.topic].failed ? obj[item.topic].failed : 0);
          }
          if (item.status === "Completed") {
            obj[item.topic]["completed"] =
              1 + (obj[item.topic].completed ? obj[item.topic].completed : 0);
          }
          if (item.status === "Running") {
            obj[item.topic]["running"] =
              1 + (obj[item.topic].running ? obj[item.topic].running : 0);
          }
        });
        for (let prop in obj) {
          datasets[0].data.push(obj[prop].running);
          datasets[1].data.push(obj[prop].completed);
          datasets[2].data.push(obj[prop].failed);
        }

        setBarData({ labels, datasets });
      }
    } else if (
      notificationData &&
      notificationData.data &&
      notificationData.data.length === 0
    ) {
      console.log("empty");
      setBarData([]);
    }
  }, [maxUpdatedTime, notificationData]);

  const onClick = (event) => {
    const element = getElementAtEvent(chartRef.current, event);
    const status = barData && barData.datasets[element[0].datasetIndex].label;
    const notifications = notificationData.data.filter(
      (item) => item.status === status
    );
    MySwal.fire({
      html: (
        <NotificationTable
          notifications={notifications}
          region={region}
          handleClose={handleClose}
        />
      ),
      showCloseButton: true,
      focusConfirm: false,
      allowOutsideClick: true,
      showConfirmButton: false,

      preConfirm: () => {},
      customClass: {
        container: styles.notification_table,
        closeButton: styles.close_btn,
      },
    });
  };
  const navigate = useNavigate();
  const handleClose = (id, type) => {
    Swal.close();
    if (type === "config") navigate(`/config-editor/${region}/${id}`);
    else if (type === "stores") navigate(`/stores/${region}/${id}`);
  };

  const options = {
    // indexAxis: "y",
    type: "bar",
    events: ["mousemove", "mouseout", "click", "touchstart", "touchmove"],

    plugins: {
      title: {
        display: true,
        text: "Supply Demand Notifications",
      },
      tooltip: {
        position: "nearest",
      },
      legend: {
        display: true,
        position: "bottom",
      },
    },
    responsive: true,
    interaction: {
      mode: "index",
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const oneWeekFromNow = new Date();
  oneWeekFromNow.setDate(oneWeekFromNow.getDate() - 60);

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Recomended Ordering"} />
        <Row>
          <div className="card-box">
            <Row>
              <Col xs={10}></Col>
              <Tablet>
                <Col xs={3} sm={2} className="text-end">
                  <DatePicker
                    selected={todayDate}
                    onChange={(date) => setTodayDate(date)}
                    className={"datePickerIp"}
                    maxDate={new Date()}
                    minDate={oneWeekFromNow}
                  />
                </Col>
              </Tablet>
            </Row>
            {notificationLoading || !barData || paramsChanged ? (
              <Loader />
            ) : notificationError ? (
              <>
                <Alert variant="danger mt-4">
                  Some Error occured. Please contant admin
                </Alert>
              </>
            ) : (
              <Col>
                <div className="row">
                  <div className="col-12">
                    <Tablet>
                      {notificationData &&
                        notificationData.data &&
                        notificationData.data.length > 0 &&
                        barData &&
                        Object.keys(barData).length > 0 && (
                          <Bar
                            type="bar"
                            datasetIdKey="id"
                            ref={chartRef}
                            options={options}
                            data={barData}
                            onClick={onClick}
                          />
                        )}

                      {notificationData &&
                        notificationData.data.length === 0 && (
                          <Alert variant="primary mt-4">
                            No cron job found for this date
                          </Alert>
                        )}
                    </Tablet>
                    <Mobile>
                      <NotificationTable
                        notifications={notificationData.data}
                        region={region}
                        handleClose={handleClose}
                        mobile={true}
                        todayDate={todayDate}
                        setTodayDate={setTodayDate}
                        oneWeekFromNow={oneWeekFromNow}
                      />
                    </Mobile>
                  </div>
                </div>
              </Col>
            )}
          </div>
        </Row>

        <div className="mt-5">
          <div className="card-box">
            <Row className="no-gutters">
              <Col
                xs={12}
                className={`action-links ${styles.store_act_btns}`}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <TableButton>
                  <Link to={`/supply-demand/${region}/active-stores`}>
                    <span>Active Stores</span>
                  </Link>
                </TableButton>
                <TableButton>
                  <Link to={`/${region}/distributors`}>
                    <span>Distributors</span>
                  </Link>
                </TableButton>
                <TableButton>
                  <Link to={`/${region}/buffers `}>
                    <span>Buffers</span>
                  </Link>
                </TableButton>
                <TableButton>
                  <Link to={`/ro `}>
                    <span>Manage RO Stores</span>
                  </Link>
                </TableButton>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SupplyDemand;
