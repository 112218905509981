import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dfpBaseUrl } from "../constants/constants";
import Cookies from "js-cookie";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },

    credentials: "include",
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => `user_management/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "User", id })),
              { type: "User", id: "UserList" },
            ]
          : [{ type: "User", id: "UserList" }],
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: `user_management/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "User", id: "UserList" }],
    }),
    updateUser: builder.mutation({
      query: ({ body, id }) => ({
        url: `user_management/${encodeURIComponent(id)}`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "User", id: "UserList" }],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `user_management/${encodeURIComponent(id)}/`,
        method: "DELETE",
        // body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "User", id: "UserList" }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi;
