import React from "react";
import styles from "./tablebutton.module.css";
const TableButton = ({ children, handleClick }) => {
  return (
    <button
      type="button"
      className={`btn ${styles.tableButton}`}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default TableButton;
