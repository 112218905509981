import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import styles from "./distributor.module.css";

const SupplyAddDistributor = ({ distributor = {}, kind = "" }) => {
  return (
    <Form>
      <Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          className={"mb-3"}
          controlId="distName"
        >
          <Form.Label>Distributor Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter distributor name"
            defaultValue={distributor.distributor_name}
          />
          <span className={styles.errorMsg} id="distErr"></span>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group
          as={Col}
          xs={12}
          className={"mb-3"}
          controlId="distributorKind"
        >
          <Form.Label>Distributor Kind</Form.Label>
          <Form.Select defaultValue={kind}>
            <option value="">Select</option>
            <option value="Integrated">Integrated</option>
            <option value="Non-Integrated">Non-Integrated</option>
          </Form.Select>
          <span className={styles.errorMsg} id="distKind"></span>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group
          as={Col}
          xs={12}
          className={"mb-3"}
          controlId="sourceDistId"
        >
          <Form.Label>Source Distributor Id</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter source distributor Id"
            defaultValue={distributor.source_distributor_id}
          />
        </Form.Group>
      </Row>
    </Form>
  );
};

export default SupplyAddDistributor;
