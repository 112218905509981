import React from "react";
import { Mobile, Tablet } from "../react-responsive/responsiveDevices";
import TableButton from "../TableButtons/TableButton";
import { IoMdAddCircle } from "react-icons/io";
import { Add } from "../Icons/Icons";

const AddNewButton = ({ addNewTxt = "Add New", addNew }) => {
  return (
    <>
      <Tablet>
        <TableButton handleClick={addNew}>
          <IoMdAddCircle /> <span> {addNewTxt}</span>
        </TableButton>
      </Tablet>
      <Mobile>
        <div className="addNewBtn">
          <Add handleClick={addNew} />
        </div>
      </Mobile>
    </>
  );
};

export default AddNewButton;
