import React from "react";
import { Row } from "react-bootstrap";
import styles from "./loader.module.css";

const Loader = () => {
  return (
    <Row>
      <div
        className={`spinner-border text-primary ${styles.dfp_loader}`}
        role="status"
      ></div>
    </Row>
  );
};

export default Loader;
