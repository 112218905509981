import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDeleteDistributorMutation } from "../../service/distributorService";
import { useSearchParams } from "react-router-dom";
import {
  addParams,
  checkWriteAccess,
  convertArrValToString,
  isEmpty,
} from "../../utils/utils";
import { filter } from "smart-array-filter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { WarningSweetAlerts } from "../../Componenets/SweetAlerts/WarningSweetAlerts";
import { DeleteIcon } from "../../Componenets/Icons/Icons";
import { Row, Col, Alert } from "react-bootstrap";

import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import {
  Desktop,
  Mobile,
} from "../../Componenets/react-responsive/responsiveDevices";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import Loader from "../../Componenets/Loader/Loader";
import ReactTable from "../../Componenets/ReactTable/ReactTable";
import styles from "./distributordetails.module.css";

const MySwal = withReactContent(Swal);

const DistributorStores = ({
  distFetching,
  distStoresErr,
  distStores,
  region,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(20);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const { userAccess } = useSelector((state) => state);
  const [deleteDistributor] = useDeleteDistributorMutation();

  const [searchParams] = useSearchParams();
  const srchTxt = searchParams.get("searchText");

  const [searchText, setSearchText] = useState(srchTxt);

  const getSearchedPosts = (data) => {
    const result = convertArrValToString(data);
    let matchingRes = filter(result, {
      keywords: searchText,
      includePaths: ["CHAINED_STORE_ID", "CITY", "FRANCHISEE"],
      excludedPaths: [],
      caseSensitive: false,
    });
    setFilteredPosts(matchingRes);
  };

  useEffect(() => {
    setCurrentPage(1);
    addParams(searchText);

    if (distStores) {
      if (!isEmpty(searchText) && distStores.length > 0) {
        getSearchedPosts(distStores);
      } else if (distStores.length > 0) {
        setFilteredPosts([...distStores]);
      } else {
        setFilteredPosts([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distStores, searchText]);

  const handleDelete = useCallback(
    (store, distributor_id) => {
      checkWriteAccess(userAccess, "stores")
        ? MySwal.fire({
            title: "Are you sure?",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
            showCloseButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              deleteDistributor({
                store,
                distributorId: distributor_id,
                regionId: region,
              });
            }
          })
        : WarningSweetAlerts(
            "You don't have permission to delete any user. Please contact admin"
          );
    },
    [deleteDistributor, userAccess]
  );

  const storeColumns = React.useMemo(
    () => [
      {
        Header: "Chain ID",
        accessor: "CHAINED_STORE_ID",
      },
      {
        Header: "City",
        accessor: "CITY",
      },
      {
        Header: "Franchise",
        accessor: "FRANCHISEE",
      },

      {
        Header: "Action",
        Cell: ({ cell }) => (
          <>
            <span
              onClick={() =>
                handleDelete(
                  cell.row.original.STORE_ID,
                  cell.row.original.DISTRIBUTOR_ID
                )
              }
              style={{ margin: "5px", cursor: "pointer" }}
            >
              <DeleteIcon id={cell.row.original.yum_id} />
            </span>
          </>
        ),
        disableSortBy: true,
      },
    ],
    [handleDelete]
  );

  return (
    <Row className="mt-2 no-gutters row">
      <Col xs={3}>
        <PostPerPage
          limit={postsPerPage}
          setPostPerPage={setPostPerPage}
          setCurrentPage={setCurrentPage}
        />
      </Col>

      <Desktop>
        <Col xs={9}>
          <div className={styles.searchWrapper}>
            <SearchFilter
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
        </Col>
      </Desktop>

      <Mobile>
        <Col xs={12}>
          <div className="mt-3 mb-3">
            <SearchFilter
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
        </Col>
      </Mobile>
      <Col xs={12} md={12}>
        <Col className="mt-3 ">
          {distFetching ? (
            <Loader className="mt-2" />
          ) : distStoresErr ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <ReactTable
              columns={storeColumns}
              data={filteredPosts ? filteredPosts : []}
              pageRows={postsPerPage}
            />
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default DistributorStores;
