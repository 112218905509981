import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import styles from "./orders.module.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../Componenets/Loader/Loader";

import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";

import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { filter } from "smart-array-filter";
import { Tablet } from "../../Componenets/react-responsive/responsiveDevices";
import { useGetOrdersQuery } from "../../service/orderService";
import { addParams, convert, getTodayDate, isEmpty } from "../../utils/utils";

import {
  saveOrderTxt,
  setDeliveryRange,
  setDistName,
  setOrderRange,
} from "../../service/filterSlice";
import ReactTable from "../../Componenets/ReactTable/ReactTable";
import { saveOrders } from "../../service/orderSlice";
const animatedComponents = makeAnimated();

const OrdersScreen = () => {
  const dispatch = useDispatch();
  const [setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(10);
  const [skip, setSkip] = useState(true);

  const { stores } = useParams();

  const [filteredPosts, setFilteredPosts] = useState([]);

  const { orderDate } = useSelector((state) => state.filterSlice.ordersFilters);
  const { delDate } = useSelector((state) => state.filterSlice.ordersFilters);

  const { distributorName } = useSelector(
    (state) => state.filterSlice.ordersFilters
  );

  const [searchParams] = useSearchParams();

  const srchTxt = searchParams.get("searchText");
  const delDateVal = searchParams.get("delDate");
  const orderDateVal = searchParams.get("orderDate");
  const distributorId = searchParams.get("distributorId");

  const { orderSearchTxt } = useSelector((state) => state.filterSlice);

  const [searchText, setSearchText] = useState(
    srchTxt ? srchTxt : orderSearchTxt
  );

  const region = useSelector((state) => state.region);

  const [formattedDateRange, setFormattedDateRange] = useState([
    getTodayDate(),
    getTodayDate(),
  ]);

  const convertDate = (date) => {
    if (date !== ",") {
      const newDate = date.split(",");
      return [new Date(newDate[0]), new Date(newDate[1])];
    }
  };

  const [dateRange, setDateRange] = useState(
    orderDateVal && orderDateVal !== "," ? convertDate(orderDateVal) : orderDate
  );

  const [deliveryDateRange, setDeliveryDateRange] = useState(
    delDateVal && delDateVal !== "," ? convertDate(delDateVal) : delDate
  );

  const [distributorNameOpt, setDistributorNameOpt] = useState([]);
  const [distributorNameVal, setDistributorNameVal] = useState(distributorName);

  const [deliveryStartDate, deliveryEndDate] = deliveryDateRange;

  const [startDate, endDate] = dateRange;

  useEffect(() => {
    delDateVal && dispatch(setDeliveryRange(convertDate(delDateVal)));
    orderDateVal && dispatch(setOrderRange(convertDate(orderDateVal)));
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const dateRange = [convert(startDate), convert(endDate)];
      setFormattedDateRange(dateRange);
    }
    if (orderDate[0] && orderDate[1]) {
      if (delDate[0] && delDate[1]) {
        addParams(searchText, null, delDate, orderDate);
      }
      addParams(searchText, null, null, orderDate);
    } else {
      if (delDate[0] && delDate[1]) addParams(searchText, null, delDate, "");
      else addParams(searchText, null, "", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDate, endDate, startDate]);

  useEffect(() => {
    if (region && stores && formattedDateRange[0] && formattedDateRange[1]) {
      setSkip(false);
    }
  }, [formattedDateRange, region, stores]);

  const { data, error, isFetching } = useGetOrdersQuery(
    {
      region,
      store: stores,
      startDate: formattedDateRange[0],
      endDate: formattedDateRange[1],
    },
    {
      skip: skip,
    }
  );

  useEffect(() => {
    // searchText && addSearchParams(searchText);
    if (searchText) {
      // addSearchParams(searchText);
      if (delDate[0] && delDate[1]) {
        if (orderDate[0] && orderDate[1]) {
          addParams(searchText, null, delDate, orderDate);
        } else addParams(searchText, null, delDate, "");
      } else {
        if (orderDate[0] && orderDate[1]) {
          addParams(searchText, null, "", orderDate);
        } else addParams(searchText, null, "", "");
      }
    }
    dispatch(saveOrderTxt(searchText));
    dispatch(saveOrders(data));
    if (data && data.length > 0 && distributorNameOpt.length === 0) {
      const distributerNamesOption = [];
      data.forEach((x) => {
        if (
          !distributerNamesOption.some(
            (y) =>
              JSON.stringify(y.value) === JSON.stringify(x.distributor_name)
          )
        ) {
          distributerNamesOption.push({
            label: x.distributor_name,
            value: x.distributor_id,
          });
        }
      });
      setDistributorNameOpt(distributerNamesOption);
    }
    if (
      data &&
      data.length > 0 &&
      ((deliveryStartDate && deliveryEndDate) ||
        !isEmpty(searchText) ||
        distributorNameVal.length > 0)
    ) {
      let dataCopy = [...data];

      if (deliveryStartDate && deliveryEndDate) {
        dataCopy = dataCopy.filter((d) => {
          let time = new Date(d.delivery_date).getTime();

          let startTime = new Date(convert(deliveryStartDate)).getTime();
          let endTime = new Date(convert(deliveryEndDate)).getTime();
          return time >= startTime && time <= endTime;
        });
      }

      if (!isEmpty(searchText)) {
        const newSearchText = searchText.trim();
        dataCopy = filter(dataCopy, {
          keywords: newSearchText,
          includePaths: [
            "order_date",
            "distributor_name",
            "delivery_date",
            "d_type",
          ],
          excludedPaths: [],
          caseSensitive: false,
        });
      }

      if (distributorNameVal.length > 0) {
        const distributorNames = distributorNameVal.map((item) => item.value);
        console.log(distributorNames, dataCopy);
        dataCopy = dataCopy.filter((item) =>
          distributorNames.includes(item.distributor_id)
        );
      }
      setFilteredPosts([...dataCopy]);
      if (
        !deliveryStartDate &&
        !deliveryEndDate &&
        isEmpty(searchText) &&
        distributorNameVal.length === 0
      ) {
        setFilteredPosts([...data]);
      }
    } else if (data && data.length > 0) {
      setFilteredPosts([...data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    searchText,
    deliveryStartDate,
    deliveryEndDate,
    distributorNameVal,
  ]);

  // const currentTableData = useMemo(() => {
  //   if (filteredPosts && filteredPosts.length > 0) {
  //     const firstPageIndex = (currentPage - 1) * postsPerPage;
  //     const lastPageIndex = firstPageIndex + postsPerPage;
  //     return filteredPosts.slice(firstPageIndex, lastPageIndex);
  //   } else {
  //     return [];
  //   }
  // }, [currentPage, filteredPosts, postsPerPage]);

  // const handleDetails = (id) => {
  //   const lineItems = data.filter((item) => item.id === id);
  //   MySwal.fire({
  //     html: (
  //       <Provider store={store}>
  //         <LineItems lineItems={lineItems[0].line_items} />
  //       </Provider>
  //     ),
  //     showCloseButton: true,
  //     focusConfirm: false,
  //     allowOutsideClick: true,
  //     showConfirmButton: false,
  //     preConfirm: () => {},
  //     customClass: {
  //       container: styles.lineTable,
  //       closeButton: styles.close_btn,
  //     },
  //   });
  // };

  useEffect(() => {
    if (delDate[0] && delDate[1]) {
      if (orderDate[0] && orderDate[1]) {
        addParams(searchText, null, delDate, orderDate);
      } else addParams(searchText, null, delDate, "");
    } else {
      if (orderDate[0] && orderDate[1]) {
        addParams(searchText, null, "", orderDate);
      } else addParams(searchText, null, "", "");
    }
  }, [delDate]);

  const handleOrderDate = (date) => {
    dispatch(setOrderRange(date));
    setDateRange(date);
  };

  const handleDelDate = (date) => {
    dispatch(setDeliveryRange(date));
    setDeliveryDateRange(date);
  };

  const handleDistName = (val) => {
    dispatch(setDistName(val));
    setDistributorNameVal(val);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Order Date",
        accessor: "order_date",
        Cell: ({ cell }) =>
          cell.row.original.order_date &&
          cell.row.original.order_date.split("T")[0],
      },
      {
        Header: "Delivery Date",
        accessor: "delivery_date",
        Cell: ({ cell }) =>
          cell.row.original.delivery_date &&
          cell.row.original.delivery_date.split("T")[0],
      },
      {
        Header: "D_Level",
        accessor: "d_type",
      },
      {
        Header: "Name of Distributor",
        accessor: "distributor_name",
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell }) => (
          <Link
            to={`/${stores}/orders/order-details?region_id=${region}&chained_store_id=${cell.row.original.chained_store_id}&distributor_id=${cell.row.original.distributor_id}&d_type=${cell.row.original.d_type}&order_date=${cell.row.original.order_date}&delivery_date=${cell.row.original.delivery_date}`}
            // onClick={() => handleDetails(item.id)}
          >
            View Details
          </Link>
        ),
        disableSortBy: true,
      },
    ],
    [region, stores]
  );

  return (
    <div className="mt-5">
      <Container>
        <PageTitle
          title={"Orders"}
          navigateTo={`/stores/${region}/${stores}`}
        />
        <div className="card-box">
          <Row className={"mt-2 no-gutters " + styles.filterWrapper}>
            <Col xs={12} sm={3} className={styles.datePickerWrapper}>
              <label
                htmlFor="store-filter-search-string"
                className={`no-space-break`}
              >
                Order Date
              </label>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  handleOrderDate(update);
                }}
                className={styles.datePickerIp}
                isClearable={false}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown={true}
              />
            </Col>
            <Col xs={12} sm={3} className={styles.datePickerWrapper}>
              <label
                htmlFor="store-filter-search-string"
                className={`${styles.searchLabel} no-space-break`}
              >
                Delivery Date
              </label>
              <DatePicker
                selectsRange={true}
                startDate={deliveryStartDate}
                endDate={deliveryEndDate}
                onChange={(update) => {
                  handleDelDate(update);
                }}
                className={styles.datePickerIp}
                isClearable={true}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown={true}
              />
            </Col>
            <Col xs={distributorNameVal.length > 2 ? 6 : 4}>
              <label className={styles.distName}>
                <Tablet>Distributor Name </Tablet>
                <Select
                  classNamePrefix={styles.asyncSel}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={distributorNameOpt}
                  value={distributorNameVal}
                  onChange={(val) => handleDistName(val)}
                />
              </label>
            </Col>
          </Row>

          <Row className="mt-4 no-gutters">
            <Col xs={6}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>

            <Col xs={6}>
              <div className={styles.searchWrapper} style={{ float: "right" }}>
                <SearchFilter
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </div>
            </Col>
          </Row>
          {isFetching ? (
            <Loader />
          ) : error ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="mt-3">
                  <ReactTable
                    columns={columns}
                    data={filteredPosts}
                    pageRows={postsPerPage}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

export default OrdersScreen;
