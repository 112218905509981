import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpApi } from "../constants/constants";

export const orderGuideApi = createApi({
  reducerPath: "orderGuideApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpApi,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getNonIntegratedOrderGuide: builder.query({
      query: (distributor_id) =>
        `/supply/non_integrated_default_order_guides/get_by_distributor_id/${distributor_id}/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ non_integrated_default_order_guides_id }) => ({
                type: "NonIntegratedOrderGuide",
                non_integrated_default_order_guides_id,
              })),
              {
                type: "NonIntegratedOrderGuide",
                id: "NonIntegratedOrderGuideList",
              },
            ]
          : [
              {
                type: "NonIntegratedOrderGuide",
                id: "NonIntegratedOrderGuideList",
              },
            ],
    }),
    getIntegratedOrderGuide: builder.query({
      query: ({ storeId, distId }) =>
        `/order_guide/order_guides/${storeId}?distributor_id=${distId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ non_integrated_default_order_guides_id }) => ({
                type: "NonIntegratedOrderGuide",
                non_integrated_default_order_guides_id,
              })),
              {
                type: "NonIntegratedOrderGuide",
                id: "NonIntegratedOrderGuideList",
              },
            ]
          : [
              {
                type: "NonIntegratedOrderGuide",
                id: "NonIntegratedOrderGuideList",
              },
            ],
    }),
  }),
});

export const {
  useGetNonIntegratedOrderGuideQuery,
  useGetIntegratedOrderGuideQuery,
} = orderGuideApi;
