import React from "react";
import { Tablet } from "../react-responsive/responsiveDevices";
import styles from "./postperpage.module.css";

const PostPerPage = ({ limit, setPostPerPage, setCurrentPage }) => {
  return (
    <label className={styles.paginationEntry}>
      <Tablet>Show </Tablet>
      <select
        name="datatable_length"
        aria-controls="datatable"
        className={`custom-select custom-select-sm form-control form-control-sm`}
        style={{ width: "auto", display: "inline-block" }}
        onChange={(e) => {
          setPostPerPage(Number(e.target.value));
          // setCurrentPage(1);
        }}
        value={limit}
      >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>{" "}
      <Tablet>entries</Tablet>
    </label>
  );
};

export default PostPerPage;
