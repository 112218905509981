import React, { useEffect, useState } from "react";
import styles from "./searchfilter.module.css";
import { useAsyncDebounce } from "react-table";

const AdvcSearchFilter = ({
  searchText,
  setSearchText,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      setSearchText(value);
    }, 500);

    return () => clearTimeout(debounceSearch);
  }, [value, setSearchText]);

  return (
    <>
      <label
        htmlFor="store-filter-search-string"
        className={styles.searchLabel}
      >
        Search
      </label>
      <input
        id={styles.storesFilter}
        type="text"
        className="mb-2 mr-sm-2"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </>
  );
};

export default AdvcSearchFilter;
