import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { regionsApi } from "../service/regionsService";
import { userApi } from "../service/userService";
import { marketApi } from "../service/marketsService";
import { brandApi } from "../service/brandsService";
import { storesApi } from "../service/storeService";
import { ordersApi } from "../service/orderService";
import { configApi } from "../service/configService";
import { cronApi } from "../service/cronService";
import { regionSlice } from "../service/regionSlice";
import { bookmarksApi } from "../service/bookmarksService";
import { notificationsApi } from "../service/notificationsService";
import { subNotificationsApi } from "../service/supplyDmdNotificationService";
import { userSlice } from "../service/userSlice";
import { filterSlice } from "../service/filterSlice";
import { orderSlice } from "../service/orderSlice";
import { inventoryApi } from "../service/inventoryService";
import { distributorApi } from "../service/distributorService";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { loginPath } from "../constants/constants";
import { orderGuideApi } from "../service/orderGuideService";
import { callScheduleApi } from "../service/callScheduleService";
import { buffersApi } from "../service/buffersService";
import { activeStoresApi } from "../service/activeStoresService";
import { roStoresApi } from "../service/roServices";
import { aibotApi } from "../service/aibotService";
const MySwal = withReactContent(Swal);

const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (
    isRejectedWithValue(action) &&
    (action.payload.status === 401 || action.payload.status === 403)
  ) {
    let timerInterval;

    MySwal.fire({
      title: "",
      html: "Your token has expired. You will be redirected to login",
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: true,
      // showCancelButton: true,
      icon: "error",
      confirmButtonText: `Login`,

      didOpen: () => {
        MySwal.showLoading();
        // const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          // b.textContent = MySwal.getTimerLeft();
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === MySwal.DismissReason.timer) {
        window.location.href = loginPath;
      }
    });
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [regionsApi.reducerPath]: regionsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [marketApi.reducerPath]: marketApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [storesApi.reducerPath]: storesApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    [cronApi.reducerPath]: cronApi.reducer,
    [bookmarksApi.reducerPath]: bookmarksApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [subNotificationsApi.reducerPath]: subNotificationsApi.reducer,
    [inventoryApi.reducerPath]: inventoryApi.reducer,
    [distributorApi.reducerPath]: distributorApi.reducer,
    [orderGuideApi.reducerPath]: orderGuideApi.reducer,
    [callScheduleApi.reducerPath]: callScheduleApi.reducer,
    [buffersApi.reducerPath]: buffersApi.reducer,
    [activeStoresApi.reducerPath]: activeStoresApi.reducer,
    [roStoresApi.reducerPath]: roStoresApi.reducer,
    [aibotApi.reducerPath]: aibotApi.reducer,

    userAccess: userSlice.reducer,
    region: regionSlice.reducer,
    orders: orderSlice.reducer,
    filterSlice: filterSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      regionsApi.middleware,
      userApi.middleware,
      marketApi.middleware,
      brandApi.middleware,
      storesApi.middleware,
      ordersApi.middleware,
      configApi.middleware,
      cronApi.middleware,
      bookmarksApi.middleware,
      notificationsApi.middleware,
      subNotificationsApi.middleware,
      inventoryApi.middleware,
      distributorApi.middleware,
      orderGuideApi.middleware,
      callScheduleApi.middleware,
      buffersApi.middleware,
      activeStoresApi.middleware,
      roStoresApi.middleware,
      aibotApi.middleware,
      rtkQueryErrorLogger
    ),
});

setupListeners(store.dispatch);
