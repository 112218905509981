import React from "react";
import styles from "./tabletitle.module.css";

const TableTitle = ({ title, fontSize = "16px" }) => {
  return (
    <h4 style={{ fontSize: fontSize }} className={styles.table_title}>
      {title}
    </h4>
  );
};

export default TableTitle;
