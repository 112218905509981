import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const AddStoreBuffers = ({ data = null, region, regionData, store }) => {
  return (
    <Form>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="countCycle">
          <Form.Label>Count Cycle</Form.Label>
          <Form.Select defaultValue={data && data.count_cycle}>
            <option value="">Select</option>
            <option value="daily">Daily</option>
            <option value="period">Period</option>
            <option value="weekly">Weekly</option>
            <option value="non recipe">Non Recipe</option>
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="days">
          <Form.Label>Days</Form.Label>
          <Form.Control
            type="number"
            min={0}
            defaultValue={data && data.days}
            placeholder="Enter Days"
          />
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="souceInventoryId">
          <Form.Label>Source Inventory Id</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder="Enter Source Id"
            defaultValue={data && data.source_inventory_id}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} xs={12} className={"mb-3"} controlId="storeId">
          <Form.Label>Store Id</Form.Label>
          <Form.Control
            type="number"
            min={0}
            placeholder="Enter Store Id"
            defaultValue={store ? store : data && data.store_id}
          />
        </Form.Group>
        {/* <Form.Group as={Col} className="mb-3" controlId="regionId">
          <Form.Label>Region</Form.Label>
          <Form.Select defaultValue={region}>
            {regionData.map((item) => (
              <option key={item.region_id} value={item.region_id}>
                {item.region_name}
              </option>
            ))}
          </Form.Select>
        </Form.Group> */}
      </Row>
    </Form>
  );
};

export default AddStoreBuffers;
