import React, { useEffect, useState, useMemo } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import styles from "./distributor.module.css";

import { useDispatch, useSelector } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { addParams, convertArrValToString, isEmpty } from "../../utils/utils";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { EditConfig } from "../../Componenets/Icons/Icons";
import ReactTable from "../../Componenets/ReactTable/ReactTable";
import {
  useAddDistributorMutation,
  useGetRegionalDistributorQuery,
} from "../../service/distributorService";

import {
  Desktop,
  Mobile,
} from "../../Componenets/react-responsive/responsiveDevices";

import { setSupplyDistributorTxt } from "../../service/filterSlice";
import SupplyAddDistributor from "./SupplyAddDistributor";
import { filter } from "smart-array-filter";
import AddNewButton from "../../Componenets/AddNewButton/AddNewButton";
import { SweetAlerts } from "../../Componenets/SweetAlerts/SweetAlerts";

const MySwal = withReactContent(Swal);

const SupplyDistributors = () => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(20);
  const region = useSelector((state) => state.region);
  const [searchParams] = useSearchParams();
  const srchTxt = searchParams.get("searchText");
  const { distributorSearchText } = useSelector((state) => state.filterSlice);
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState(
    srchTxt ? srchTxt : distributorSearchText
  );

  const [filteredPosts, setFilteredPosts] = useState([]);
  const { data, error, isFetching } = useGetRegionalDistributorQuery(region);
  const [createDistributor, responseInfo] = useAddDistributorMutation();

  const [distKind, setDistKind] = useState("");

  const getSearchedPosts = (data) => {
    const result = convertArrValToString(data);
    let matchingRes = filter(result, {
      keywords: searchText,
      includePaths: [
        "DISTRIBUTOR_ID",
        "DISTRIBUTOR_NAME",
        "DISTRIBUTOR_KIND",
        "STORE_DISTRIBUTOR_ID",
      ],
      excludedPaths: [],
      caseSensitive: false,
    });
    setFilteredPosts(matchingRes);
  };

  useEffect(() => {
    addParams(searchText);
    dispatch(setSupplyDistributorTxt(searchText));
    if (data) {
      if (!isEmpty(searchText) && data.length > 0) {
        getSearchedPosts(data);
      } else if (data.length > 0) {
        setFilteredPosts([...data]);
      } else {
        setFilteredPosts([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  const handleResponse = (response) => {
    if (response.error) {
      Swal.showValidationMessage(
        `Request failed: ${response.error.data.detail}`
      );
    } else {
      // MySwal.fire(
      //   "",
      //   <p style={{ textAlign: "center" }}>Distributor added successfully</p>,
      //   "success"
      // );
      navigate(
        `/distributors/${region}/${response.data[0].DISTRIBUTOR_ID}?kind=${response.data[0].DISTRIBUTOR_KIND}&tab=addStores`
      );
    }
  };

  const addNew = () => {
    MySwal.fire({
      title: `Add Distributor`,
      html: <SupplyAddDistributor />,
      confirmButtonText: `Save`,
      focusConfirm: false,
      showLoaderOnConfirm: true,
      showCloseButton: true,
      preConfirm: async () => {
        const newDistributor = validateDistributor();
        if (newDistributor) {
          newDistributor.region_id = region;

          return createDistributor(newDistributor).then((response) => {
            handleResponse(response);
          });
        }
      },
    });
  };

  const ApiState = () => {
    if (isFetching) {
      return <Loader className="mt-2 " />;
    } else if (error) {
      return (
        <Alert variant="danger mt-4">
          Some Error occured. Please contant admin
        </Alert>
      );
    } else {
      return (
        <Row>
          <Col xs={12}>
            <div className="mt-3">
              <Table
                region={region}
                filteredPosts={filteredPosts}
                postsPerPage={postsPerPage}
              />
            </div>
          </Col>
        </Row>
      );
    }
  };

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Distributors"} />
        <div className="card-box">
          <Row className="mt-2 no-gutters">
            <Col lg={5} md={5} xs={2}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
            {/* <Col xs={4}>
              <DistributorKind distKind={distKind} setDistKind={setDistKind} />
            </Col> */}
            <Desktop>
              <Col className={styles.searchWrapper} xs={4}>
                <SearchFilter
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </Col>
            </Desktop>

            <Col xs={3} style={{ float: "right" }}>
              <AddNewButton addNew={addNew} />
            </Col>

            <Mobile>
              <Col xs={12}>
                <div className="mt-3 mb-3">
                  <SearchFilter
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Mobile>
          </Row>
          <ApiState />
        </div>
      </Container>
    </div>
  );
};

const DistributorKind = ({ setDistKind, distKind }) => {
  return (
    <label
      className={styles.distName}
      style={{ margin: "0 5px", fontSize: "16px", fontWeight: 400 }}
    >
      Distributor Kind
      <select
        name="datatable_length"
        aria-controls="datatable"
        className={`custom-select custom-select-sm form-control form-control-sm`}
        style={{
          width: "auto",
          display: "inline-block",
          margin: "0 5px",
        }}
        onChange={(e) => setDistKind(e.target.value)}
        value={distKind}
      >
        <option value="">Select</option>
        <option value="Integrated">Integrated</option>
        <option value="Non-Integrated">Non-Integrated</option>
      </select>
    </label>
  );
};

const Table = ({ region, filteredPosts, postsPerPage }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Distributor ID",
        accessor: "distributor_id",
      },
      {
        Header: "Distributor Name",
        accessor: "distributor_name",
      },
      {
        Header: "Distributor Kind",
        accessor: "distributor_kind",
      },
      {
        Header: "Source distributor id",
        accessor: "source_distributor_id",
      },

      {
        Header: "Action",
        accessor: "",

        Cell: ({ cell }) => (
          <>
            <Link
              to={`/distributors/${region}/${cell.row.original.distributor_id}?kind=${cell.row.original.distributor_kind}`}
            >
              <EditConfig id={cell.row.original.yum_id} />
            </Link>
          </>
        ),
        disableSortBy: true,
      },
    ],
    [region]
  );
  return (
    <ReactTable
      columns={columns}
      data={filteredPosts}
      pageRows={postsPerPage}
    />
  );
};

const validateDistributor = () => {
  const distName = document.getElementById("distName").value;
  const distKind = document.getElementById("distributorKind").value;
  let sourceDistId = document.getElementById("sourceDistId").value;

  if (isEmpty(distName)) {
    return MySwal.showValidationMessage(`Please enter distributor name`);
  }
  if (isEmpty(distKind)) {
    return MySwal.showValidationMessage(`Please select distributor kind`);
  }

  if (sourceDistId && sourceDistId.length > 10) {
    return MySwal.showValidationMessage(`Please enter a id less than 10`);
  }

  sourceDistId = isEmpty(sourceDistId) ? "" : sourceDistId;

  return {
    distributor_name: distName,
    distributor_kind: distKind,
    source_distributor_id: sourceDistId,
  };
};

export default SupplyDistributors;
