import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const AddDistributors = ({ distributors, setSelectedDist, storeDist }) => {
  const { regDistData } = distributors ? distributors : {};

  const [distributorOptions, setDistributorOptions] = useState([]);

  useEffect(() => {
    if (regDistData && regDistData.length > 0) {
      const uniqueIds = [];
      storeDist.forEach((element) => {
        uniqueIds.push(element.DISTRIBUTOR_ID);
      });

      const availableDist = regDistData.filter(
        (dist) => !uniqueIds.includes(dist.distributor_id)
      );

      const options = [];
      availableDist.forEach((element) => {
        options.push({
          value: element.distributor_id,
          label: `Name - ${element.distributor_name} -  Distributor Kind - ${element.distributor_kind}`,
        });
      });
      setDistributorOptions(options);
    }
  }, [regDistData, storeDist]);

  const handleChange = (val) => {
    const values = val.map((value, index) => value.value);
    setSelectedDist(values);
  };
  return (
    <Form>
      <Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={12}
          className={"mb-3"}
          controlId="market"
        >
          <Form.Label>Select Distributor</Form.Label>
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={distributorOptions}
            onChange={handleChange}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999999999,
                position: "inherit",

                border: "1px solid #ced4da",
                boxShadow: "none",
              }),
            }}
          />
        </Form.Group>
      </Row>
    </Form>
  );
};

export default AddDistributors;
