import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const SweetAlerts = (
  response,
  successMsg,
  failedMsg = "Some error occured. Please contact Admin"
) => {
  if (response.isSuccess) {
    return MySwal.fire(
      "",
      <p style={{ textAlign: "center" }}>{successMsg}</p>,
      "success"
    );
  } else if (response.isError) {
    return MySwal.fire(
      "",
      <p style={{ textAlign: "center" }}>{response.error.data.detail}</p>,
      "error"
    );
  }
};
