import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import styles from "./inventory.module.css";

import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { filter } from "smart-array-filter";
import { addParams, convert, getTodayDate, isEmpty } from "../../utils/utils";

import { setInventoryRange, setInventoryTxt } from "../../service/filterSlice";

import ReactTable from "../../Componenets/ReactTable/ReactTable";
import { useGetInventoryQuery } from "../../service/inventoryService";

const InventoryScreen = () => {
  const dispatch = useDispatch();
  const [setCurrentPage] = useState(1);

  const [postsPerPage, setPostPerPage] = useState(10);
  const [skip, setSkip] = useState(true);

  const { stores } = useParams();

  const [filteredPosts, setFilteredPosts] = useState([]);

  const { inventoryDate } = useSelector((state) => state.filterSlice);

  // const { distributorName } = useSelector(
  //   (state) => state.filterSlice.ordersFilters
  // );

  const [searchParams] = useSearchParams();

  const srchTxt = searchParams.get("searchText");

  const orderDateVal = searchParams.get("dateRange");

  const { inventorySearchText } = useSelector((state) => state.filterSlice);

  const [searchText, setSearchText] = useState(
    srchTxt ? srchTxt : inventorySearchText
  );

  const region = useSelector((state) => state.region);

  const [formattedDateRange, setFormattedDateRange] = useState([
    getTodayDate(),
    getTodayDate(),
  ]);

  const convertDate = (date) => {
    if (date !== ",") {
      const newDate = date.split(",");
      return [new Date(newDate[0]), new Date(newDate[1])];
    }
  };

  const [dateRange, setDateRange] = useState(
    orderDateVal && orderDateVal !== ","
      ? convertDate(orderDateVal)
      : inventoryDate
  );

  const [startDate, endDate] = dateRange;

  useEffect(() => {
    orderDateVal && dispatch(setInventoryRange(convertDate(orderDateVal)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const dateRange = [convert(startDate), convert(endDate)];
      setFormattedDateRange(dateRange);
    }
    if (inventoryDate[0] && inventoryDate[1]) {
      addParams(searchText, null, null, null, null, inventoryDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryDate, endDate, startDate]);

  useEffect(() => {
    if (region && stores && formattedDateRange[0] && formattedDateRange[1]) {
      setSkip(false);
    }
  }, [formattedDateRange, region, stores]);

  const { data, error, isFetching } = useGetInventoryQuery(
    {
      store: stores,
      startDate: formattedDateRange[0],
      endDate: formattedDateRange[1],
    },
    {
      skip: skip,
    }
  );

  useEffect(() => {
    dispatch(setInventoryTxt(searchText));
    addParams(searchText, null, null, null, null, inventoryDate);
    if (data && data.length > 0 && !isEmpty(searchText)) {
      let dataCopy = [...data];

      if (!isEmpty(searchText)) {
        const inventoryArr = [];
        dataCopy.forEach((item) => inventoryArr.push(item));
        const result = inventoryArr.map((obj) =>
          Object.fromEntries(
            Object.entries(obj).map(([key, val]) => [key, String(val)])
          )
        );
        const newSearchText = searchText.trim();
        dataCopy = filter(result, {
          keywords: newSearchText,
          includePaths: [
            "business_date",
            "inventory_item_id",
            "source_inventory_id",
            "descr",
            "source_subinventory_id",
            "purchasing_count",
            "serving_count",
            "counting_unit",
            "ending_count",
            "invoice_count",
            "theoretical_count",
            "on_hand_qty",
            "waste_count",
          ],
          excludedPaths: [],
          caseSensitive: false,
        });
      }

      setFilteredPosts([...dataCopy]);
    } else if (data && data.length > 0) {
      setFilteredPosts([...data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  const handleOrderDate = (date) => {
    dispatch(setInventoryRange(date));
    setDateRange(date);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Buisness Date",
        accessor: "business_date",
        Cell: ({ cell }) =>
          cell.row.original.business_date &&
          cell.row.original.business_date.split("T")[0],
      },
      {
        Header: "Counting Unit",
        accessor: "counting_unit",
      },
      {
        Header: "Descr",
        accessor: "descr",
      },
      {
        Header: "Ending Count",
        accessor: "ending_count",
      },
      {
        Header: "Inventory Item Id",
        accessor: "inventory_item_id",
      },
      {
        Header: "Invoice Count",
        accessor: "invoice_count",
      },
      {
        Header: "On Hand Qty",
        accessor: "on_hand_qty",
      },
      {
        Header: "Purchasing Count",
        accessor: "purchasing_count",
      },
      {
        Header: "Serving Count",
        accessor: "serving_count",
      },

      {
        Header: "Source Inventory Id",
        accessor: "source_inventory_id",
      },
      {
        Header: "Source Subinventory Id",
        accessor: "source_subinventory_id",
      },
      // {
      //   Header: "Store ID",
      //   accessor: "store_id",
      // },
      {
        Header: "Theoretical Count",
        accessor: "theoretical_count",
        Cell: ({ cell }) =>
          cell.row.original.theoretical_count &&
          Number(cell.row.original.theoretical_count).toFixed(2),
      },
      {
        Header: "Waste Count",
        accessor: "waste_count",
      },
    ],
    []
  );

  return (
    <div className="mt-5">
      <Container>
        <PageTitle
          title={"Inventory"}
          navigateTo={`/stores/${region}/${stores}`}
        />
        <div className="card-box">
          <Row className="mt-4 no-gutters">
            <Col xs={4}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>

            <Col xs={5}>
              <div className={styles.searchWrapper}>
                <SearchFilter
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </div>
            </Col>
            <Col xs={3} className={styles.datePickerWrapper}>
              <label
                htmlFor="store-filter-search-string"
                className={`no-space-break`}
              >
                Date
              </label>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  handleOrderDate(update);
                }}
                className={styles.datePickerIp}
                isClearable={false}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown={true}
              />
            </Col>
          </Row>
          {isFetching ? (
            <Loader />
          ) : error ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="mt-3">
                  <ReactTable
                    columns={columns}
                    data={filteredPosts}
                    pageRows={postsPerPage}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

export default InventoryScreen;
