import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpApi } from "../constants/constants";

export const activeStoresApi = createApi({
  reducerPath: "activeStoresApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpApi,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  keepUnusedDataFor: 864000,
  endpoints: (builder) => ({
    getActiveStores: builder.query({
      query: (region) => `supply/active_stores/${region}/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ chained_store_id }) => ({
                type: "ActiveStores",
                chained_store_id,
              })),
              { type: "ActiveStores", chained_store_id: "ActiveStoresList" },
            ]
          : [{ type: "ActiveStores", chained_store_id: "ActiveStoresList" }],
    }),
    updateActiveStores: builder.mutation({
      query: ({ region, programStatusId }) => ({
        url: `supply/active_stores/${programStatusId}/`,
        method: "POST",

        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(
        { region, programStatusId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          activeStoresApi.util.updateQueryData(
            "getActiveStores",
            region,
            (draft) => {
              const index = draft.findIndex((item) => {
                return item.program_status_id === programStatusId;
              });
              if (index > -1) {
                draft.splice(index, 1);
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    addActiveStores: builder.mutation({
      query: (body) => ({
        url: `supply/active_stores`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [
        { type: "ActiveStores", chained_store_id: "activeStoresList" },
        {
          type: "PaginatedInActiveStores",
          chained_store_id: "PaginatedInActiveStoresList",
        },
      ],
    }),
  }),
});

export const {
  useGetActiveStoresQuery,
  useUpdateActiveStoresMutation,
  useAddActiveStoresMutation,
} = activeStoresApi;
