import React from "react";
import {
  AiOutlineDelete,
  AiOutlineLink,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import {
  BiBookmarkMinus,
  BiBookmarkPlus,
  BiEdit,
  BiFile,
  BiPlayCircle,
  BiTrendingUp,
} from "react-icons/bi";
import { BsPauseCircle } from "react-icons/bs";
import { FaFileDownload } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import styles from "./icons.module.css";
import { TbFileTime } from "react-icons/tb";

export const Edit = ({ handleClick, id }) => {
  return (
    <Link
      title="Edit"
      className={styles.action_btn_links}
      to="#"
      onClick={() => handleClick(id)}
    >
      <BiEdit className={`${styles.action_icons} ${styles.edit_icon}`} />
    </Link>
  );
};

export const EditConfig = ({ handleClick, id }) => {
  return <BiEdit className={`${styles.action_icons} ${styles.edit_icon}`} />;
};

export const EditIcon = () => {
  return <BiEdit className={`${styles.action_icons} ${styles.edit_icon}`} />;
};

export const Pause = ({ handleClick, id }) => {
  return (
    <BsPauseCircle className={`${styles.action_icons} ${styles.pause_icons}`} />
  );
};

export const Add = ({ handleClick, id }) => {
  return (
    <IoIosAddCircleOutline
      className={`${styles.action_icons} ${styles.pause_icons}`}
      onClick={() => handleClick()}
    />
  );
};

export const CronIcon = ({ handleClick, id }) => {
  return (
    <TbFileTime className={`${styles.action_icons} ${styles.cron_icons}`} />
  );
};

export const FileIcon = ({ handleClick, id }) => {
  return <BiFile className={`${styles.action_icons} ${styles.file_icon}`} />;
};

export const Minus = ({ handleClick, id }) => {
  return (
    <AiOutlineMinusCircle
      className={`${styles.action_icons} ${styles.delete_icon}`}
    />
  );
};

export const Play = ({ handleClick, id }) => {
  return (
    <BiPlayCircle
      className={`${styles.action_icons} ${styles.download_icon}`}
    />
  );
};

export const ConfigPlay = ({ handleClick, id }) => {
  return (
    <BiPlayCircle
      className={`${styles.action_icons} ${styles.download_icon}`}
      style={{ backgroundColor: "ffdc00", color: "#000" }}
    />
  );
};

export const DownloadConfig = ({ handleClick, id }) => {
  return (
    <FaFileDownload
      className={`${styles.action_icons} ${styles.download_icon}`}
    />
  );
};

export const Delete = ({ handleClick, id }) => {
  return (
    <Link
      title="Delete"
      className={styles.action_btn_links}
      to="#"
      onClick={() => handleClick(id)}
    >
      <AiOutlineDelete
        className={`${styles.action_icons} ${styles.delete_icon}`}
      />
    </Link>
  );
};

export const DeleteIcon = ({ handleClick, id }) => {
  return (
    <AiOutlineDelete
      className={`${styles.action_icons} ${styles.delete_icon}`}
    />
  );
};

export const AddBookmark = ({ handleClick, id, store }) => {
  return (
    <span
      title="Add Bookmark"
      className={styles.action_btn_links}
      onClick={() => handleClick(id, store)}
      style={{ cursor: "pointer" }}
    >
      <BiBookmarkPlus
        className={`${styles.action_icons} ${styles.add_bookmark}`}
      />
    </span>
  );
};

export const RemoveBookmark = ({ handleClick, id, store }) => {
  return (
    <span
      title="Remove Bookmark"
      className={styles.action_btn_links}
      onClick={() => handleClick(id, store)}
      style={{ cursor: "pointer" }}
    >
      <BiBookmarkMinus
        className={`${styles.action_icons} ${styles.delete_icon}`}
      />
    </span>
  );
};

export const Analysis = ({ handleClick, id }) => {
  return (
    <Link
      title="Domo Dashboard"
      className={styles.action_btn_links}
      to="#"
      onClick={() => handleClick(id)}
    >
      <AiOutlineLink
        className={`${styles.action_icons} ${styles.analysis_icon}`}
      />
    </Link>
  );
};

export const SupplyDemand = ({ handleClick, id, link }) => {
  return (
    <Link
      title="Supply Demand"
      className={styles.action_btn_links}
      to={link}
      // onClick={() => handleClick(id)}
    >
      <BiTrendingUp
        className={`${styles.action_icons} ${styles.supplyDmd_icon}`}
      />
    </Link>
  );
};
