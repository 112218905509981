import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpApi } from "../constants/constants";

export const buffersApi = createApi({
  reducerPath: "buffersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpApi,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  keepUnusedDataFor: 864000,
  endpoints: (builder) => ({
    getBuffers: builder.query({
      query: ({ region, url }) => `/buffers/${region}${url}`,
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ buffer_id }) => ({
                type: "Buffers",
                buffer_id,
              })),
              { type: "Buffers", id: "BuffersList" },
            ]
          : [{ type: "Buffers", id: "BuffersList" }],
    }),
    getBuffersByStore: builder.query({
      query: ({ region_id, store_id }) =>
        `buffers/get_by_store/${region_id}/${store_id}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ buffer_id }) => ({
                type: "BufferStore",
                buffer_id,
              })),
              { type: "BufferStore", id: "BufferStoreList" },
            ]
          : [{ type: "BufferStore", id: "BufferStoreList" }],
    }),

    createBuffer: builder.mutation({
      query: (body) => ({
        url: `buffers/buffer/save_bulk`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Buffers", id: "BuffersList" }],
    }),
    updateBuffer: builder.mutation({
      query: (body) => ({
        url: `buffers/buffer/${body.buffer_id}/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Buffers", id: "BuffersList" }],
    }),
  }),
});

export const {
  useGetBuffersQuery,
  useCreateBufferMutation,
  useUpdateBufferMutation,
  useGetBuffersByStoreQuery,
} = buffersApi;
