import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order",
  initialState: [],
  reducers: {
    saveOrders: (state, action) => action.payload,
  },
});

export const { saveOrders } = orderSlice.actions;
