import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import {
  Alert,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import styles from "./recomendedordering.module.css";
import { Link } from "react-router-dom";
import { MainButton } from "../../Componenets/buttons/button";
import { useGetRegionalDistributorQuery } from "../../service/distributorService";
import { useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  useAddWithUpdateRoStoresMutation,
  useLazyGetActivationStatusForStoresQuery,
} from "../../service/roServices";
import Loader from "../../Componenets/Loader/Loader";
import Swal from "sweetalert2";
import { SecButton } from "../../Componenets/buttons/button";
import { isEmpty } from "../../utils/utils";
const animatedComponents = makeAnimated();

const RecomendedOrdering = () => {
  const [activeTab, setActiveTab] = useState("create");
  const [chainedStoreId, setChainedStoreId] = useState("");
  const [isAutoValidate, setIsAutoValidate] = useState(false);
  const [chainedStoreIdList, setChainedStoreIdList] = useState([]);
  const [distributorOptions, setDistributorOptions] = useState([]);
  const [selectedDist, setSelectedDist] = useState([]);
  const [bulkActivationStatus, setBulkActivationStatus] = useState("false");
  const [updateBulkActivationStatus, setUpdateBulkActivationStatus] =
    useState();

  // const [statusOption, setStatusOption] = useState("change_status");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const [initialActivationStatusData, setInitialActivationStatusData] =
    useState(false);

  const selectRef = useRef(null);

  const region = useSelector((state) => state.region);

  const [addRoStores, addRoStoresInfo] = useAddWithUpdateRoStoresMutation();

  const { data: distData } = useGetRegionalDistributorQuery(region);

  const [
    getActivationStatus,
    {
      isFetching: activationStatusLoading,
      data: activationStatusData,
      error: activationStatusError,
    },
  ] = useLazyGetActivationStatusForStoresQuery();

  useEffect(() => {
    if (distData && distData.length > 0) {
      const options = [];
      distData.forEach((element) => {
        options.push({
          value: element.distributor_id,
          label: `Name - ${element.distributor_name} -  Distributor Kind - ${element.distributor_kind}`,
        });
      });
      setDistributorOptions(options);
    }
  }, [distData]);

  useEffect(() => {
    if (chainedStoreId) {
      let formattedValue = chainedStoreId;
      if (isAutoValidate) {
        const lines = formattedValue.split("\n");
        formattedValue = lines
          .map((line) => line.trim())
          .filter(Boolean)
          .join(", ");

        // const cleanedValue = chainedStoreId.replace(/\s/g, "").toUpperCase();
        // const pattern = /([A-Z]+\d+)/g;

        // formattedValue = cleanedValue.replace(
        //   pattern,
        //   (match, group, index, input) => {
        //     if (input.charAt(index + match.length) !== ",") {
        //       return match + ",";
        //     } else {
        //       return match;
        //     }
        //   }
        // );

        // formattedValue = formattedValue.replace(/,,+/g, ",");
        // formattedValue = formattedValue.replace(/,$/, "");
      }

      const parts = formattedValue.split(",");
      const partsWithoutSpaces = parts.map((part) => part.trim());
      const output = partsWithoutSpaces.join(",");
      let chainedStoreIds = output.split(",");

      const existingIds = chainedStoreIdList.filter(([item]) => {
        console.log(item);
        return chainedStoreIds.includes(item);
      });

      const newIds = chainedStoreIds
        .filter(
          (item) =>
            !chainedStoreIdList.some(([existingItem]) => existingItem === item)
        )
        .map((item) => [item, "False"]);
      const updatedList = [...existingIds, ...newIds];
      if (!arraysAreEqual(updatedList, chainedStoreIdList)) {
        setChainedStoreIdList(updatedList);
      }
      setChainedStoreId(output);
    } else if (chainedStoreIdList.length > 0) {
      setChainedStoreIdList([]);
    }
  }, [
    chainedStoreId,
    isAutoValidate,
    updateBulkActivationStatus,
    chainedStoreIdList,
  ]);

  // Utility function to compare arrays
  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i][0] !== arr2[i][0] || arr1[i][1] !== arr2[i][1]) {
        return false;
      }
    }

    return true;
  }

  const handleChange = (val) => {
    const values = val.map((value, index) => value.value);
    setSelectedDist(values);
  };

  const handleForm = () => {
    let valid = true;
    let errors = [];

    const update = activeTab === "create" ? "addrostores" : "activaterostores";
    if (update === "addrostores") {
      if (selectedDist.length === 0) {
        errors.push("Please select distributors");
        valid = false;
      }
    }
    console.log(isEmpty(chainedStoreId), "chainedstore id");
    if (isEmpty(chainedStoreId)) {
      errors.push("Please enter Chained Store Id");
      valid = false;
    }
    setValidationErrors(errors);
    if (valid) {
      let params;
      if (update === "addrostores") {
        const distributor_ids = selectedDist.toString();
        params = `distributor_ids=${distributor_ids}&update=${update}&active=${bulkActivationStatus}&region_id=${region}&chained_store_ids=${chainedStoreId}`;
      } else {
        params = `update=${update}&active=true&region_id=${region}`;
      }

      console.log({ params, body: chainedStoreIdList });
      addRoStores({ params, body: chainedStoreIdList });
    }
  };
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    // setUpdateBulkActivationStatus(selectedValue);
    if (selectedRows.length > 0) {
      const updatedData = chainedStoreIdList.map((item, index) => {
        if (selectedRows.includes(index)) {
          return [item[0], selectedValue];
        } else {
          return item;
        }
      });
      setChainedStoreIdList(updatedData);
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  const getStoreStatuses = () => {
    const status = getActivationStatus(
      `region_id=${region}&chained_store_ids=${chainedStoreId}`
    );
    console.log(status, "status");
  };

  useEffect(() => {
    if (!activationStatusLoading) {
      setInitialActivationStatusData(true);
    }
  }, [activationStatusLoading]);

  useEffect(() => {
    if (activationStatusData && initialActivationStatusData) {
      const transformedData = activationStatusData.map((item) => {
        if (item.CHAINED_STORE_ID && item.IS_ACTIVE !== undefined) {
          return [item.CHAINED_STORE_ID, item.IS_ACTIVE];
        } else {
          const missingIds = item.missing_ids;
          if (missingIds.length > 0) {
            const missingIdString = missingIds.join(", ");

            Swal.fire({
              title: "Missing IDs",
              html: `Missing IDs: ${missingIdString}`,
              icon: "warning",
              confirmButtonText: "OK",
            });

            const updatedChainedStoreId = missingIds.reduce(
              (acc, missingId) => {
                return acc.replace(missingId + ",", "");
              },
              chainedStoreId
            );

            setChainedStoreId(updatedChainedStoreId);
            return [missingIdString, false];
          }
        }
      });

      console.log(transformedData.filter(Boolean), "transformedData");
      setChainedStoreIdList(transformedData.filter(Boolean));
      setInitialActivationStatusData(false);
    }
  }, [activationStatusData, initialActivationStatusData]);

  useEffect(() => {
    if (addRoStoresInfo.isSuccess) {
      console.log(addRoStoresInfo.data[0].missing_store_ids);
      if (addRoStoresInfo.data[0].missing_store_ids.length > 0) {
        Swal.fire({
          title: "Missing IDs",
          html: `Missing IDs: ${addRoStoresInfo.data[0].missing_store_ids}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }

      // setSelectedDist([]);
      setBulkActivationStatus(false);
      setChainedStoreId("");
      setChainedStoreIdList([]);
      // selectRef.current.select.setValue(null);
    }
  }, [addRoStoresInfo]);

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Manage RO Stores"} />
        <Row className="mb-3 mt-3">
          <Col xs={8} className="offset-2">
            <div className={`config-tabs ${styles.config_tabs}`}>
              <div className="d-flex mt-3">
                <ul
                  className="nav nav-tabs flex-grow-1 flex-nowrap"
                  style={{ justifyContent: "center" }}
                >
                  <li className={`nav-item ${styles.cf_tab}`}>
                    <Link
                      className={`nav-link  ${
                        activeTab === "create" ? styles.active : ""
                      } ${styles.cf_link} ${styles.normalTab}`}
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("create");
                        setValidationErrors([]);
                      }}
                    >
                      Add RO Stores
                    </Link>
                  </li>
                  <li className={`nav-item ${styles.cf_tab}`}>
                    <Link
                      className={`nav-link ${
                        activeTab === "update" ? styles.active : ""
                      } ${styles.cf_link} ${styles.normalTab}`}
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("update");
                        setValidationErrors([]);
                      }}
                    >
                      Activate RO Stores
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={8} className="offset-2">
            <div className="card-box shadow mt-3">
              <div
                className={`cf-editor  ${
                  activeTab === "create" ? "visible" : "hide"
                }`}
              >
                <Form>
                  {activeTab === "create" && (
                    <Row>
                      <Form.Group
                        as={Col}
                        xs={12}
                        sm={12}
                        className={"mb-3"}
                        controlId="distName"
                      >
                        <Form.Label>Select Distributor </Form.Label>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          ref={selectRef}
                          isMulti
                          className={`${styles.distSelect}`}
                          options={distributorOptions}
                          onChange={handleChange}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999999999,
                              position: "inherit",

                              border: "1px solid #ced4da",
                              boxShadow: "none",
                            }),
                          }}
                        />

                        <span className={styles.errorMsg} id="distErr"></span>
                      </Form.Group>
                    </Row>
                  )}
                  {activeTab === "create" && (
                    <Row>
                      <Form.Group
                        as={Col}
                        xs={12}
                        className={"mb-3"}
                        controlId="distributorKind"
                      >
                        <Form.Label>Activation Status</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setBulkActivationStatus(e.target.value)
                          }
                          value={bulkActivationStatus}
                          defaultValue={bulkActivationStatus}
                        >
                          <option value="false">False</option>
                          <option value="true">True</option>
                        </Form.Select>
                        <span className={styles.errorMsg} id="distKind"></span>
                      </Form.Group>
                    </Row>
                  )}

                  <Row>
                    <Form.Group
                      as={Col}
                      xs={12}
                      className={"mb-3"}
                      controlId="sourceDistId"
                    >
                      <Form.Label>Chained Store Id</Form.Label>
                      {/* <span style={{ float: "right" }}>
                        <Form.Check
                          type="checkbox"
                          id="exampleCheckbox"
                          label="Auto Format"
                          checked={isAutoValidate}
                          onChange={(e) => setIsAutoValidate(e.target.checked)}
                        />
                      </span> */}
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={chainedStoreId}
                        onChange={(e) => setChainedStoreId(e.target.value)}
                      />

                      <small>
                        Please enter comma seprated{" "}
                        <strong>"Chained Store Ids"</strong>
                      </small>

                      {activeTab === "update" && chainedStoreId && (
                        <span
                          className={`${styles.small_btn}`}
                          onClick={() => getStoreStatuses()}
                        >
                          {/* <SecButton
                            handleClick={() => getStoreStatuses()}
                            classes={"btn-sm"}
                          > */}
                          Show Status
                          {/* </SecButton> */}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </Form>
              </div>

              {activeTab === "update" && (
                <DataComponent
                  data={chainedStoreIdList}
                  outerBooleanInput={updateBulkActivationStatus}
                  setChainedStoreIdList={setChainedStoreIdList}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  activationStatusLoading={activationStatusLoading}
                  activationStatusError={activationStatusError}
                />
              )}
              {activeTab === "update" && selectedRows.length > 0 && (
                <Row>
                  <Form.Group
                    as={Col}
                    xs={12}
                    className={"mb-3"}
                    controlId="distributorKind"
                  >
                    <Form.Label>Activation Status</Form.Label>
                    <Form.Select onChange={(e) => handleSelectChange(e)}>
                      <option value="">Select</option>

                      <option value="False">False</option>
                      <option value="True">True</option>
                    </Form.Select>
                    <span className={styles.errorMsg} id="distKind"></span>
                  </Form.Group>
                </Row>
              )}
              <div className="text-center">
                {validationErrors?.map((error, index) => (
                  <span key={index} variant="light" className={styles.errorMsg}>
                    {" "}
                    {/* Add custom class 'my-2' */}
                    {error}
                  </span>
                ))}
              </div>
              <div className={`save-config mt-1 text-center ${styles.cfbtns}`}>
                {addRoStoresInfo.isLoading ? (
                  <MainButton>
                    Updating{" "}
                    <Spinner
                      style={{ marginLeft: "5px" }}
                      animation="border"
                      size="sm"
                    />
                  </MainButton>
                ) : (
                  <MainButton handleClick={handleForm}>Update</MainButton>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RecomendedOrdering;

const DataComponent = ({
  data,
  outerBooleanInput,
  setChainedStoreIdList,
  selectedRows,
  setSelectedRows,
  selectAll,
  setSelectAll,
  activationStatusLoading,
  activationStatusError,
}) => {
  console.log(data, "data");
  // useEffect(() => {
  //   if (selectedRows.length > 0) {
  //     const updatedData = data.map((item, index) => {
  //       if (selectedRows.includes(index)) {
  //         return [item[0], outerBooleanInput];
  //       } else {
  //         return item;
  //       }
  //     });
  //     setChainedStoreIdList(updatedData);
  //     setSelectedRows([]);
  //   }
  // }, [selectedRows, outerBooleanInput]);

  const handleHeaderCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedRows(isChecked ? data.map((item, index) => index) : []);
  };

  const handleRowCheckboxChange = (event, rowIndex) => {
    const isChecked = event.target.checked ? "True" : "False";
    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        return [...prevSelectedRows, rowIndex];
      } else {
        return prevSelectedRows.filter((index) => index !== rowIndex);
      }
    });
    setSelectAll(false);
  };

  const handleRowSelect = (index) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(index)) {
        return prevSelectedRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevSelectedRows, index];
      }
    });
  };

  const handleActivationStatusChange = (event, index) => {
    const isChecked = event.target.checked ? "True" : "False";

    setChainedStoreIdList((prevList) => {
      const updatedList = prevList.map((item, itemIndex) => {
        if (itemIndex === index) {
          return [item[0], isChecked];
        }
        return item;
      });
      return updatedList;
    });
  };

  return (
    <>
      {activationStatusLoading ? (
        <Loader />
      ) : activationStatusError ? (
        <Alert variant="danger mt-4">
          Some Error occured. Please contant admin
        </Alert>
      ) : (
        <>
          <Table striped bordered>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleHeaderCheckboxChange}
                  />
                </th>
                <th>Chained Store Ids</th>
                <th>Activation Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(index)}
                      onChange={() => handleRowSelect(index)}
                    />
                  </td>
                  <td>{item[0]}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={item[1] === "True" || item[1] === true}
                      onChange={(e) => handleActivationStatusChange(e, index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};
