import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpBaseUrl } from "../constants/constants";

export const configApi = createApi({
  reducerPath: "configApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getConfigs: builder.query({
      query: ({ region, scheduled }) => ({
        url: `config_manager/metadata/${region}/`,
        params: `isscheduled=${scheduled}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Configs", id })),
              { type: "Configs", id: "ConfigsList" },
            ]
          : [{ type: "Configs", id: "ConfigsList" }],
    }),
    getConfigsByUid: builder.query({
      query: (uid) => `config_manager/get_config_by_uuid/${uid}/`,
    }),
    downloadConfig: builder.query({
      _query: (uuid) => ({
        url: `config_manager/download/${uuid}`,
        responseHandler: (response) => response.text(),
      }),
      get query() {
        return this._query;
      },
      set query(value) {
        this._query = value;
      },
    }),
    getYaml: builder.query({
      _query: ({ region, uid }) => ({
        url: `config_manager/get_yaml/${region}/${uid}`,
        responseHandler: (response) => response.text(),
      }),
      get query() {
        return this._query;
      },
      set query(value) {
        this._query = value;
      },
      providesTags: ["Yaml"],
    }),
    runConfig: builder.mutation({
      query: ({ uuid, params }) => ({
        url: `config_manager/run/${uuid}/${params}`,
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      // invalidatesTags: [{ type: "Configs", id: "ConfigsList" }],
    }),
    suspendConfig: builder.mutation({
      query: (uuid) => ({
        url: `config_manager/suspend/${uuid}`,
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Configs", id: "ConfigsList" }],
    }),
    saveYaml: builder.mutation({
      query: ({ yaml, type }) => ({
        url: `config_manager/${type}/yaml`,
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-yaml",
        },
        body: yaml,
      }),
      invalidatesTags: ["Yaml"],
    }),
    uploadYaml: builder.mutation({
      query: ({ formData, type }) => ({
        url: `config_manager/${type}/file`,
        method: "POST",
        headers: {
          accept: "application/json",
        },

        body: formData,
      }),
      invalidatesTags: ["Yaml", { type: "Configs", id: "ConfigsList" }],
    }),
  }),
});

export const {
  useGetConfigsQuery,
  useGetYamlQuery,
  useSuspendConfigMutation,
  useRunConfigMutation,
  useSaveYamlMutation,
  useUploadYamlMutation,
  useLazyGetConfigsByUidQuery,
  useLazyDownloadConfigQuery,
} = configApi;
