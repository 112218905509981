import React from "react";
import styles from "./button.module.css";

export const MainButton = ({ children, handleClick }) => {
  return (
    <button className={`btn ${styles.submit_btn}`} onClick={handleClick}>
      {children}
    </button>
  );
};

export const SecButton = ({ children, handleClick, classes }) => {
  return (
    <button
      type="button"
      className={`btn ${styles.tableButton} ${classes}`}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
