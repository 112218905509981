import { createSlice } from "@reduxjs/toolkit";
import { defaultFilters } from "../utils/utils";

export const filterSlice = createSlice({
  name: "filterSlice",
  initialState: defaultFilters(),
  reducers: {
    saveDashboardDate: (state, action) => {
      state.dashboardDate = action.payload;
    },
    saveSupplyDate: (state, action) => {
      state.supplyDmdDate = action.payload;
    },
    saveConfigTxt: (state, action) => {
      state.configSearchText = action.payload;
    },
    saveStoreTxt: (state, action) => {
      state.storeSearchText = action.payload;
    },
    saveOrderTxt: (state, action) => {
      state.orderSearchTxt = action.payload;
    },
    setOrderRange: (state, action) => {
      state.ordersFilters.orderDate = action.payload;
    },
    setDeliveryRange: (state, action) => {
      state.ordersFilters.delDate = action.payload;
    },
    setDistName: (state, action) => {
      state.ordersFilters.distributorName = action.payload;
    },
    setLineItemsTxt: (state, action) => {
      state.lineItemsTxt = action.payload;
    },
    setNotificationsTxt: (state, action) => {
      state.notificationTxt = action.payload;
    },
    setInventoryTxt: (state, action) => {
      state.inventorySearchText = action.payload;
    },
    setDistributorTxt: (state, action) => {
      state.distributorSearchText = action.payload;
    },
    setSupplyDistributorTxt: (state, action) => {
      state.supplyDistributorSearchText = action.payload;
    },
    saveActiveConfigVal: (state, action) => {
      state.activeConfig = action.payload;
    },
    setInventoryRange: (state, action) => {
      state.inventoryDate = action.payload;
    },
  },
});

export const {
  saveDashboardDate,
  saveConfigTxt,
  saveStoreTxt,
  saveOrderTxt,
  setOrderRange,
  setDeliveryRange,
  setDistName,
  setLineItemsTxt,
  setNotificationsTxt,
  saveSupplyDate,
  saveActiveConfigVal,
  setInventoryTxt,
  setInventoryRange,
  setDistributorTxt,
  setSupplyDistributorTxt,
} = filterSlice.actions;
