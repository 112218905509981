import React, { useEffect, useState } from "react";
import { Container, Accordion, Alert, Row, Col } from "react-bootstrap";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import { useGetResponsesQuery } from "../../service/aibotService";
import Loader from "../../Componenets/Loader/Loader";
import ReactMarkdown from "react-markdown";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import styles from "./aibot.module.css";
import { Tablet } from "../../Componenets/react-responsive/responsiveDevices";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { TagsInput } from "react-tag-input-component";
// import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import AdvcSearchFilter from "../../Componenets/AdvSearchFilter/SearchFilter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SecButton } from "../../Componenets/buttons/button";
import Pagination from "../../Componenets/Pagination/Pagination";

const animatedComponents = makeAnimated();
const today = new Date();
const feedBackTypeOpt = [
  { value: "all", label: "All" },
  { value: "true", label: "Positive" },
  { value: "false", label: "Negative" },
];

const AIbot = () => {
  const [feedbackTypeVal, setFeedbackTypeVal] = useState({
    value: "all",
    label: "All",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [searchText, setSearchText] = useState();
  const [selectedConfigs, setSelectedConfigs] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [postsPerPage, setPostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [queryParams, setQueryParams] = useState(
    `feedback_type=${feedbackTypeVal.value}&page=${currentPage}&limit=${postsPerPage}`
  );

  const { data, error, isFetching } = useGetResponsesQuery(queryParams);

  useEffect(() => {
    // setQueryParams(`feedback_type=${feedbackTypeVal.value}`);
    let queryStr = `feedback_type=${feedbackTypeVal.value}&page=1&limit=${postsPerPage}`;
    if (searchText) {
      queryStr += `&search_text=${searchText}`;
    }
    if (startDate) {
      const dateObject = new Date(startDate);
      const formattedDate = dateObject.toISOString();
      queryStr += `&startDate=${formattedDate}`;
    }
    if (endDate) {
      const dateObject = new Date(endDate);
      const formattedDate = dateObject.toISOString();
      queryStr += `&endDate=${formattedDate}`;
    }
    selectedConfigs.forEach((val) => {
      queryStr += `&app_uuid=${val}`;
    });
    selectedUsers.forEach((val) => {
      queryStr += `&yum_id=${val}`;
    });
    setCurrentPage(1);

    setQueryParams(queryStr);
  }, [
    feedbackTypeVal,
    selectedConfigs,
    searchText,
    selectedUsers,
    startDate,
    endDate,
    postsPerPage,
  ]);

  const handleFeedback = (val) => {
    console.log(val);
    setFeedbackTypeVal(val);
  };

  const handleOrderDate = (update) => {
    const [start, end] = update;
    setStartDate(start);
    setEndDate(end);
  };

  const handlePageChange = (newPage) => {
    let queryStr = `feedback_type=${feedbackTypeVal.value}&page=${newPage}&limit=${postsPerPage}`;
    if (searchText) {
      queryStr += `&search_text=${searchText}`;
    }
    if (startDate) {
      const dateObject = new Date(startDate);
      const formattedDate = dateObject.toISOString();
      queryStr += `&startDate=${formattedDate}`;
    }
    if (endDate) {
      const dateObject = new Date(endDate);
      const formattedDate = dateObject.toISOString();
      queryStr += `&endDate=${formattedDate}`;
    }
    selectedConfigs.forEach((val) => {
      queryStr += `&app_uuid=${val}`;
    });
    selectedUsers.forEach((val) => {
      queryStr += `&yum_id=${val}`;
    });

    setQueryParams(queryStr);
    setCurrentPage(newPage);
  };

  console.log(data);
  console.log(currentPage);
  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"AiBot Responses"} />
        <div className="card-box" style={{ padding: "1rem" }}>
          <Row className={"mt-2 no-gutters " + styles.filterWrapper}>
            <Col className={styles.datePickerWrapper}>
              <label
                htmlFor="store-filter-search-string"
                className={`no-space-break`}
                style={{ alignSelf: "center" }}
              >
                Date
              </label>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  handleOrderDate(update);
                }}
                className={styles.datePickerIp}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown={true}
                isClearable={true}
                maxDate={today}
              />
            </Col>

            <Col className="xs-4">
              <label className={styles.distName}>
                Configs
                <div
                  className=""
                  style={{ marginTop: "7%", marginLeft: "2%", width: "100%" }}
                >
                  <TagsInput
                    value={selectedConfigs}
                    onChange={setSelectedConfigs}
                    name="configs"
                    placeHolder="Enter configs"
                    classNames={{ tag: "tag-cls", input: styles.inputCls }}
                  />
                  <small>
                    <em>press enter or comma to add new tag</em>
                  </small>
                </div>
              </label>
            </Col>

            <Col className="xs-4">
              <label className={styles.distName}>
                User Email
                <div
                  className=""
                  style={{ marginTop: "7%", marginLeft: "2%", width: "100%" }}
                >
                  <TagsInput
                    value={selectedUsers}
                    onChange={setSelectedUsers}
                    name="users"
                    placeHolder="Enter Yum Id"
                    classNames={{ tag: "tag-cls", input: styles.inputCls }}
                  />
                  <small>
                    <em>press enter or comma to add new tag</em>
                  </small>
                </div>
              </label>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={currentPage}
              />
            </Col>
            <Col>
              <label className={styles.distName}>
                <Tablet> Feedback Type </Tablet>
                <Select
                  classNamePrefix={styles.asyncSel}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  isMulti={false}
                  options={feedBackTypeOpt}
                  value={feedbackTypeVal}
                  onChange={(val) => handleFeedback(val)}
                  className={styles.feedbackType}
                />
              </label>
            </Col>
            <Col>
              <div className={styles.searchWrapper} style={{ float: "right" }}>
                <AdvcSearchFilter
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </div>
            </Col>
          </Row>
        </div>
        {isFetching ? (
          <Loader />
        ) : error ? (
          <>
            <Alert variant="danger mt-4">
              Some Error occured. Please contant administrator
            </Alert>
          </>
        ) : (
          <>
            <Accordion defaultActiveKey={["0"]} alwaysOpen className="">
              {data &&
                data.data.map((item, index) => (
                  <Accordion.Item
                    eventKey={`${index}`}
                    style={{ backgroundColor: "#f0f0f0" }}
                    className="customAccordion"
                    key={item.response_id}
                  >
                    <Accordion.Header> {item.prompt}</Accordion.Header>
                    <Accordion.Body>
                      <ReactMarkdown>{item.message}</ReactMarkdown>
                      <h5 className="mt-4">Feedback Details:</h5>
                      {typeof item.feedback === "boolean" ? (
                        <>
                          <p>
                            <strong>Rating:</strong>{" "}
                            {item.feedback ? (
                              <AiFillLike color="#008000" size={20} />
                            ) : (
                              <AiFillDislike color="#ec1b25" size={20} />
                            )}
                          </p>
                          <p>
                            {" "}
                            <strong>Feedback:</strong> {item.feedback_details}
                          </p>
                        </>
                      ) : (
                        "No feedback provided"
                      )}
                      <h5 className="mt-4">Other Details:</h5>
                      <p>
                        {" "}
                        <strong>App UUID:</strong> {item.app_uuid}
                      </p>
                      <p>
                        {" "}
                        <strong>User:</strong> {item.yum_id}
                      </p>
                      <p>
                        {" "}
                        <strong>Conversation Name:</strong>{" "}
                        {item.conversation_name}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
            {/* {data.more_posts && ( */}
            <Row>
              <Col className="no-gutters">
                {/* <SecButton handleClick={loadMore}>Load More</SecButton> */}
                <Pagination
                  onPageChange={handlePageChange}
                  totalCount={data.total_posts}
                  siblingCount={1}
                  currentPage={currentPage}
                  pageSize={postsPerPage}
                />
              </Col>
            </Row>
            {/* )} */}
            <Row>
              <Col style={{ height: "30px" }}> </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default AIbot;
