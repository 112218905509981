export const envTypes = {
  development: "development",
  staging: "staging",
  production: "production",
};
export const env = envTypes.staging;
let dfpBaseUrl,
  dfpApi,
  subDomain,
  loginPath,
  domain,
  dfpAdminUrl,
  aibotApiUrl,
  craveAIDomain,
  aibotDomain;

export const skipLogin = env === "development" ? true : false;

if (env === envTypes.development) {
  dfpBaseUrl = "http://localhost:8001/rest/v2/standard/";
  dfpApi = "http://localhost:8001/rest/v2/";
  subDomain = "/dfp-admin";
  loginPath = "http://localhost:8000/login";
  domain = "http://localhost:3000/";
  dfpAdminUrl = "http://localhost:3000/dfp-admin";
  aibotApiUrl = "http://localhost:8002/rest/v2/realm/";
  craveAIDomain = "http://craveai.nonprod.yumds.com/";
  aibotDomain = "https://re-api.nonprod.yumds.com/";
}
if (env === envTypes.staging) {
  dfpBaseUrl = "https://dfp-api.nonprod.yumds.com/rest/v2/standard/";
  dfpApi = "https://dfp-api.nonprod.yumds.com/rest/v2/";
  subDomain = "/dfp-admin";
  loginPath = "https://dfp-api.nonprod.yumds.com/login";
  domain = "https://dfp.nonprod.yumds.com";
  dfpAdminUrl = "https://dfp.nonprod.yumds.com/dfp-admin/";
  aibotApiUrl = "https://dfp-api.nonprod.yumds.com/rest/v2/realm/";
  craveAIDomain = "http://craveai.nonprod.yumds.com/";
  aibotDomain = "https://re-api.nonprod.yumds.com/";
}

if (env === envTypes.production) {
  dfpBaseUrl = "https://dfp-api.yumds.com/rest/v2/standard/";
  dfpApi = "https://dfp-api.yumds.com/rest/v2/";
  subDomain = "/dfp-admin";
  loginPath = "https://dfp-api.yumds.com/login";
  domain = "https://dfp.yumds.com/";
  dfpAdminUrl = "https://dfp.yumds.com/dfp-admin/";
  aibotApiUrl = "https://dfp-api.yumds.com/rest/v2/realm/";
  craveAIDomain = "http://craveai.yumds.com/";
  aibotDomain = "https://aibot.yumds.com/";
}

export {
  dfpBaseUrl,
  dfpApi,
  subDomain,
  loginPath,
  domain,
  dfpAdminUrl,
  aibotApiUrl,
  craveAIDomain,
  aibotDomain,
};
