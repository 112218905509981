import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import styles from "./distributordetails.module.css";

import PageTitle from "../../Componenets/PageTitle/PageTitle";

import {
  distributorApi,
  useGetDistributorStoresQuery,
  useGetRegionalDistributorQuery,
} from "../../service/distributorService";
import { useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";

import { useGetNonIntegratedOrderGuideQuery } from "../../service/orderGuideService";

import {
  Mobile,
  Tablet,
} from "../../Componenets/react-responsive/responsiveDevices";

import TableButton from "../../Componenets/TableButtons/TableButton";

import { IoMdAddCircle } from "react-icons/io";
import DistributorStores from "./DistributorStores";
import DistributorsBasicDetials from "./DistributorsBasicDetials";
import AddStores from "./AddStores";

const DistributorsDetials = () => {
  const { region, distId } = useParams();
  const [searchParams] = useSearchParams();

  const [data, setData] = useState();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") ? searchParams.get("tab") : "distributorStores"
  );
  const kind = searchParams.get("kind");

  const [skip, setSkip] = useState(true);
  const [skipOrderGuide] = useState(kind === "Non-Integrated" ? false : true);

  const { data: distCachedData } = useSelector(
    distributorApi.endpoints.getRegionalDistributor.select(1)
  );

  const {
    data: distStores,
    error: distStoresErr,
    isFetching: distFetching,
  } = useGetDistributorStoresQuery({ distributorId: distId, regionId: region });

  const {
    data: distData,
    error,
    isLoading,
  } = useGetRegionalDistributorQuery(region, {
    skip,
  });

  const {
    data: orderGuideData,
    error: orderGuideError,
    isFetching: orderGuideLoading,
  } = useGetNonIntegratedOrderGuideQuery(distId, {
    skip: skipOrderGuide,
  });

  useEffect(() => {
    if (distCachedData || (distData && distData.length > 0)) {
      const distributorData = distCachedData ? distCachedData : distData;
      const distributor = distributorData.find(
        (item) => item.distributor_id === Number(distId)
      );
      setData(distributor);
    } else {
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distCachedData, distData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Default Gins",
        accessor: "default_gins",
      },
      {
        Header: "Order Guide Id",
        accessor: "non_integrated_default_order_guides_id",
      },
    ],
    []
  );

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Distributor Details"} />

        <Row>
          <div className={`config-tabs ${styles.config_tabs}`}>
            <div className="d-flex mt-3">
              <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
              <span className="ml-auto text-nowrap border-bottom">
                <Tablet>
                  <div
                    title="Download"
                    className={styles.action_btn_links}
                    rel="noreferrer"
                    style={{ margin: "5px" }}
                    onClick={() => setActiveTab("addStores")}
                  >
                    <TableButton>
                      <IoMdAddCircle />
                      Add Stores
                    </TableButton>
                  </div>
                </Tablet>
                <Mobile>
                  <Link
                    title="Download"
                    className={styles.mob_action_btn_links}
                    to={`#`}
                    rel="noreferrer"
                    style={{ margin: "5px" }}
                    onClick={() => setActiveTab("addStores")}
                  >
                    <TableButton>
                      <IoMdAddCircle />
                    </TableButton>
                  </Link>
                </Mobile>
              </span>
            </div>
          </div>
          {activeTab === "distributorBasicDetails" && (
            <DistributorsBasicDetials
              kind={kind}
              orderGuideLoading={orderGuideLoading}
              orderGuideError={orderGuideError}
              orderGuideData={orderGuideData}
              columns={columns}
              isFetching={isLoading}
              error={error}
              data={data}
              region={region}
              distId={distId}
            />
          )}
          {activeTab === "distributorStores" && (
            <Row style={{ margin: "0" }}>
              <div className="card-box mt-3">
                <DistributorStores
                  distFetching={distFetching}
                  distStoresErr={distStoresErr}
                  distStores={distStores}
                  region={region}
                />
              </div>
            </Row>
          )}
          {activeTab === "addStores" && (
            <Row style={{ margin: "0" }}>
              <div className="card-box mt-3">
                <AddStores
                  distFetching={distFetching}
                  distStoresErr={distStoresErr}
                  distStores={distStores}
                  region={region}
                  distId={distId}
                  setActiveTab={setActiveTab}
                />
              </div>
            </Row>
          )}
        </Row>
      </Container>
    </div>
  );
};

const Tabs = ({ activeTab, setActiveTab }) => {
  return (
    <ul className={`nav nav-tabs flex-grow-1 flex-nowrap ${styles.tabWrap}`}>
      <li className={`nav-item ${styles.cf_tab}`}>
        <Link
          className={`nav-link ${
            activeTab === "distributorStores" ? styles.active : ""
          } ${styles.cf_link}`}
          to="#"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("distributorStores");
          }}
        >
          Distributor Stores
        </Link>
      </li>
      <li className={`nav-item ${styles.cf_tab}`}>
        <Link
          className={`nav-link ${
            activeTab === "distributorBasicDetails" ? styles.active : ""
          } ${styles.cf_link}`}
          to="#"
          onClick={(e) => {
            e.preventDefault();
            setActiveTab("distributorBasicDetails");
          }}
        >
          Distributor Details
        </Link>
      </li>
    </ul>
  );
};

export default DistributorsDetials;
