import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpApi } from "../constants/constants";

export const callScheduleApi = createApi({
  reducerPath: "callScheduleApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpApi,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  keepUnusedDataFor: 864000,
  endpoints: (builder) => ({
    getRegionalDistributor: builder.query({
      query: (region) => `/supply/Distributors/${region}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ distributor_id }) => ({
                type: "RegionDistributor",
                distributor_id,
              })),
              { type: "RegionDistributor", id: "RegionDistributorList" },
            ]
          : [{ type: "RegionDistributor", id: "RegionDistributorList" }],
    }),

    getCallSchedules: builder.query({
      query: ({ storeId, url }) => `/call_schedule/${storeId}?${url}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ STORE_ID }) => ({
                type: "CallSchedule",
                STORE_ID,
              })),
              { type: "CallSchedule", id: "CallScheduleList" },
            ]
          : [{ type: "CallSchedule", id: "CallScheduleList" }],
    }),
  }),
});

export const { useGetCallSchedulesQuery } = callScheduleApi;
