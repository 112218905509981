import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const AddRegions = ({ markets, region, brands }) => {
  const { marketData, marketError } = markets ? markets : null;
  const { brandData, brandError } = brands ? brands : null;

  return (
    <Form>
      <Row>
        <Form.Group as={Col} className="mb-3" controlId="regionName">
          <Form.Label>Region Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter region name"
            defaultValue={(region && region.region_name) || ""}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group
          as={Col}
          xs={12}
          sm={6}
          className={"mb-3"}
          controlId="market"
        >
          <Form.Label>Select Market</Form.Label>
          <Form.Select defaultValue={(region && region.market_id) || ""}>
            <option value="">Select</option>
            {marketError ? (
              <option>Error Occured</option>
            ) : (
              marketData &&
              marketData.map((item, index) => (
                <option key={index} value={item.market_id}>
                  {item.market_name}
                </option>
              ))
            )}
          </Form.Select>
        </Form.Group>
        <Form.Group
          as={Col}
          xs={12}
          sm={6}
          className={"mb-3"}
          controlId="brand"
        >
          <Form.Label>Select Brand</Form.Label>
          <Form.Select defaultValue={(region && region.brand_id) || ""}>
            <option value="">Select</option>
            {brandError ? (
              <option>Error Occured</option>
            ) : (
              brandData &&
              brandData.map((item, index) => (
                <option key={index} value={item.brand_id}>
                  {item.brand_name}
                </option>
              ))
            )}
          </Form.Select>
        </Form.Group>
      </Row>
    </Form>
  );
};

export default AddRegions;
