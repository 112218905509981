import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpBaseUrl } from "../constants/constants";

export const storesApi = createApi({
  reducerPath: "storesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  keepUnusedDataFor: 864000,
  endpoints: (builder) => ({
    getStores: builder.query({
      query: (region) => `stores/${region}/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ chained_store_id }) => ({
                type: "Stores",
                chained_store_id,
              })),
              { type: "Stores", chained_store_id: "storesList" },
            ]
          : [{ type: "Stores", chained_store_id: "storesList" }],
    }),
    getPaginatedStores: builder.query({
      query: ({ region, page, searchTxt }) =>
        `active_stores_list/${region}/?page=${page}&search=${searchTxt}`,
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ chained_store_id }) => ({
                type: "PaginatedStores",
                chained_store_id,
              })),
              {
                type: "PaginatedStores",
                chained_store_id: "PaginatedstoresList",
              },
            ]
          : [
              {
                type: "PaginatedStores",
                chained_store_id: "PaginatedstoresList",
              },
            ],
    }),
    getPaginatedInActiveStores: builder.query({
      query: ({ region, page, searchTxt }) =>
        `inactive_stores_list/${region}/?page=${page}&search=${searchTxt}`,
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ chained_store_id }) => ({
                type: "PaginatedInActiveStores",
                chained_store_id,
              })),
              {
                type: "PaginatedInActiveStores",
                chained_store_id: "PaginatedInActiveStoresList",
              },
            ]
          : [
              {
                type: "PaginatedInActiveStores",
                chained_store_id: "PaginatedInActiveStoresList",
              },
            ],
    }),
    getStoresDetails: builder.query({
      query: ({ region, chained_store_id }) =>
        `store/${region}/${chained_store_id}/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ chained_store_id }) => ({
                type: "Stores",
                chained_store_id,
              })),
              { type: "Stores", chained_store_id: "storesList" },
            ]
          : [{ type: "Stores", chained_store_id: "storesList" }],
    }),
  }),
});

export const {
  useGetStoresQuery,
  useGetPaginatedStoresQuery,
  useGetStoresDetailsQuery,
  useGetPaginatedInActiveStoresQuery,
} = storesApi;
