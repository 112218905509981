import React, { useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import PageTitle from "../../Componenets/PageTitle/PageTitle";

import { useParams } from "react-router-dom";
import { useGetIntegratedOrderGuideQuery } from "../../service/orderGuideService";
import Loader from "../../Componenets/Loader/Loader";
import ReactTable from "../../Componenets/ReactTable/ReactTable";

import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import {
  Desktop,
  Mobile,
} from "../../Componenets/react-responsive/responsiveDevices";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      id="status-filter"
      style={{ borderRadius: "10px", borderColor: "#bec6cd" }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

const OrderGuide = () => {
  const { distId, storeId } = useParams();
  const [setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(20);
  // const [searchParams] = useSearchParams();
  // const kind = searchParams.get("kind");
  // const [filteredPosts, setFilteredPosts] = useState([]);

  const {
    data: orderGuideData,
    error: orderGuideError,
    isFetching: orderGuideLoading,
  } = useGetIntegratedOrderGuideQuery({ storeId, distId });

  const columns = React.useMemo(
    () => [
      {
        Header: "GIN Id",
        accessor: "gin_id",
      },
      {
        Header: "GIN desc",
        accessor: "gin_desc",
      },
      {
        Header: "Status",
        accessor: "item_status",
        Filter: SelectColumnFilter,
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Order Guide"} />

        <div className="mt-3 card-box h-100">
          <Row className="mt-2 no-gutters">
            <Col lg={9} md={9} xs={2}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>

            <Desktop>
              <Col xs={3}>
                <div>
                  <SearchFilter
                  //   searchText={searchText}
                  //   setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Desktop>

            <Mobile>
              <Col xs={12}>
                <div className="mt-3 mb-3">
                  <SearchFilter
                  //   searchText={searchText}
                  //   setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Mobile>
          </Row>
          {/* <p style={{ margin: "5px 0" }}>
                <TableTitle title={`${kind} Order Guide`} isHome={true} />
              </p> */}

          {orderGuideLoading ? (
            <Loader className="mt-2 " />
          ) : orderGuideError ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <ReactTable
              columns={columns}
              data={orderGuideData ? orderGuideData : []}
              pageRows={10}
              disableFilters={false}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default OrderGuide;
