import React, { useCallback, useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import styles from "./activestoresscreen.module.css";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Pause, SupplyDemand } from "../../Componenets/Icons/Icons";
import {
  addParams,
  addQueryParams,
  convertArrValToString,
  filterObject,
  isEmpty,
  removeQueryParams,
} from "../../utils/utils";
import { useSearchParams } from "react-router-dom";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { saveStoreTxt } from "../../service/filterSlice";
import ReactTable from "../../Componenets/ReactTable/ReactTable";
import useDebounce from "../../custom_hooks/use-debounce";
import {
  useGetActiveStoresQuery,
  useUpdateActiveStoresMutation,
} from "../../service/activeStoresService";
import { filter } from "smart-array-filter";
import { Desktop } from "../../Componenets/react-responsive/responsiveDevices";
import AddNewButton from "../../Componenets/AddNewButton/AddNewButton";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";

const MySwal = withReactContent(Swal);

const ActiveStores = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(50);
  const region = useSelector((state) => state.region);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const srchTxt = searchParams.get("searchText");
  const { storeSearchText } = useSelector((state) => state.filterSlice);

  const [skip, setSkip] = useState(true);
  const userManagementId = Cookies.get("user_management_id");

  const [searchText, setSearchText] = useState(
    srchTxt ? srchTxt : storeSearchText
  );
  const [filteredPosts, setFilteredPosts] = useState([]);

  const { data, error, isFetching } = useGetActiveStoresQuery(region);

  const [updateActiveStores] = useUpdateActiveStoresMutation();

  const getSearchedPosts = (data) => {
    const result = convertArrValToString(data);
    let matchingRes = filter(result, {
      keywords: searchText,
      includePaths: [
        "program_status_id",
        "chained_store_id",
        "city",
        "franchisee",
      ],
      excludedPaths: [],
      caseSensitive: false,
    });
    setFilteredPosts(matchingRes);
  };

  useEffect(() => {
    setCurrentPage(1);
    addParams(searchText);
    if (data) {
      if (!isEmpty(searchText) && data.length > 0) {
        getSearchedPosts(data);
      } else if (data.length > 0) {
        setFilteredPosts([...data]);
      } else {
        setFilteredPosts([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    if (userManagementId && region) setSkip(false);
  }, [userManagementId, region]);

  const pauseStores = useCallback(
    (id) => {
      updateActiveStores({ region, programStatusId: id });
    },
    [region, updateActiveStores]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Program Status ID",
        accessor: "program_status_id",
      },
      {
        Header: "Chain ID",
        accessor: "chained_store_id",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Franchise",
        accessor: "franchisee",
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell }) => (
          <>
            <span
              onClick={() => pauseStores(cell.row.original.program_status_id)}
              style={{ cursor: "pointer" }}
            >
              <Pause />
            </span>
            <SupplyDemand
              link={`/stores/${region}/${cell.row.original.chained_store_id}/`}
            />
          </>
        ),
        disableSortBy: true,
      },
    ],
    [currentPage, pauseStores, region, searchText]
  );

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Active Stores"} />
        <div className="card-box">
          <Row className="mt-2 no-gutters">
            <Col lg={5} md={5} xs={2}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
            <Desktop>
              <Col className={styles.searchWrapper} xs={4}>
                <SearchFilter
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </Col>
            </Desktop>
            <Col xs={3} style={{ float: "right" }}>
              <Link to={`/supply-demand/${region}/add-active-stores`}>
                <AddNewButton />
              </Link>
            </Col>
          </Row>
          {isFetching ? (
            <Loader />
          ) : error ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="mt-3">
                  <ReactTable
                    columns={columns}
                    data={filteredPosts}
                    pageRows={postsPerPage}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ActiveStores;
