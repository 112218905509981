import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useSearchParams } from "react-router-dom";
import { addParams, convertArrValToString, isEmpty } from "../../utils/utils";
import { filter } from "smart-array-filter";

import { Row, Col, Alert, Spinner } from "react-bootstrap";

import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import {
  Desktop,
  Mobile,
} from "../../Componenets/react-responsive/responsiveDevices";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import Loader from "../../Componenets/Loader/Loader";
import ReactTable from "../../Componenets/ReactTable/ReactTable";
import styles from "./distributordetails.module.css";
import { storesApi, useGetStoresQuery } from "../../service/storeService";
import { MainButton } from "../../Componenets/buttons/button";
import { useBulkSaveDistributorMutation } from "../../service/distributorService";
import { SweetAlerts } from "../../Componenets/SweetAlerts/SweetAlerts";
import TableTitle from "../../Componenets/TableTitle/TableTitle";

const AddStores = ({
  distFetching,
  distStoresErr,
  distStores,
  region,
  distId,
  setActiveTab,
}) => {
  const [setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(20);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [bulkSaveDistributor, bulkSaveDistributorInfo] =
    useBulkSaveDistributorMutation();

  const [searchParams] = useSearchParams();
  const srchTxt = searchParams.get("searchText");

  const [searchText, setSearchText] = useState(srchTxt);
  const [selectedRows, setSelectedRows] = useState([]);

  const [skip, setSkip] = useState(true);

  const [data, setData] = useState([]);

  const { data: storeCachedData } = useSelector(
    storesApi.endpoints.getStores.select(Number(region))
  );

  const {
    data: storeData,
    error,
    isLoading,
  } = useGetStoresQuery(region, {
    skip,
  });

  useEffect(() => {
    if (storeCachedData || (storeData && storeData.length > 0)) {
      const allStores = storeCachedData ? storeCachedData : storeData;
      if (distStores) {
        const uniqueStores = allStores.filter(
          ({ store_id }) =>
            !distStores.some(({ STORE_ID }) => STORE_ID === store_id)
        );
        setData(uniqueStores);
      }
    } else {
      console.log("cache not found");
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeCachedData, storeData, distStores]);

  const getSearchedPosts = (data) => {
    const result = convertArrValToString(data);
    let matchingRes = filter(result, {
      keywords: searchText,
      includePaths: ["chained_store_id", "city", "franchisee"],
      excludedPaths: [],
      caseSensitive: false,
    });
    setFilteredPosts(matchingRes);
  };

  useEffect(() => {
    addParams(searchText);
    if (data) {
      if (!isEmpty(searchText) && data.length > 0) {
        getSearchedPosts(data);
      } else if (data.length > 0) {
        setFilteredPosts([...data]);
      } else {
        setFilteredPosts([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  const storeColumns = React.useMemo(
    () => [
      {
        Header: "Chain ID",
        accessor: "chained_store_id",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Franchise",
        accessor: "franchisee",
      },
    ],
    []
  );

  const addStores = () => {
    const data = [];
    selectedRows.forEach((element) => {
      data.push({
        distributor_id: distId,
        store_id: filteredPosts[element].store_id,
      });
    });
    bulkSaveDistributor(data);
  };

  useEffect(() => {
    SweetAlerts(bulkSaveDistributorInfo, `Stores added successfully`);
    if (bulkSaveDistributorInfo.isSuccess) setActiveTab("distributorStores");
  }, [bulkSaveDistributorInfo, setActiveTab]);

  return (
    <Row className="mt-2 no-gutters row">
      <div className="mb-3">
        <TableTitle title={`Add stores to distributors`} isHome={true} />
      </div>

      <Col xs={3}>
        <PostPerPage
          limit={postsPerPage}
          setPostPerPage={setPostPerPage}
          setCurrentPage={setCurrentPage}
        />
      </Col>

      <Desktop>
        <Col xs={9}>
          <div className={styles.searchWrapper}>
            <SearchFilter
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
        </Col>
      </Desktop>

      <Mobile>
        <Col xs={12}>
          <div className="mt-3 mb-3">
            <SearchFilter
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
        </Col>
      </Mobile>
      <Col xs={12} md={12}>
        <Col className="mt-1">
          {isLoading || distFetching ? (
            <Loader className="mt-2" />
          ) : error || distStoresErr ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <ReactTable
              columns={storeColumns}
              data={filteredPosts ? filteredPosts : []}
              pageRows={postsPerPage}
              allowRowSelect={true}
              setSelectedRows={setSelectedRows}
            />
          )}
        </Col>
      </Col>
      {selectedRows.length > 0 && (
        <Col xs={12} className="text-center">
          <MainButton handleClick={addStores}>
            {bulkSaveDistributorInfo.isLoading ? (
              <>
                Adding
                <Spinner
                  style={{ marginLeft: "5px" }}
                  animation="border"
                  size="sm"
                />
              </>
            ) : (
              <>Add Stores</>
            )}
          </MainButton>
        </Col>
      )}
    </Row>
  );
};

export default AddStores;
