import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpBaseUrl } from "../constants/constants";
import { userAccess } from "../utils/utils";

export const regionsApi = createApi({
  reducerPath: "regionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getRegions: builder.query({
      query: () => `regions/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Regions", id })),
              { type: "Regions", id: "RegionsList" },
            ]
          : [{ type: "Regions", id: "RegionsList" }],
      transformResponse: (response) => {
        const access = userAccess();
        if (access.write.includes("region")) {
          return response;
        }
        return response.filter((item) =>
          access.region.includes(item.region_id)
        );
      },
    }),
    createRegion: builder.mutation({
      query: (body) => ({
        url: `regions/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Regions", id: "RegionsList" }],
    }),

    updateRegion: builder.mutation({
      query: (id, body) => ({
        url: `regions/${id}/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Regions", id: "RegionsList" }],
    }),
  }),
});

export const {
  useGetRegionsQuery,
  useCreateRegionMutation,
  useUpdateRegionMutation,
} = regionsApi;

//test
