import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpApi } from "../constants/constants";

export const distributorApi = createApi({
  reducerPath: "distributorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpApi,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getRegionalDistributor: builder.query({
      query: (region) => `/supply/Distributors/${region}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ distributor_id }) => ({
                type: "RegionDistributor",
                distributor_id,
              })),
              { type: "RegionDistributor", id: "RegionDistributorList" },
            ]
          : [{ type: "RegionDistributor", id: "RegionDistributorList" }],
    }),
    getDistributor: builder.query({
      query: (store) => `/supply/store_distributors/get_by_store_id/${store}/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ STORE_ID }) => ({
                type: "Distributor",
                STORE_ID,
              })),
              { type: "Distributor", id: "DistributorList" },
            ]
          : [{ type: "Distributor", id: "DistributorList" }],
    }),

    bulkSaveDistributor: builder.mutation({
      query: (body) => ({
        url: `/supply/store_distributors/save_bulk`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Distributor", id: "DistributorList" }],
    }),
    addDistributor: builder.mutation({
      query: (body) => ({
        url: `/supply/Distributors/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [
        { type: "RegionDistributor", id: "RegionDistributorList" },
      ],
    }),
    updateDistributor: builder.mutation({
      query: (body) => ({
        url: `/supply/Distributor/${body.distributor_id}/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [
        { type: "RegionDistributor", id: "RegionDistributorList" },
      ],
    }),
    getDistributorStores: builder.query({
      query: ({ distributorId, regionId }) =>
        `/supply/store_distributors/get_all_store_info/${distributorId}/${regionId}`,

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ STORE_ID }) => ({
                type: "DistributorStores",
                STORE_ID,
              })),
              { type: "DistributorStores", id: "DistributorStoresList" },
            ]
          : [{ type: "DistributorStores", id: "DistributorStoresList" }],
    }),
    deleteDistributor: builder.mutation({
      query: ({ store, distributorId, regionId }) => ({
        url: `/supply/store_distributors/${distributorId}/${store}`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),

      invalidatesTags: [{ type: "Distributor", id: "DistributorList" }],
    }),
  }),
});

export const {
  useGetDistributorQuery,
  useDeleteDistributorMutation,
  useGetRegionalDistributorQuery,
  useBulkSaveDistributorMutation,
  useAddDistributorMutation,
  useGetDistributorStoresQuery,
  useUpdateDistributorMutation,
} = distributorApi;
