import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Link,
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";

import { createBrowserHistory } from "history";

import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import { isEmpty } from "../../utils/utils";
import {
  Mobile,
  Tablet,
} from "../../Componenets/react-responsive/responsiveDevices";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import DatePicker from "react-datepicker";
import { filter } from "smart-array-filter";

import styles from "./notification.module.css";
import ReactTable from "../../Componenets/ReactTable/ReactTable";

const history = createBrowserHistory({ window });

const NotificationTable = ({
  notifications,
  region,
  handleClose,
  mobile = false,
  todayDate,
  setTodayDate,
  oneWeekFromNow,
}) => {
  const [setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(5);

  const [searchText, setSearchText] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    if (!isEmpty(searchText) && notifications && notifications.length > 0) {
      // const matchingRes = filterByVal(notifications, searchText);

      const notificationsArr = [];
      notifications.forEach((item) => notificationsArr.push(item));
      console.log(notificationsArr);
      const result = notificationsArr.map((obj) =>
        Object.fromEntries(
          Object.entries(obj).map(([key, val]) => [key, String(val)])
        )
      );
      console.log(result, notifications);

      const matchingRes = filter(result, {
        keywords: searchText, // search for any field that contains the "Do" string
        includePaths: [
          "process_id",
          "config_uuid",
          "chained_store_id",
          "subprocess_id",
          "create_date_time",
          "status",
          "time_difference_minutes",
        ],
        excludedPaths: [],
        caseSensitive: false,
      });
      setFilteredPosts(matchingRes);
    } else if (notifications && notifications.length > 0) {
      setFilteredPosts([...notifications]);
    } else if (notifications) {
      setFilteredPosts([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, searchText]);

  // const currentTableData = useMemo(() => {
  //   if (filteredPosts && filteredPosts.length > 0) {
  //     const firstPageIndex = (currentPage - 1) * postsPerPage;
  //     const lastPageIndex = firstPageIndex + postsPerPage;
  //     return filteredPosts.slice(firstPageIndex, lastPageIndex);
  //   }
  // }, [currentPage, filteredPosts, postsPerPage]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Process Id",
        accessor: "process_id",
      },
      {
        Header: "Config_uuid",
        accessor: "config_uuid",
        Cell: ({ cell }) => (
          <>
            {!mobile && (
              <HistoryRouter history={history}>
                {" "}
                <Link
                  to={`/config-editor/${region}/${cell.row.original.config_uuid}`}
                  onClick={() =>
                    handleClose(cell.row.original.config_uuid, "config")
                  }
                >
                  {cell.row.original.config_uuid}
                </Link>
              </HistoryRouter>
            )}
          </>
        ),
      },
      {
        Header: "Chained Store ID",
        accessor: "chained_store_id",
        Cell: ({ cell }) => (
          <>
            {!mobile && (
              <HistoryRouter history={history}>
                {" "}
                <Link
                  to={`/config-editor/${region}/${cell.row.original.chained_store_id}`}
                  onClick={() =>
                    handleClose(cell.row.original.chained_store_id)
                  }
                >
                  {cell.row.original.chained_store_id}
                </Link>
              </HistoryRouter>
            )}
          </>
        ),
      },
      {
        Header: "SubProcess ID",
        accessor: "subprocess_id",
        Cell: ({ cell }) =>
          cell.row.original.time_difference_minutes &&
          Number(cell.row.original.time_difference_minutes).toFixed(2),
      },
      {
        Header: "Total Time",
        accessor: "time_difference_minutes",
        Cell: ({ cell }) =>
          cell.row.original.time_difference_minutes &&
          Number(cell.row.original.time_difference_minutes).toFixed(2),
      },
      {
        Header: "Created Time",
        accessor: "create_date_time",
        Cell: ({ cell }) =>
          cell.row.original.create_date_time &&
          cell.row.original.create_date_time.split("T")[0],
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    [handleClose, mobile, region]
  );

  return (
    <>
      {/* <Container fluid> */}
      <Mobile>
        <Col xs={12} className="">
          <SearchFilter searchText={searchText} setSearchText={setSearchText} />
        </Col>
      </Mobile>
      <Row className="mb-3 mt-1">
        <Col xs={4}>
          <PostPerPage
            limit={postsPerPage}
            setPostPerPage={setPostPerPage}
            setCurrentPage={setCurrentPage}
          />
        </Col>
        <Tablet>
          <Col xs={8} className="text-end" style={{ textAlign: "right" }}>
            <SearchFilter
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </Col>
        </Tablet>
        <Mobile>
          <Col xs={8} sm={2} className="text-end customDatePickerWidth">
            <DatePicker
              selected={todayDate}
              onChange={(date) => setTodayDate(date)}
              maxDate={new Date()}
              className={"datePickerIp"}
              minDate={oneWeekFromNow}
            />
          </Col>
        </Mobile>
      </Row>
      {/* </Container> */}

      {/* <Table
        striped
        bordered
        hover
        className="table table-bordered mb-0 table-striped table-responsive"
        style={{ marginTop: "1%" }}
      >
        <Thead>
          <Tr>
            <th>Process Id</th>
            <th>Config_uuid</th>
            <th>Chained Store ID</th>
            <th>SubProcess ID</th>
            <th>Total Time</th>
            <th>Created Time</th>
            <th>Status</th>
          </Tr>
        </Thead>
        <Tbody>
          {currentTableData &&
            currentTableData.length > 0 &&
            currentTableData.map((item, index) => (
              <Tr key={item.process_id + index}>
                <Td style={{ width: !mobile ? "16%" : "" }}>
                  {item.process_id}
                </Td>
                {/* <Td>{item.topic} </Td> 
                <Td style={{ width: !mobile ? "16%" : "" }}>
                  {!mobile && (
                    <HistoryRouter history={history}>
                      {" "}
                      <Link
                        to={`/config-editor/${region}/${item.config_uuid}`}
                        onClick={() => handleClose(item.config_uuid, "config")}
                      >
                        {item.config_uuid}
                      </Link>
                    </HistoryRouter>
                  )}
                </Td>
                <Td>
                  {!mobile && (
                    <HistoryRouter history={history}>
                      <Link
                        to={`/stores/${region}/${item.chained_store_id}`}
                        onClick={() =>
                          handleClose(item.chained_store_id, "stores")
                        }
                      >
                        {item.chained_store_id}
                      </Link>
                    </HistoryRouter>
                  )}
                </Td>
                <Td style={{ width: !mobile ? "18%" : "" }}>
                  {item.subprocess_id}{" "}
                </Td>
                <Td>{Number(item.time_difference_minutes).toFixed(2)} </Td>
                <Td>
                  {item.create_date_time && item.create_date_time.split("T")[0]}
                </Td>
                <Td>{item.status} </Td>
              </Tr>
            ))}
        </Tbody>
      </Table> */}
      <div className={styles.notification_wrapper}>
        <ReactTable
          columns={columns}
          data={filteredPosts}
          pageRows={postsPerPage}
        />
      </div>
      {/* <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={filteredPosts.length}
        pageSize={postsPerPage}
        onPageChange={(page) => setCurrentPage(page)}
      /> */}
    </>
  );
};

export default NotificationTable;
