import React, { useCallback, useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";

import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import { useGetPaginatedStoresQuery } from "../../service/storeService";
import styles from "./storesscreen.module.css";

import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  AddBookmark,
  Analysis,
  RemoveBookmark,
  SupplyDemand,
} from "../../Componenets/Icons/Icons";
import {
  useAddBookmarkMutation,
  useGetBookmarkedStoresQuery,
  useRemoveBookmarkMutation,
} from "../../service/bookmarksService";
import {
  addParams,
  addQueryParams,
  filterObject,
  removeQueryParams,
} from "../../utils/utils";

import { useSearchParams } from "react-router-dom";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { saveStoreTxt } from "../../service/filterSlice";

import ReactTable from "../../Componenets/ReactTable/ReactTable";
import Pagination from "../../Componenets/Pagination/Pagination";
import useDebounce from "../../custom_hooks/use-debounce";

const StoresScreen = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(50);
  const region = useSelector((state) => state.region);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const srchTxt = searchParams.get("searchText");
  const { storeSearchText } = useSelector((state) => state.filterSlice);

  const [skip, setSkip] = useState(true);
  const userManagementId = Cookies.get("user_management_id");

  const [searchText, setSearchText] = useState(
    srchTxt ? srchTxt : storeSearchText
  );
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [withBookmarkPosts, setWithBookmarkPosts] = useState([]);

  const debouncedSearchQuery = useDebounce(searchText, 500);

  const { data, error, isFetching } = useGetPaginatedStoresQuery({
    region,
    page: currentPage,
    searchTxt: debouncedSearchQuery,
  });

  const { data: bkData } = useGetBookmarkedStoresQuery(
    { region, userId: userManagementId },
    { skip }
  );

  const [addBk] = useAddBookmarkMutation();
  const [removeBk] = useRemoveBookmarkMutation();

  useEffect(() => {
    const obj = {
      searchText,
    };
    const filterObj = filterObject(obj);
    let url = window.location.href;
    url = removeQueryParams(url);
    let refresh = addQueryParams(filterObj, url);
    window.history.replaceState({ path: refresh }, "", refresh);

    dispatch(saveStoreTxt(searchText));
    if (data && data.items.length > 0) {
      setFilteredPosts([...data.items]);
    } else if (data) {
      setFilteredPosts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    if (userManagementId && region) setSkip(false);
  }, [userManagementId, region]);

  // const currentTableData = useMemo(() => {
  //   if (filteredPosts && filteredPosts.length > 0) {
  //     const firstPageIndex = (currentPage - 1) * postsPerPage;
  //     const lastPageIndex = firstPageIndex + postsPerPage;
  //     return filteredPosts.slice(firstPageIndex, lastPageIndex);
  //   } else if (filteredPosts) {
  //     return [];
  //   }
  // }, [currentPage, filteredPosts, postsPerPage]);

  const addBookmark = useCallback(
    (id, store) => {
      const bdData = {
        user_management_id: userManagementId,
        bookmark_table: "STORES",
        value: id,
        store,
      };
      addBk({ body: bdData, region });
    },
    [userManagementId, addBk, region]
  );

  const removeBookmark = useCallback(
    (id, store) => {
      removeBk({
        type: "stores",
        value: id,
        user_management_id: userManagementId,
        region,
        store,
      });
    },
    [region, removeBk, userManagementId]
  );

  useEffect(() => {
    if (
      filteredPosts &&
      filteredPosts.length > 0 &&
      bkData &&
      bkData.length > 0
    ) {
      //   const bookmarkedStores = filteredPosts.map((item) => {
      //     let bookmark = bkData.filter((bkStore) => bkStore === item.store_id);

      //     if (bookmark && bookmark.length > 0)
      //       return { ...item, bookmarked: true };
      //     else return { ...item, bookmarked: false };
      //   });
      //   setWithBookmarkPosts(bookmarkedStores);
      // } else if (filteredPosts) {
      //   setWithBookmarkPosts(filteredPosts);
      // }

      const bookmarkedStores = filteredPosts.map((store) => {
        const isBookmarked = bkData.some((bookmarkObj) => {
          return bookmarkObj.store_id === store.store_id;
        });

        return { ...store, bookmarked: isBookmarked };
      });

      setWithBookmarkPosts(bookmarkedStores);
    } else if (filteredPosts) {
      setWithBookmarkPosts(filteredPosts);
    }
  }, [bkData, filteredPosts]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Chain ID	",
        accessor: "chained_store_id",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Franchise",
        accessor: "franchisee",
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell }) => (
          <>
            {cell.row.original.bookmarked ? (
              <RemoveBookmark
                handleClick={removeBookmark}
                id={cell.row.original.store_id}
                store={cell.row.original}
              />
            ) : (
              <AddBookmark
                handleClick={addBookmark}
                id={cell.row.original.store_id}
                store={cell.row.original}
              />
            )}

            <Analysis />
            <SupplyDemand
              link={`/stores/${region}/${cell.row.original.chained_store_id}/?page=${currentPage}&searchText=${searchText}`}
            />
          </>
        ),
        disableSortBy: true,
      },
    ],
    [addBookmark, currentPage, region, removeBookmark, searchText]
  );

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Stores"} />
        <div className="card-box">
          <Row className="mt-2 no-gutters">
            <Col xs={12} md={6}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
            <Col xs={12} md={6}>
              <div className={styles.searchWrapper}>
                <SearchFilter
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </div>
            </Col>
          </Row>
          {isFetching ? (
            <Loader />
          ) : error ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="mt-3">
                  <ReactTable
                    columns={columns}
                    data={withBookmarkPosts}
                    pageRows={postsPerPage}
                    showPagination={false}
                  />
                </div>
                <Pagination
                  onPageChange={setCurrentPage}
                  totalCount={data && data.total}
                  siblingCount={1}
                  currentPage={currentPage}
                  pageSize={postsPerPage}
                />
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

export default StoresScreen;
