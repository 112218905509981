import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { IoMdAddCircle } from "react-icons/io";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Add } from "../../Componenets/Icons/Icons";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import Cookies from "js-cookie";
import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import {
  Mobile,
  Tablet,
} from "../../Componenets/react-responsive/responsiveDevices";
import ReactTable from "../../Componenets/ReactTable/ReactTable";
import { SweetAlerts } from "../../Componenets/SweetAlerts/SweetAlerts";
import TableButton from "../../Componenets/TableButtons/TableButton";
import { useGetbrandsQuery } from "../../service/brandsService";
import { useGetMarketsQuery } from "../../service/marketsService";
import {
  useCreateRegionMutation,
  useGetRegionsQuery,
} from "../../service/regionsService";
import { useUpdateUserMutation } from "../../service/userService";
import { isEmpty, userFullAccess } from "../../utils/utils";
import AddRegions from "./AddRegions";

const MySwal = withReactContent(Swal);

const RegionManager = () => {
  const [setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(10);

  const { data, error, isLoading } = useGetRegionsQuery();
  const [updateUser] = useUpdateUserMutation();

  const { data: marketData, error: marketError } = useGetMarketsQuery();
  const { data: brandData, error: brandError } = useGetbrandsQuery();

  const [createRegion, responseInfo] = useCreateRegionMutation();

  const addRegion = () => {
    MySwal.fire({
      title: `Add Region`,
      html: (
        <AddRegions
          markets={{ marketData, marketError }}
          brands={{ brandData, brandError }}
        />
      ),
      confirmButtonText: `Save`,
      focusConfirm: false,
      showLoaderOnConfirm: true,
      showCloseButton: true,
      preConfirm: () => {
        const newRegion = validateRegion();
        if (newRegion)
          return createRegion(newRegion).then((response) => {
            if (response.error) {
              Swal.showValidationMessage(
                `Request failed: ${response.error.data.detail}`
              );
            } else {
              const updateUserObj = {};
              const userAccess = userFullAccess();
              let email = Cookies.get("email");
              updateUserObj.yum_id = email;
              updateUserObj.access = userAccess;
              updateUserObj.active = true;
              updateUserObj.last_logoin_timestamp = "2022-10-11T05:05:59.137Z";
              console.log(updateUserObj);

              updateUserObj.access["dfp-admin"].region.push(
                response.data.region_id
              );
              updateUserObj.access["market-demand"].region.push(
                response.data.region_id
              );
              return updateUser({ body: updateUserObj, id: email });
            }
          });
      },
    });
  };

  useEffect(() => {
    SweetAlerts(responseInfo, `Region added successfully`);
  }, [responseInfo]);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "region_id",
      },
      {
        Header: "Region",
        accessor: "region_name",
      },
      {
        Header: "Brand",
        accessor: "brand.brand_name",
      },
      {
        Header: "Market",
        accessor: "market.market_name",
      },
    ],
    []
  );

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Region Manager"} />
        <div className="card-box">
          {isLoading ? (
            <Loader />
          ) : error ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant administrator
              </Alert>
            </>
          ) : (
            <>
              <Row className="mt-2 no-gutters">
                <Col xs={10}>
                  <PostPerPage
                    limit={postsPerPage}
                    setPostPerPage={setPostPerPage}
                    setCurrentPage={setCurrentPage}
                  />
                </Col>
                <Col xs={2}>
                  <Tablet>
                    <TableButton handleClick={addRegion}>
                      <IoMdAddCircle /> <span> Add New</span>
                    </TableButton>
                  </Tablet>
                  <Mobile>
                    <div onClick={addRegion} className="addNewBtn">
                      <Add />
                    </div>
                  </Mobile>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div className="mt-3">
                    <ReactTable
                      columns={columns}
                      data={data}
                      pageRows={postsPerPage}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

const validateRegion = () => {
  const regionName = document.getElementById("regionName").value;
  const brand = document.getElementById("brand").value;
  const market = document.getElementById("market").value;
  if (isEmpty(regionName)) {
    return MySwal.showValidationMessage(`Please enter region name`);
  }
  if (isEmpty(market)) {
    return MySwal.showValidationMessage(`Please enter market`);
  }
  if (isEmpty(brand)) {
    return MySwal.showValidationMessage(`Please select brand`);
  }

  return {
    region_name: regionName,
    brand_id: brand,
    market_id: market,
  };
};

export default RegionManager;
