import { createSlice } from "@reduxjs/toolkit";
import { defaultRegion } from "../utils/utils";

export const regionSlice = createSlice({
  name: "region",
  initialState: defaultRegion(),
  reducers: {
    saveRegion: (state, action) => action.payload,
  },
});

export const { saveRegion } = regionSlice.actions;
