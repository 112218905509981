import { current } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpBaseUrl } from "../constants/constants";

export const cronApi = createApi({
  reducerPath: "cronApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getCrons: builder.query({
      query: (uid) => `schedule/list/${uid}`,
      providesTags: (result) => {
        const res = result
          ? [
              ...result.map(({ schedule_id }) => ({
                type: "Crons",
                schedule_id,
              })),
              { type: "Crons", schedule_id: "LIST" },
            ]
          : [{ type: "Crons", schedule_id: "LIST" }];

        return res;
      },
      // transformResponse: (response) => console.log(response),
    }),
    addCron: builder.mutation({
      query: (body) => ({
        url: `schedule/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Crons"],
    }),
    updateCron: builder.mutation({
      query: (body) => ({
        url: `schedule/${body.schedule_id}/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        const patchResult = dispatch(
          cronApi.util.updateQueryData("getCrons", body.uuid, (draft) => {
            const copy = current(draft);
            const updated = copy.map((item) => {
              if (item.schedule_id === body.schedule_id) {
                return body;
              } else {
                return item;
              }
            });

            Object.assign(draft, updated);
            // let newArr = [...draft];
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetCronsQuery,
  useAddCronMutation,
  useUpdateCronMutation,
  useLazyGetCronsQuery,
} = cronApi;
