import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import styles from "./call_schedule.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { filter } from "smart-array-filter";
import {
  addQueryParams,
  convert,
  convertArrValToString,
  filterObject,
  isEmpty,
  removeQueryParams,
} from "../../utils/utils";

import { Link, useParams, useSearchParams } from "react-router-dom";

import {
  Desktop,
  Mobile,
} from "../../Componenets/react-responsive/responsiveDevices";
import ReactTable from "../../Componenets/ReactTable/ReactTable";

import { useGetCallSchedulesQuery } from "../../service/callScheduleService";
import { useSelector } from "react-redux";

const generateDynamicUrl = (orderDate, deliveryDate, status, tracking) => {
  const params = [
    orderDate && `order_date=${orderDate}`,
    deliveryDate && `delivery_date=${deliveryDate}`,
    status && `order_status=${status}`,
    tracking && `tracking_number=${tracking}`,
  ]
    .filter(Boolean)
    .join("&");
  console.log(params);
  return params;
};
const CallScheduleScreen = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(20);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const region = useSelector((state) => state.region);

  const [searchParams] = useSearchParams();
  const srchTxt = searchParams.get("searchText");
  const orderDateUrl = searchParams.get("orderDate");
  const delDateUrl = searchParams.get("delDate");
  const selectedStatusUrl = searchParams.get("selectedStatus");
  const trackingUrl = searchParams.get("tracking");

  console.log(orderDateUrl);

  const [orderDate, setOrderDate] = useState(
    orderDateUrl && new Date(orderDateUrl)
  );
  const [deliveryDate, setDeliveryDate] = useState(
    delDateUrl && new Date(delDateUrl)
  );
  // const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(selectedStatusUrl);
  const [tracking, setTracking] = useState(trackingUrl);

  const { store_id: storeId, stores } = useParams();

  const [searchText, setSearchText] = useState(srchTxt);

  const { data, error, isFetching } = useGetCallSchedulesQuery({
    storeId,
    url: generateDynamicUrl(
      convert(orderDate),
      convert(deliveryDate),
      selectedStatus,
      tracking
    ),
  });

  const getSearchedPosts = (data) => {
    const result = convertArrValToString(data);
    let matchingRes = filter(result, {
      keywords: searchText,
      includePaths: [
        "order_date",
        "order_time",
        "delivery_date",
        "delivery_time",
        "order_status",
        "tracking_number",
        "route",
      ],
      excludedPaths: [],
      caseSensitive: false,
    });
    setFilteredPosts(matchingRes);
  };

  useEffect(() => {
    setCurrentPage(1);
    if (data) {
      if (!isEmpty(searchText) && data.length > 0) {
        getSearchedPosts(data);
      } else if (data.length > 0) {
        setFilteredPosts([...data]);
      } else {
        setFilteredPosts([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  useEffect(() => {
    const obj = {
      orderDate,
      deliveryDate,
      selectedStatus,
      tracking,
      searchText,
    };
    const filterObj = filterObject(obj);
    let url = window.location.href;
    url = removeQueryParams(url);
    let refresh = addQueryParams(filterObj, url);
    window.history.replaceState({ path: refresh }, "", refresh);
  }, [orderDate, deliveryDate, selectedStatus, tracking, searchText]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Distributor ID",
        accessor: "distributor_id",
      },
      {
        Header: "Order Date",
        accessor: "order_date",
        Cell: ({ cell }) =>
          cell.row.original.order_date &&
          cell.row.original.order_date.split("T")[0],
      },
      {
        Header: "Order Time",
        accessor: "order_time",
      },
      {
        Header: "Delivery Date",
        accessor: "delivery_date",
        Cell: ({ cell }) =>
          cell.row.original.delivery_date &&
          cell.row.original.delivery_date.split("T")[0],
      },
      {
        Header: "Delivery Time",
        accessor: "delivery_time",
      },
      {
        Header: "Order Status",
        accessor: "order_status",
      },
      {
        Header: "Tracking Number",
        accessor: "tracking_number",
      },
      {
        Header: "Route",
        accessor: "route",
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell }) => (
          <Link
            to={`/${stores}/orders?orderDate=${new Date(
              cell.row.original.order_date
            )},${new Date(cell.row.original.order_date)}`}
            // onClick={() => handleDetails(item.id)}
          >
            View Details
          </Link>
        ),
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Call Schedules"} />
        <div className="card-box">
          <Row className="mt-2 no-gutters">
            <Col xs={12} sm={3} className={styles.datePickerWrapper}>
              <label
                htmlFor="store-filter-search-string"
                className={`no-space-break`}
              >
                Order Date
              </label>
              <DatePicker
                onChange={(update) => {
                  setOrderDate(update);
                }}
                selected={orderDate}
                className={
                  styles.datePickerIp + " form-control form-control-sm"
                }
                isClearable={true}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown={true}
              />
            </Col>
            <Col xs={12} sm={3} className={styles.datePickerWrapper}>
              <label
                htmlFor="store-filter-search-string"
                className={`no-space-break`}
              >
                Delivery Date
              </label>
              <DatePicker
                onChange={(update) => {
                  console.log(update);
                  setDeliveryDate(update);
                }}
                selected={deliveryDate}
                className={
                  styles.datePickerIp + " form-control form-control-sm"
                }
                isClearable={true}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown={true}
              />
            </Col>
            <Col xs={3}>
              <label
                className={styles.distName}
                style={{ fontSize: "16px", fontWeight: 400 }}
              >
                Status
                <select
                  name="datatable_length"
                  aria-controls="datatable"
                  className={`custom-select custom-select-sm  form-control form-control-sm`}
                  style={{
                    width: "inherit",
                    display: "inline-block",
                    margin: "0 5px",
                  }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSelectedStatus(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option value="DUE">DUE</option>
                  <option value="SHIPPED">SHIPPED</option>
                  <option value="STARTED">STARTED</option>
                  <option value="CONFIRMED_ACK">CONFIRMED_ACK</option>
                  <option value="NA">NA</option>
                  <option value="PLACED">PLACED</option>
                </select>
              </label>
            </Col>
            <Col xs={3}>
              <label className={styles.distName}>
                Tracking
                <input
                  type="number"
                  id="tracking"
                  name="tracking"
                  value={tracking}
                  className={styles.tracking + "  form-control form-control-sm"}
                  placeholder={"Tracking Number"}
                  onChange={(e) => setTracking(e.target.value)}
                />
              </label>
            </Col>
          </Row>

          <Row className="mt-3 no-gutters">
            <Col lg={9} md={9} xs={6}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>

            <Desktop>
              <Col xs={3}>
                <div className={styles.searchWrapper}>
                  <SearchFilter
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Desktop>

            <Mobile>
              <Col xs={12}>
                <div className="mt-3 mb-3">
                  <SearchFilter
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Mobile>
          </Row>
          {isFetching ? (
            <Loader className="mt-2 " />
          ) : error ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="mt-3">
                  <ReactTable
                    columns={columns}
                    data={filteredPosts}
                    pageRows={postsPerPage}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

export default CallScheduleScreen;
