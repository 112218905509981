import React, { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./Componenets/Header/Header";

import ConfigEditor from "./Screens/ConfigEditor/ConfigEditor";
import ConfigManager from "./Screens/ConfigManager/ConfigManager";
import HomeScreen from "./Screens/HomeScreen/HomeScreen";
import LineItems from "./Screens/OrderDetailsScreen/LineItems";
import OrdersScreen from "./Screens/OrdersScreen/OrdersScreen";
import RegionManager from "./Screens/RegionManager/RegionManager";
import StoreDetailsScreen from "./Screens/StoreDetailsScreen/StoreDetailsScreen";
import StoresScreen from "./Screens/StoresScreen/StoresScreen";
import SupplyDemand from "./Screens/SupplyDemandScreen/SupplyDemand";
import UserManager from "./Screens/UserManager/UserManager";

import Cookies from "js-cookie";

import { loginPath, subDomain } from "./constants/constants";

import { useSelector } from "react-redux";
import DistributorsDetials from "./Screens/DistributorDetails/DistributorDetails";
import DistributorsScreen from "./Screens/DistributorsScreen/DistributorsScreen";
import InventoryScreen from "./Screens/Inventory/InventoryScreen";
import SupplyDistributors from "./Screens/SupplyDistributors/SupplyDistributors";
import { checkVal, userFullAccess } from "./utils/utils";

// const HomeScreen = React.lazy(() => import("./Screens/HomeScreen/HomeScreen"));
// const UserManager = React.lazy(() =>
//   import("./Screens/UserManager/UserManager")
// );
// const RegionManager = React.lazy(() =>
//   import("./Screens/RegionManager/RegionManager")
// );

// const StoresScreen = React.lazy(() =>
//   import("./Screens/StoresScreen/StoresScreen")
// );

// const ConfigManager = React.lazy(() =>
//   import("./Screens/ConfigManager/ConfigManager")
// );
// const ConfigEditor = React.lazy(() =>
//   import("./Screens/ConfigEditor/ConfigEditor")
// );

// const StoreDetailsScreen = React.lazy(() =>
//   import("./Screens/StoreDetailsScreen/StoreDetailsScreen")
// );
// const OrdersScreen = React.lazy(() =>
//   import("./Screens/OrdersScreen/OrdersScreen")
// );
// const SupplyDemand = React.lazy(() =>
//   import("./Screens/SupplyDemandScreen/SupplyDemand")
// );

// const LineItems = React.lazy(() =>
//   import("./Screens/OrderDetailsScreen/LineItems")
// );import DistributorsDetials from './Screens/DistributorDetails/DistributorDetails';
import OrderGuide from "./Screens/OrderGuide/OrderGuide";
import CallScheduleScreen from "./Screens/CallSchedule/CallScheduleScreen";
import BuffersScreen from "./Screens/Buffers/BuffersScreen";
import StoreBuffersScreen from "./Screens/StoreBuffers/StoreBuffersScreen";
import ActiveStores from "./Screens/SupplyDmdActiveStores/ActiveStores";
import AddActiveStores from "./Screens/AddActiveStores/AddActiveStores";
import RecomendedOrdering from "./Screens/RoScreen/RecomendedOrdering";
import Platform from "./Platform/Platform";
import AIbot from "./Screens/AIBot/Aibot";

// const RedirectHome = () => {
//   const navigate = useNavigate();

//   React.useEffect(() => {
//     navigate("/dfp-admin", { replace: true });
//   }, [navigate]);

//   return null;
// };

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  console.log(isAdmin);
  const userAccess = useSelector((state) => state.userAccess);

  useEffect(() => {
    if (!isAuth) {
      const accessToken = Cookies.get("jwt_access_token");
      if (!accessToken) {
        window.location.href = loginPath;
      } else {
        const access = userFullAccess();
        if (
          access &&
          access["dfp-admin"] &&
          access["dfp-admin"].execute.length === 0 &&
          access["dfp-admin"].read.length === 0 &&
          access["dfp-admin"].write.length === 0 &&
          access["dfp-admin"].region.length === 0 &&
          access.helios.access
        ) {
          console.log("AIBOt");
          window.location.href = "https://aibot.yumds.com/";
        } else if (!access.helios || !access.helios.access) {
          console.log("no helios");
          setIsAuth(true);
          setIsAdmin(true);
        } else {
          console.log("home");
          setIsAuth(true);
        }
      }
    }
  }, [isAuth]);

  return (
    <>
      {isAuth && (
        <Router
          basename={
            window.location.pathname === "/home" ||
            window.location.pathname === "/"
              ? ""
              : subDomain
          }
        >
          {window.location.pathname === "/home" ||
          window.location.pathname === "/" ? null : (
            <Header />
          )}
          <Routes>
            {/* { && subDomainoute path="/" element={<RedirectHome />} />} */}

            <Route path="/supply-demand" element={<SupplyDemand />} />

            {/* <Route path="/home" element={<Platform />} /> */}

            <Route
              path="/supply-demand/:region/active-stores"
              element={<ActiveStores />}
            />

            <Route
              path="/supply-demand/:region/add-active-stores"
              element={<AddActiveStores />}
            />

            <Route
              path="/:region/distributors"
              element={<SupplyDistributors />}
            />
            <Route
              path="/distributors/:region/:distId"
              element={<DistributorsDetials />}
            />

            <Route
              path="/:region/distributors/order-guide/:storeId/:distId"
              element={<OrderGuide />}
            />

            {/* <Route
              path="/supply-demand"
              element={
                <Suspense
                  fallback={
                    <div className="mt-5">
                      <Loader />
                    </div>
                  }
                >
                  <SupplyDemand />
                </Suspense>
              }
            /> */}

            {checkVal(userAccess, "stores") && (
              <Route path="/stores" element={<StoresScreen />} />
            )}

            <Route path="/ro" element={<RecomendedOrdering />} />

            {/* {checkVal(userAccess, "stores") && (
              <Route
                path="/stores"
                element={
                  <Suspense
                    fallback={
                      <div className="mt-5">
                        <Loader />
                      </div>
                    }
                  >
                    <StoresScreen />
                  </Suspense>
                }
              />
            )} */}

            {checkVal(userAccess, "stores") && (
              <Route
                path="/stores/:region/:chainedStoreId/"
                exact
                element={<StoreDetailsScreen />}
              />
            )}

            {/* {checkVal(userAccess, "stores") && (
              <Route
                path="/stores/:region/:chainedStoreId/"
                exact
                element={
                  <Suspense
                    fallback={
                      <div className="mt-5">
                        <Loader />
                      </div>
                    }
                  >
                    <StoreDetailsScreen />
                  </Suspense>
                }
              />
            )} */}

            <Route path="/:stores/orders/" exact element={<OrdersScreen />} />

            {/* <Route
              path="/:stores/orders/"
              exact
              element={
                <Suspense
                  fallback={
                    <div className="mt-5">
                      <Loader />
                    </div>
                  }
                >
                  <OrdersScreen />
                </Suspense>
              }
            /> */}

            <Route
              path=":region/:stores/inventory/"
              exact
              element={<InventoryScreen />}
            />

            <Route
              path=":region/:stores/distributors/:store_id"
              exact
              element={<DistributorsScreen />}
            />

            <Route
              path=":region/:stores/call-schedules/:store_id"
              exact
              element={<CallScheduleScreen />}
            />

            <Route
              path=":region/:stores/buffers/:store_id"
              exact
              element={<StoreBuffersScreen />}
            />

            <Route path=":region/buffers" exact element={<BuffersScreen />} />

            <Route
              path="/:stores/orders/order-details"
              exact
              element={<LineItems />}
            />

            {/* <Route
              path="/:stores/orders/order-details"
              exact
              element={<LineItems />}
            />

            {/* <Route
              path="/:stores/orders/order-details"
              exact
              element={
                <Suspense
                  fallback={
                    <div className="mt-5">
                      <Loader />
                    </div>
                  }
                >
                  <LineItems />
                </Suspense>
              }
            /> */}

            {checkVal(userAccess, "config", true) && (
              <Route path="/config-manager/" element={<ConfigManager />} />
            )}

            <Route path="/config-editor/" element={<ConfigEditor />} />

            <Route
              path="/config-editor/:region/:uid"
              forceRefresh={true}
              element={<ConfigEditor key={Math.random()} />}
            />

            {checkVal(userAccess, "region") && (
              <Route path="/region-manager" element={<RegionManager />} />
            )}

            {/* {checkVal(userAccess, "region") && (
              <Route
                path="/region-manager"
                element={
                  <Suspense
                    fallback={
                      <div className="mt-5">
                        <Loader />
                      </div>
                    }
                  >
                    <RegionManager />
                  </Suspense>
                }
              />
            )} */}

            {checkVal(userAccess, "user_manager") && (
              <Route path="/user-manager" element={<UserManager />} />
            )}

            {/* {checkVal(userAccess, "user_manager") && ( */}
            <Route path="/aibot" element={<AIbot />} />
            {/* )} */}

            {/* {checkVal(userAccess, "user_manager") && (
              <Route
                path="/user-manager"
                element={
                  <Suspense
                    fallback={
                      <div className="mt-5">
                        <Loader />
                      </div>
                    }
                  >
                    <UserManager />
                  </Suspense>
                }
              />
            )} */}
            {window.location.pathname === "/" && (
              <Route
                path="/"
                exact
                key={Math.random()}
                element={<Platform isAdmin={isAdmin} />}
              />
            )}

            <Route path="/" element={<HomeScreen />} />

            {/* <Route
              exact
              path="/"
              key={Math.random()}
              element={
                <Suspense
                  fallback={
                    <div className="mt-5">
                      <Loader />
                    </div>
                  }
                >
                  <HomeScreen />
                </Suspense>
              }
            /> */}
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
