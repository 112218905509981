import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import TableButton from "../../Componenets/TableButtons/TableButton";
import TableTitle from "../../Componenets/TableTitle/TableTitle";
import {
  storesApi,
  useGetStoresDetailsQuery,
} from "../../service/storeService";
import { humanize } from "../../utils/utils";
import styles from "./storedetails.module.css";

const StoreDetailsScreen = (props) => {
  const { region, chainedStoreId } = useParams();

  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const searchText = searchParams.get("searchText");

  const [store, setstore] = useState();
  const [skip, setSkip] = useState(true);

  const [data, setData] = useState([]);

  const { data: storeCachedData } = useSelector(
    storesApi.endpoints.getPaginatedStores.select({
      region: Number(region),
      page: Number(page),
      searchTxt: searchText,
    })
  );

  const {
    data: storeData,
    error,
    isLoading,
  } = useGetStoresDetailsQuery(
    { region, chained_store_id: chainedStoreId },
    {
      skip,
    }
  );

  useEffect(() => {
    if (storeCachedData || (storeData && storeData.length > 0)) {
      setData(storeCachedData ? storeCachedData.items : storeData);
    } else {
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeCachedData, storeData]);

  useEffect(() => {
    if (data && data.length > 0) {
      const matchingStore = data.find(
        (item) => item.chained_store_id === chainedStoreId
      );
      setstore(matchingStore);
    }
  }, [chainedStoreId, data]);

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Store Details"} />
        <div className="card-box">
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Alert variant="danger mt-4">
              Some Error occured. Please contant admin
            </Alert>
          ) : (
            <>
              <Row className="no-gutters">
                <Col xs={12} className="align-self-center">
                  <strong>
                    <TableTitle fontSize={"18px"} title={"Store Details"} />
                  </strong>
                </Col>
              </Row>
              <Row className="mt-2 no-gutters">
                {store &&
                  Object.entries(store).map(([key, value], index) => (
                    <Col xs={6} sm={3} key={index} className="mt-2">
                      <div className={styles.store_details}>
                        <strong>{humanize(key)}</strong>
                        <p>{value}</p>
                      </div>
                    </Col>
                  ))}
              </Row>
            </>
          )}
        </div>

        {store && (
          <div className="mt-5">
            <PageTitle isHome="true" title={"Supply Demand"} />
            <div className="card-box">
              <Row className="no-gutters">
                <Col
                  xs={12}
                  className={`action-links ${styles.store_act_btns}`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TableButton>
                    <Link to={`/${chainedStoreId}/orders`}>
                      <span>Orders</span>
                    </Link>
                  </TableButton>
                  <TableButton>
                    <Link to={`/${region}/${chainedStoreId}/inventory`}>
                      <span>Inventory</span>
                    </Link>
                  </TableButton>
                  <TableButton>
                    <Link
                      to={`/${region}/${chainedStoreId}/buffers/${store.store_id}`}
                    >
                      <span>Buffers</span>
                    </Link>
                  </TableButton>
                  <TableButton>
                    <Link
                      to={`/${region}/${chainedStoreId}/distributors/${store.store_id}`}
                    >
                      <span>Distributors</span>
                    </Link>
                  </TableButton>
                  <TableButton>
                    <Link
                      to={`/${region}/${chainedStoreId}/call-schedules/${store.store_id}`}
                    >
                      <span>Call Schedules</span>
                    </Link>
                  </TableButton>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default StoreDetailsScreen;
