import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select/async";
import { Add, Minus } from "../../Componenets/Icons/Icons";
import {
  Mobile,
  Tablet,
} from "../../Componenets/react-responsive/responsiveDevices";
import { convert, isEmpty } from "../../utils/utils";

const animatedComponents = makeAnimated();

const forecastTypes = [
  { value: "infer_forecast", label: "Infer Forecast" },
  { value: "learn_forecast", label: "Learn Forecast" },
  { value: "feature_store", label: "Feature Store" },
  { value: "run_forecast", label: "Run Forecast" },
];

const freqOptions = [
  { value: "date", label: "Date" },
  { value: "date_range", label: "Date Range" },
];

const oneDayFromNow = new Date();
oneDayFromNow.setDate(oneDayFromNow.getDate() + 1);

const TriggerConfigForm = ({
  frequency,
  setFrequency,
  frequencyRef,
  setParams,
  type,
  setForecastingType,
}) => {
  const [todayDate, setTodayDate] = useState(new Date());
  const [selectedFreq, setSelectedFreq] = useState(freqOptions[0]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(oneDayFromNow);
  const [forecastType, setForecastType] = useState();

  const [command, setCommand] = useState(`--date ${todayDate}`);

  const [inputFields, setInputFields] = useState([
    { fieldName: "", fieldValue: "" },
  ]);

  useEffect(() => {
    let command = ``;
    if (selectedFreq.value === "date_range" && startDate && endDate) {
      command = `--start_date ${convert(
        startDate.toLocaleDateString("en-US")
      )} --end_date ${convert(endDate.toLocaleDateString("en-US"))}`;
    } else if (todayDate) {
      command = `--date ${convert(todayDate.toLocaleDateString("en-US"))}`;
    }

    if (type === "forecast" && forecastType && forecastType.value) {
      command += ` --forecast_type ${forecastType.value}`;
    }

    inputFields.forEach((item) => {
      if (!isEmpty(item.fieldName) && !isEmpty(item.fieldValue)) {
        const fieldName = item.fieldName.replace(/-/g, "_");
        command += ` --${fieldName} ${item.fieldValue} `;
      }
    });
    setCommand(command);
  }, [
    endDate,
    selectedFreq,
    startDate,
    todayDate,
    inputFields,
    forecastType,
    type,
  ]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ fieldName: "", fieldValue: "" });
    setInputFields(values);
    setParams(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
    setParams(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "fieldName") {
      values[index].fieldName = event.target.value;
    } else {
      values[index].fieldValue = event.target.value;
    }
    setInputFields(values);
    setParams(values);
  };

  const handleFreq = (val) => {
    setFrequency(val.value);
    setSelectedFreq(val);
  };

  const handleForecast = (val) => {
    setForecastType(val);
    setForecastingType(val.value);
    // setSelectedFreq(val);
  };

  return (
    <>
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="frequency">
            <Form.Label>Frequency</Form.Label>
            <AsyncSelect
              value={selectedFreq}
              defaultOptions={freqOptions}
              components={animatedComponents}
              inputId="frequency"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999999999,
                  position: "inherit",

                  border: "1px solid #ced4da",
                  boxShadow: "none",
                }),
              }}
              onChange={handleFreq}
            />
          </Form.Group>
        </Row>
        <Row>
          {selectedFreq.value === "date" ? (
            <Form.Group as={Col} controlId="dates" className="mb-3">
              <Form.Label>Select Date</Form.Label>
              <DatePicker
                selected={todayDate}
                className="form-control"
                onChange={(date) => setTodayDate(date)}
                id="date"
              />
            </Form.Group>
          ) : (
            <>
              <Form.Group
                as={Col}
                xs={12}
                md={6}
                controlId="date-range"
                className="mb-3"
              >
                <Form.Label>Start Date</Form.Label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control"
                  id="start_date"
                />
              </Form.Group>
              <Form.Group
                as={Col}
                xs={12}
                md={6}
                controlId="date-range"
                className="mb-3"
              >
                <Form.Label>End Date</Form.Label>
                <DatePicker
                  // portalId="root-portal"
                  className="form-control"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  minDate={
                    startDate
                      ? new Date(startDate.getTime() + 86400000)
                      : undefined
                  }
                  id="end_date"
                />
              </Form.Group>
            </>
          )}
        </Row>

        {type === "forecast" && (
          <Row className="mb-3">
            <Form.Group as={Col} controlId="forecast">
              <Form.Label>Forecast Type</Form.Label>
              <AsyncSelect
                defaultOptions={forecastTypes}
                value={forecastType}
                components={animatedComponents}
                inputId="frequency"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999999999,
                    position: "inherit",

                    border: "1px solid #ced4da",
                    boxShadow: "none",
                  }),
                }}
                onChange={handleForecast}
              />
            </Form.Group>
          </Row>
        )}

        <>
          {inputFields.map((inputField, index) => (
            <Fragment key={`${inputField}~${index}`}>
              <Row>
                <Form.Group
                  as={Col}
                  xs={12}
                  md={6}
                  controlId="field-name"
                  className="mb-3"
                >
                  <Form.Label>Param Name</Form.Label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="fieldName"
                    value={inputField.fieldName}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  md={6}
                  controlId="field-value"
                  className="mb-3"
                >
                  <Form.Label>Param Value</Form.Label>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="fieldValue"
                      value={inputField.fieldValue}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                    <Tablet>
                      <span style={{ display: "flex" }}>
                        <button
                          className="btn btn-link"
                          type="button"
                          onClick={() => handleAddFields()}
                          style={{ padding: "4px" }}
                        >
                          <Add />
                        </button>
                        {index !== 0 && (
                          <button
                            className="btn btn-link"
                            type="button"
                            disabled={index === 0}
                            onClick={() => handleRemoveFields(index)}
                            style={{ padding: "4px" }}
                          >
                            <Minus />
                          </button>
                        )}
                      </span>
                    </Tablet>
                  </div>
                </Form.Group>
                <Mobile>
                  <span style={{ textAlign: "center" }}>
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={() => handleAddFields()}
                      style={{ padding: "4px" }}
                    >
                      <Add />
                    </button>
                    {index !== 0 && (
                      <button
                        className="btn btn-link"
                        type="button"
                        disabled={index === 0}
                        onClick={() => handleRemoveFields(index)}
                        style={{ padding: "4px" }}
                      >
                        <Minus />
                      </button>
                    )}
                  </span>
                </Mobile>
              </Row>
            </Fragment>
          ))}
        </>
      </Form>
      <Row className="mb-3 mt-3">
        <Form.Group as={Col} className="mb-3 mt-3" controlId="command">
          <strong>
            {" "}
            <Form.Label>Genrated Command</Form.Label>
          </strong>
          <Form.Control
            type="text"
            placeholder="Enter your command directly"
            value={command}
            onChange={(e) => setCommand(e.target.value)}
            as="textarea"
          />
        </Form.Group>
      </Row>
    </>
  );
};

export default TriggerConfigForm;
