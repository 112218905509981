import React, { useEffect } from "react";
import styles from "./platform.module.css";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";
import {
  aibotDomain,
  craveAIDomain,
  dfpAdminUrl,
} from "../constants/constants";

const Platform = ({ isAdmin = false }) => {
  const particlesInit = async (main) => {
    console.log(main);
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    if (isAdmin) window.location.href = dfpAdminUrl;
  }, [isAdmin]);

  return (
    !isAdmin && (
      <div className={styles.platform}>
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            particles: {
              number: {
                value: 60,
                density: {
                  enable: true,
                  value_area: 500,
                },
              },
              color: {
                value: "#b1c900",
              },
              shape: {
                type: "circle",
                stroke: {
                  width: 0,
                  color: "#000000",
                },
                polygon: {
                  nb_sides: 5,
                },
                image: {
                  width: 100,
                  height: 100,
                },
              },
              opacity: {
                value: 0.5,
                random: false,
                anim: {
                  enable: false,
                  speed: 1,
                  opacity_min: 0.1,
                  sync: false,
                },
              },
              size: {
                value: 5,
                random: true,
                anim: {
                  enable: false,
                  speed: 40,
                  size_min: 0.1,
                  sync: false,
                },
              },
              line_linked: {
                enable: true,
                distance: 150,
                color: "#ffffff",
                opacity: 0.4,
                width: 1,
              },
              move: {
                enable: true,
                speed: 4,
                direction: "none",
                random: false,
                straight: false,
                out_mode: "out",
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200,
                },
              },
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
                onclick: {
                  enable: true,
                  mode: "push",
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 400,
                  size: 40,
                  duration: 2,
                  opacity: 8,
                  speed: 3,
                },
                repulse: {
                  distance: 100,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
            config_demo: {
              hide_card: false,
              background_color: "#b61924",
              background_image: "",
              background_position: "50% 50%",
              background_repeat: "no-repeat",
              background_size: "cover",
            },
          }}
        ></Particles>
        <div className="container">
          <div className="row">
            <div className="">
              <div className={styles.pfSelection}>
                <div className={`text-center`}>
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: "30%", height: "auto" }}
                  />
                </div>
                <div className={`text-center ${styles.pfHeading}`}>
                  <h1>Select the platform</h1>
                </div>
                <div className={`text-center ${styles.pfBtns}`}>
                  <div className={styles.pfBtnElm}>
                    <a
                      href={aibotDomain} // Replace with your external URL
                      // target="_blank" // Open in a new tab
                      rel="noopener noreferrer" // Recommended security attributes for external links
                    >
                      <button className={`btn md-btn ${styles.pfBtn}`}>
                        Gen AI
                      </button>
                    </a>
                  </div>
                  <div className={styles.pfBtnElm}>
                    <a href="/dfp-admin">
                      <button className={`btn admin-btn ${styles.pfBtn}`}>
                        DFP Admin
                      </button>
                    </a>
                  </div>
                  <div className={styles.pfBtnElm}>
                    <a href={craveAIDomain}>
                      <button className={`btn admin-btn ${styles.pfBtn}`}>
                        Crave AI
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Platform;
