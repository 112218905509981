import { Switch } from "pretty-checkbox-react";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select/async";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const animatedComponents = makeAnimated();
const AddUserForm = ({ regions, user, setSelectedRegions }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const { regionData, regionLoading } = regions ? regions : null;

  const [regionOptions, setRegionOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDisable, setIsDisable] = useState(false);

  const [configOpts, setConfigOpts] = useState(
    user
      ? user.access["dfp-admin"].read.includes("config")
        ? "read"
        : user.access["dfp-admin"].write.includes("config")
        ? "write"
        : user.access["dfp-admin"].execute.includes("config")
        ? "execute"
        : ""
      : ""
  );
  const [mdOpts, setMdOpts] = useState(
    user
      ? user.access["dfp-admin"].read.includes("market-demand")
        ? "read"
        : user.access["dfp-admin"].write.includes("market-demand")
        ? "write"
        : ""
      : ""
  );
  const [userOpts, setUserOpts] = useState(
    user
      ? user.access["dfp-admin"].write.includes("user_manager")
        ? "write"
        : ""
      : ""
  );

  const [regionOpts, setRegionOpts] = useState(
    user
      ? user.access["dfp-admin"].write.includes("region")
        ? "write"
        : ""
      : ""
  );

  const [schedularOpts, setSchedularOpts] = useState(
    user
      ? user.access["dfp-admin"].execute.includes("scheduler")
        ? "execute"
        : ""
      : ""
  );

  const [storeOpts, setStoreOpts] = useState(
    user
      ? user.access["dfp-admin"].write.includes("stores")
        ? "write"
        : ""
      : ""
  );

  const [heliosOpts, setHeliosOpts] = useState(
    user
      ? user.access.helios
        ? user.access["helios"].access
          ? "write"
          : ""
        : ""
      : ""
  );

  const userRef = useRef();
  const regionsRef = useRef();
  const storesRef = useRef();
  const heliosRef = useRef();

  const mdRef = useRef();
  const cfRef = useRef();

  useEffect(() => {
    if (regionData && regionData.length > 0) {
      regionData.forEach((region) => {
        setRegionOptions((opts) => [
          ...opts,
          { value: region.region_id, label: region.region_name },
        ]);
      });
    }
  }, [regionData]);

  useEffect(() => {
    if (regionData && regionData.length > 0) {
      regionData.forEach((region) => {
        if (
          user &&
          user.access["market-demand"].region.includes(region.region_id)
        ) {
          setSelectedOptions((opts) => [
            ...opts,
            { value: region.region_id, label: region.region_name },
          ]);

          setSelectedRegions((opts) => [
            ...opts,
            { value: region.region_id, label: region.region_name },
          ]);
        }
      });
    }
  }, [regionData, setSelectedOptions, setSelectedRegions, user]);

  const handleCheckbox = (event) => {
    if (event.target.checked) {
      if (event.target.name === "usr_mg") setUserOpts(event.target.value);
      if (event.target.name === "region_mg") {
        setSelectedOptions(regionOptions);
        setSelectedRegions(regionOptions);
        setIsDisable(true);
        setRegionOpts(event.target.value);
      }
      if (event.target.name === "schedular_mg")
        setSchedularOpts(event.target.value);

      if (event.target.name === "stores") setStoreOpts(event.target.value);
      if (event.target.name === "helios") setHeliosOpts(event.target.value);

      if (event.target.name === "market_demand") setMdOpts(event.target.value);
      if (event.target.name === "config_permission")
        setConfigOpts(event.target.value);
    }

    if (!event.target.checked) {
      if (event.target.name === "config_permission") {
        setConfigOpts("");
      }

      if (event.target.name === "market_demand") {
        setMdOpts("");
      }
      if (event.target.name === "usr_mg") setUserOpts("");

      if (event.target.name === "region_mg") {
        setSelectedOptions([]);
        setSelectedRegions([]);
        setIsDisable(false);
        setRegionOpts("");
      }

      if (event.target.name === "stores") setStoreOpts("");
      if (event.target.name === "helios") setHeliosOpts("");

      if (event.target.name === "schedular_mg") setSchedularOpts("");
    }
  };

  const handleChange = (val) => {
    setSelectedOptions(val);
    setSelectedRegions(val);
  };

  return (
    <Form>
      <Row className="mb-3">
        <Form.Group as={Col} className="mb-3" controlId="yum_id">
          <Form.Label>Yum ID</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter yum id"
            defaultValue={(user && user.yum_id) || ""}
            disabled={user && user.yum_id}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="mb-3" controlId="regionId">
          <Form.Label>Select region</Form.Label>
          <AsyncSelect
            value={selectedOptions}
            defaultOptions={regionOptions}
            isMulti={true}
            components={animatedComponents}
            isLoading={regionLoading}
            closeMenuOnSelect={false}
            inputId="regionId"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999999999,
                position: "inherit",

                border: "1px solid #ced4da",
                boxShadow: "none",
              }),
            }}
            onChange={handleChange}
            isDisabled={isDisable}
          />
        </Form.Group>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <div className="table-responsive mt-3">
            <Table
              striped="true"
              bordered="true"
              hover="true"
              className="table table-bordered table-nowrap mb-0 table-striped"
            >
              <Thead>
                <Tr>
                  <th>Module</th>
                  <th>Read</th>
                  <th>Write</th>
                  <th>Execute</th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td style={{ width: isMobile ? "100%" : "40%" }}>
                    {" "}
                    User Manager
                  </Td>
                  <Td></Td>
                  <Td>
                    <Switch
                      shape="fill"
                      color="success"
                      name="usr_mg"
                      value="write"
                      type="checkbox"
                      onChange={(e) => handleCheckbox(e)}
                      defaultChecked={
                        user &&
                        user.access["dfp-admin"].write.includes("user_manager")
                      }
                    ></Switch>
                  </Td>
                  <Td></Td>
                  <input
                    type="hidden"
                    ref={userRef}
                    name="user_permission"
                    id="user_mg"
                    defaultValue={
                      user
                        ? user.access["dfp-admin"].write.includes(
                            "user_manager"
                          )
                          ? "write"
                          : ""
                        : ""
                    }
                    value={userOpts}
                  />
                </Tr>
                <Tr>
                  {}
                  <Td style={{ width: isMobile ? "100%" : "40%" }}>
                    {" "}
                    Region Manager
                  </Td>
                  <Td></Td>
                  <Td>
                    <Switch
                      shape="fill"
                      color="success"
                      name="region_mg"
                      value="write"
                      type="checkbox"
                      onChange={(e) => handleCheckbox(e)}
                      defaultChecked={
                        user &&
                        user.access["dfp-admin"].write.includes("region")
                      }
                    ></Switch>
                  </Td>
                  <Td></Td>
                  <input
                    type="hidden"
                    ref={regionsRef}
                    name="region_permission"
                    id="region_mg"
                    defaultValue={
                      user
                        ? user.access["dfp-admin"].write.includes("region")
                          ? "write"
                          : ""
                        : ""
                    }
                    value={regionOpts}
                  />
                </Tr>
                <Tr>
                  <Td style={{ width: isMobile ? "100%" : "40%" }}> Stores</Td>

                  <Td></Td>
                  <Td>
                    <Switch
                      shape="fill"
                      color="success"
                      name="stores"
                      value="write"
                      type="checkbox"
                      onChange={(e) => handleCheckbox(e)}
                      defaultChecked={
                        user
                          ? user.access["dfp-admin"].write.includes("stores")
                            ? "write"
                            : ""
                          : ""
                      }
                    ></Switch>
                  </Td>
                  <Td></Td>
                  <input
                    type="hidden"
                    ref={storesRef}
                    name="stores_permission"
                    id="stores"
                    defaultValue={
                      user
                        ? user.access["dfp-admin"].write.includes("stores")
                          ? "write"
                          : ""
                        : ""
                    }
                    value={storeOpts}
                  />
                </Tr>
                <Tr>
                  <Td style={{ width: isMobile ? "100%" : "40%" }}>
                    {" "}
                    Market Demand
                  </Td>

                  <Td>
                    <Switch
                      shape="fill"
                      color="success"
                      id="mkt_dmd_read"
                      name="market_demand"
                      value="read"
                      type="checkbox"
                      onChange={(e) => handleCheckbox(e)}
                      checked={mdOpts === "read"}
                      defaultChecked={
                        user &&
                        user.access["dfp-admin"].read.includes("market-demand")
                      }
                    ></Switch>
                  </Td>
                  <Td>
                    <Switch
                      shape="fill"
                      color="success"
                      id="mkt_dmd_write"
                      name="market_demand"
                      checked={mdOpts === "write"}
                      value="write"
                      type="checkbox"
                      onChange={(e) => handleCheckbox(e)}
                      defaultChecked={
                        user &&
                        user.access["dfp-admin"].write.includes("market-demand")
                      }
                    ></Switch>
                  </Td>

                  <Td></Td>
                  <input
                    type="hidden"
                    ref={mdRef}
                    name="md_permission"
                    id="md_permission"
                    defaultValue={
                      user
                        ? user.access["dfp-admin"].read.includes(
                            "market-demand"
                          )
                          ? "read"
                          : user.access["dfp-admin"].write.includes(
                              "market-demand"
                            )
                          ? "write"
                          : ""
                        : ""
                    }
                    value={mdOpts}
                  />
                </Tr>
                <Tr>
                  <Td style={{ width: isMobile ? "100%" : "40%" }}>
                    {" "}
                    Config Manager
                  </Td>
                  <Td>
                    <Switch
                      type="checkbox"
                      shape="fill"
                      value="read"
                      checked={configOpts === "read"}
                      name="config_permission"
                      color="success"
                      id="config_read"
                      onChange={(e) => handleCheckbox(e)}
                      defaultChecked={
                        user && user.access["dfp-admin"].read.includes("config")
                      }
                    ></Switch>
                  </Td>
                  <Td>
                    <Switch
                      shape="fill"
                      type="checkbox"
                      value="write"
                      name="config_permission"
                      color="success"
                      id="config_write"
                      checked={configOpts === "write"}
                      defaultChecked={
                        user &&
                        user.access["dfp-admin"].write.includes("config")
                      }
                      onChange={(e) => handleCheckbox(e)}
                    ></Switch>
                  </Td>
                  <Td>
                    <Switch
                      shape="fill"
                      type="checkbox"
                      value="execute"
                      name="config_permission"
                      color="success"
                      id="config_exec"
                      checked={configOpts === "execute"}
                      defaultChecked={
                        user &&
                        user.access["dfp-admin"].execute.includes("config")
                      }
                      onChange={(e) => handleCheckbox(e)}
                    ></Switch>
                  </Td>
                  <input
                    type="hidden"
                    ref={cfRef}
                    name="cf_permission"
                    id="cf_permission"
                    defaultValue={
                      user
                        ? user.access["dfp-admin"].read.includes("config")
                          ? "read"
                          : user.access["dfp-admin"].write.includes("config")
                          ? "write"
                          : user.access["dfp-admin"].execute.includes("config")
                          ? "execute"
                          : ""
                        : ""
                    }
                    value={configOpts}
                  />
                </Tr>

                <Tr>
                  <Td style={{ width: isMobile ? "100%" : "40%" }}>
                    {" "}
                    Schedular
                  </Td>
                  <Td></Td>
                  <Td></Td>
                  <Td>
                    <Switch
                      shape="fill"
                      color="success"
                      name="schedular_mg"
                      value="execute"
                      type="checkbox"
                      onChange={(e) => handleCheckbox(e)}
                      defaultChecked={
                        user &&
                        user.access["dfp-admin"].execute.includes("scheduler")
                      }
                    ></Switch>
                  </Td>
                  <input
                    type="hidden"
                    ref={regionsRef}
                    name="schedular_permission"
                    id="schedular_permission"
                    defaultValue={
                      user
                        ? user.access["dfp-admin"].execute.includes("scheduler")
                          ? "execute"
                          : ""
                        : ""
                    }
                    value={schedularOpts}
                  />
                </Tr>

                <Tr>
                  <Td style={{ width: isMobile ? "100%" : "40%" }}> Helios</Td>
                  <Td></Td>
                  <Td>
                    <Switch
                      shape="fill"
                      color="success"
                      name="helios"
                      value="write"
                      type="checkbox"
                      onChange={(e) => handleCheckbox(e)}
                      defaultChecked={
                        user &&
                        user.access.helios &&
                        user.access["helios"].access
                      }
                    ></Switch>
                  </Td>
                  <Td></Td>
                  <input
                    type="hidden"
                    ref={heliosRef}
                    name="helios_permission"
                    id="helios_permission"
                    defaultValue={
                      user
                        ? user.access.helios
                          ? user.access["helios"].access
                            ? "write"
                            : ""
                          : ""
                        : ""
                    }
                    value={heliosOpts}
                  />
                </Tr>
              </Tbody>
            </Table>
          </div>
        </Col>
      </Row>
      {heliosOpts && (
        <>
          <Row className="mb-3">
            <Form.Group as={Col} className="mb-3" controlId="brand">
              <Form.Label>Select Brand</Form.Label>
              <Form.Select
                defaultValue={
                  (user && user.access.helios && user.access.helios.brand) || ""
                }
              >
                <option value="">Select</option>
                <option value="kfc">KFC</option>
                <option value="pizzahut">Pizza Hut</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Row className="">
            <Form.Group as={Col} className="mb-3" controlId="config">
              <Form.Label>Enter Config Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Config Id"
                defaultValue={
                  (user && user.access.helios && user.access.helios.config) ||
                  ""
                }
              />
            </Form.Group>
          </Row>
        </>
      )}
    </Form>
  );
};

export default AddUserForm;
