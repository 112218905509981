import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
  useRowSelect,
} from "react-table";
import { Alert, Col, Row } from "react-bootstrap";
import styles from "./reacttable.module.css";
import {
  DOTS,
  usePagination as usePaginationRange,
} from "../../utils/usePaginations";
import React, { useEffect, useMemo } from "react";

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  // const count = preFilteredRows.length;
  // return (
  //   <input
  //     value={filterValue || ""}
  //     onChange={(e) => {
  //       setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
  //     }}
  //     placeholder={`Search ${count} record...`}
  //   />
  // );
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const ReactTable = ({
  columns,
  data,
  pageRows = 10,
  showPagination = true,
  disableFilters = true,
  allowRowSelect = false,
  setSelectedRows,
  getOriginal = false,
  setOrignalValues,
}) => {
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 200, // width is used for both the flex-basis and flex-grow
      maxWidth: 200,
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,

    canPreviousPage,
    canNextPage,

    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,

    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data: data,

      defaultColumn,
      disableFilters: disableFilters,
      defaultCanFilter: false,
      autoResetPage: false,
      autoResetFilters: false,
    },

    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      allowRowSelect &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              // <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              // </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    }

    // useBlockLayout
    // useFlexLayout
  );

  useEffect(() => {
    setPageSize(pageRows);
  }, [pageRows, setPageSize]);

  useEffect(() => {
    if (allowRowSelect) {
      console.log(selectedRowIds);
      setSelectedRows([...Object.keys(selectedRowIds)]);
    }
    console.log();
    if (getOriginal) {
      const originalVal = selectedFlatRows.map((d) => d.original);

      setOrignalValues(originalVal);
    }
  }, [
    allowRowSelect,
    getOriginal,
    selectedFlatRows,
    selectedRowIds,
    setOrignalValues,
    setSelectedRows,
  ]);

  const paginationRange = usePaginationRange({
    currentPage: pageIndex + 1,
    totalCount: rows.length,
    siblingCount: 1,
    pageSize: pageRows,
  });

  return (
    <>
      {/* <Row>
        <Col xs={6}>
          <PostPerPage
            limit={pageSize}
            setPostPerPage={setPageSize}
            // setCurrentPage={setCurrentPage}
          />
        </Col>
        <Col xs={6}>
          <div className={styles.searchWrapper} style={{ float: "right" }}>
            <SearchFilter
              // searchText={searchText}
              // setSearchText={setSearchText}
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        </Col>
      </Row> */}
      <table
        striped="true"
        bordered="true"
        hover="true"
        className="table table-bordered table-nowrap mb-0 table-striped react-table"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}

                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                  <span style={{ margin: "5px" }}>
                    {column.canFilter ? column.render("Filter") : null}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      data-label={headerGroups[0].headers[index].Header}
                      {...cell.getCellProps()}
                      className={cell.column.Header}
                      style={{
                        maxWidth: cell.column.Header !== "Action" && "18%",
                        whiteSpace: cell.column.Header === "Action" && "nowrap",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {allowRowSelect && (
        <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
      )}

      {page && page.length === 0 && (
        <Alert variant="primary mt-4">No resuts found</Alert>
      )}
      {showPagination && (
        <Row className="tble-footer-wrapper mt-3">
          <Col xs={12} sm={6} md={6}>
            <p className={styles.page_msg}>
              {" "}
              Showing {pageIndex * pageSize + 1} to{" "}
              {data.length > (pageIndex + 1) * pageSize
                ? (pageIndex + 1) * pageSize
                : data.length}{" "}
              of {data.length} entries
            </p>
          </Col>

          <Col xs={6} sm={6} md={6} className="text-center">
            <ul className={styles.pagination_container}>
              <li
                className={`${styles.pagination_item} ${
                  pageIndex === 0 ? styles.disabled : ""
                }`}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                &#8249; Prev
              </li>
              {paginationRange.map((pageNumber) => {
                if (pageNumber === DOTS) {
                  return (
                    <li
                      key={pageNumber}
                      className={`dots ${styles.pagination_item}`}
                    >
                      &#8230;
                    </li>
                  );
                }

                return (
                  <li
                    className={`${styles.pagination_item} ${
                      pageNumber === pageIndex + 1 ? styles.selected : ""
                    }`}
                    key={pageNumber}
                    onClick={() => gotoPage(pageNumber - 1)}
                  >
                    {pageNumber}
                  </li>
                );
              })}
              <li
                className={`${styles.pagination_item} ${
                  !canNextPage ? styles.disabled : ""
                }`}
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next &#8250;
              </li>
            </ul>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ReactTable;
