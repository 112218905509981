import React, { useEffect, useState } from "react";

import { Col, Alert, Row, Spinner } from "react-bootstrap";

import Loader from "../../Componenets/Loader/Loader";
import ReactTable from "../../Componenets/ReactTable/ReactTable";
import styles from "./distributordetails.module.css";
import SupplyAddDistributor from "../SupplyDistributors/SupplyAddDistributor";
import { MainButton } from "../../Componenets/buttons/button";
import TableTitle from "../../Componenets/TableTitle/TableTitle";
import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import {
  Desktop,
  Mobile,
} from "../../Componenets/react-responsive/responsiveDevices";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import { useSearchParams } from "react-router-dom";
import { addParams, convertArrValToString, isEmpty } from "../../utils/utils";
import { filter } from "smart-array-filter";
import { useUpdateDistributorMutation } from "../../service/distributorService";

const DistributorsBasicDetials = ({
  kind,
  orderGuideLoading,
  orderGuideError,
  orderGuideData,
  columns,
  isFetching,
  error,
  data,
  region,
  distId,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(20);
  const [filteredPosts, setFilteredPosts] = useState([]);

  const [updateDistributorData, responseInfo] = useUpdateDistributorMutation();

  const [searchParams] = useSearchParams();
  const srchTxt = searchParams.get("searchText");

  const [searchText, setSearchText] = useState(srchTxt);

  const getSearchedPosts = (data) => {
    const result = convertArrValToString(data);
    let matchingRes = filter(result, {
      keywords: searchText,
      includePaths: ["default_gins", "non_integrated_default_order_guides_id"],
      excludedPaths: [],
      caseSensitive: false,
    });
    setFilteredPosts(matchingRes);
  };

  useEffect(() => {
    setCurrentPage(1);
    addParams(searchText);

    if (orderGuideData) {
      if (!isEmpty(searchText) && orderGuideData.length > 0) {
        getSearchedPosts(orderGuideData);
      } else if (orderGuideData.length > 0) {
        setFilteredPosts([...orderGuideData]);
      } else {
        setFilteredPosts([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderGuideData, searchText]);

  const updateDistributor = () => {
    const distributor = validateDistributor();
    distributor.region_id = region;
    distributor.distributor_id = distId;
    if (distributor) {
      updateDistributorData(distributor);
    }
  };

  return (
    <>
      {kind !== "Integrated" && (
        <Col xs={12} md={8}>
          <div className="mt-3 card-box h-100">
            <p style={{ margin: "5px 0" }}>
              <TableTitle title={`${kind} Order Guide`} isHome={true} />
            </p>
            <Row>
              <Col xs={3}>
                <PostPerPage
                  limit={postsPerPage}
                  setPostPerPage={setPostPerPage}
                  setCurrentPage={setCurrentPage}
                />
              </Col>

              <Desktop>
                <Col xs={9}>
                  <div className={styles.searchWrapper}>
                    <SearchFilter
                      searchText={searchText}
                      setSearchText={setSearchText}
                    />
                  </div>
                </Col>
              </Desktop>

              <Mobile>
                <Col xs={12}>
                  <div className="mt-3 mb-3">
                    <SearchFilter
                      searchText={searchText}
                      setSearchText={setSearchText}
                    />
                  </div>
                </Col>
              </Mobile>
            </Row>

            {orderGuideLoading ? (
              <Loader className="mt-2 " />
            ) : orderGuideError ? (
              <>
                <Alert variant="danger mt-4">
                  Some Error occured. Please contant admin
                </Alert>
              </>
            ) : (
              <ReactTable
                columns={columns}
                data={filteredPosts ? filteredPosts : []}
                pageRows={postsPerPage}
              />
            )}
          </div>
        </Col>
      )}

      <Col xs={12} md={kind !== "Integrated" ? 4 : 6}>
        <div
          className="mt-3 card-box h-100"
          style={{ maxHeight: "350px", marginBottom: 0 }}
        >
          {isFetching ? (
            <Loader className="mt-2" />
          ) : error ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <>
              <SupplyAddDistributor
                distributor={data}
                kind={kind}
              ></SupplyAddDistributor>

              <div className={`save-config mt-1 text-center ${styles.cfbtns}`}>
                <MainButton handleClick={updateDistributor}>
                  {responseInfo.isLoading ? (
                    <>
                      Updating
                      <Spinner
                        style={{ marginLeft: "5px" }}
                        animation="border"
                        size="sm"
                      />
                    </>
                  ) : (
                    <>Update Distributor</>
                  )}
                </MainButton>
              </div>
            </>
          )}
        </div>
      </Col>
    </>
  );
};

const validateDistributor = () => {
  const distName = document.getElementById("distName").value;
  const distKind = document.getElementById("distributorKind").value;
  let sourceDistId = document.getElementById("sourceDistId").value;

  if (!distName) {
    document.getElementById("distErr").innerHTML =
      "Please enter distributor name";
  } else {
    document.getElementById("distErr").innerHTML = " ";
  }

  if (!distKind) {
    document.getElementById("distKind").innerHTML =
      "Please enter distributor kind";
  } else {
    document.getElementById("distKind").innerHTML = " ";
  }

  return {
    distributor_name: distName,
    distributor_kind: distKind,
    source_distributor_id: sourceDistId,
  };
};

export default DistributorsBasicDetials;
