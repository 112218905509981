import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { saveRegion } from "../../service/regionSlice";
import { useGetRegionsQuery } from "../../service/regionsService";
import Logo from "../Logo/Logo";
import styles from "./header.module.css";

import { checkVal } from "../../utils/utils";
import { domain } from "../../constants/constants";

const Header = () => {
  const location = useLocation();
  // const navigate = useNavigate();

  const { data, error, isLoading } = useGetRegionsQuery();
  const { region, userAccess } = useSelector((state) => state);
  const [expanded, setExpanded] = useState(false);
  const [expandedSubMenu, setExpandedSubMenu] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (region) => {
    localStorage.setItem("defaultRegion", JSON.stringify(region));
    dispatch(saveRegion(region));
  };

  // const handleLogout = () => {
  //   document.cookie.split(";").forEach(function (c) {
  //     document.cookie = c
  //       .replace(/^ +/, "")
  //       .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  //   });
  //   window.location.assign(loginPath);
  // };

  return (
    <header id="top-nav">
      <Navbar
        collapseOnSelect
        expand="lg"
        className={styles.main_menu}
        expanded={expanded}
      >
        <Container fluid>
          <Link to="/" className="text-decoration-none navbar-brand">
            <Logo />
            <h1 className={styles.brand_name}> DFP ADMIN </h1>
          </Link>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          />
          <Navbar.Collapse
            data-bs-toggle="collapse"
            data-bs-target="#responsive-navbar-nav"
            id="responsive-navbar-nav"
          >
            <Nav className="ms-auto sec_menu_links">
              <Link
                to="/"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                className={`nav-link ${styles.dfp_navlink} ${
                  location.pathname === "/" ? styles.top_nav_active : ""
                } `}
                onClick={() => setExpanded(false)}
              >
                Dashboard
              </Link>
              <Link
                to="/aibot"
                className={`nav-link ${styles.dfp_navlink} ${
                  location.pathname === "/aibot" ? styles.top_nav_active : ""
                }`}
                onClick={() => setExpanded(false)}
              >
                AiBot
              </Link>
              {checkVal(userAccess, "user_manager") && (
                <Link
                  to="/user-manager"
                  className={`nav-link ${styles.dfp_navlink} ${
                    location.pathname === "/user-manager"
                      ? styles.top_nav_active
                      : ""
                  }`}
                  onClick={() => setExpanded(false)}
                >
                  User Manager
                </Link>
              )}
              {checkVal(userAccess, "region") && (
                <Link
                  to="/region-manager"
                  className={`nav-link ${styles.dfp_navlink} ${
                    location.pathname === "/region-manager"
                      ? styles.top_nav_active
                      : ""
                  }`}
                  onClick={() => setExpanded(false)}
                >
                  Region Manager
                </Link>
              )}

              {/* <Link
                to="#"
                className={`nav-link ${styles.dfp_navlink} ${
                  location.pathname === "/region-manager"
                    ? styles.top_nav_active
                    : ""
                }`}
                onClick={(e) => handleLogout()}
              >
                Logout
              </Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className={styles.tool_menu}>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          expanded={expandedSubMenu}
        >
          <Container className={styles.mob_fullWidth}>
            <Nav>
              <select
                aria-label="Region"
                defaultValue={region}
                onChange={(e) => handleChange(Number(e.target.value))}
                className={styles.mobile_regionSel}
              >
                {isLoading ? (
                  <option>Region</option>
                ) : error ? (
                  <option>Some Error Occured. Please contact admin</option>
                ) : (
                  data &&
                  data.map((item) => (
                    <option key={item.region_id} value={item.region_id}>
                      {item.region_name}
                    </option>
                  ))
                )}
              </select>
            </Nav>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() =>
                setExpandedSubMenu(expandedSubMenu ? false : "expanded")
              }
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto sec_menu_links">
                {checkVal(userAccess, "stores") && (
                  <Link
                    to="/stores"
                    className={`nav-link ${styles.tool_link} ${
                      location.pathname === "/stores" ? styles.active : ""
                    }  ${styles.align_left} `}
                    style={{ paddingLeft: "0px !important" }}
                    onClick={() => setExpandedSubMenu(false)}
                  >
                    Stores
                  </Link>
                )}
                {checkVal(userAccess, "config", true) && (
                  <Link
                    to="/config-manager"
                    className={`nav-link ${
                      location.pathname === "/config-manager"
                        ? styles.active
                        : ""
                    } ${styles.tool_link}`}
                    onClick={() => setExpandedSubMenu(false)}
                  >
                    Config Manager
                  </Link>
                )}
                {/* {checkVal(userAccess, "supply-demand") && ( */}
                <Link
                  to="/supply-demand"
                  className={`nav-link ${
                    location.pathname === "/supply-demand" ? styles.active : ""
                  } ${styles.tool_link}`}
                  onClick={() => setExpandedSubMenu(false)}
                >
                  Recommended Ordering
                </Link>
                {/* )} */}
                {checkVal(userAccess, "labour") && (
                  <Link
                    to="/labour"
                    className={`nav-link ${
                      location.pathname === "/labour" ? styles.active : ""
                    } ${styles.tool_link}`}
                    onClick={() => setExpandedSubMenu(false)}
                  >
                    Labor & Suggested Scheduling
                  </Link>
                )}
                {checkVal(userAccess, "production") && (
                  <Link
                    to="/production"
                    className={`nav-link ${
                      location.pathname === "/production" ? styles.active : ""
                    } ${styles.tool_link}`}
                    onClick={() => setExpandedSubMenu(false)}
                  >
                    Production
                  </Link>
                )}

                {/* <Link
                  to={"/ro"}
                  className={`nav-link ${
                    location.pathname === "/ro" ? styles.active : ""
                  } ${styles.tool_link}`}
                  onClick={() => setExpandedSubMenu(false)}
                  rel="noreferrer"
                >
                  Recommended Ordering
                </Link> */}
              </Nav>
              <Nav className="ms-auto">
                <select
                  aria-label="Region"
                  value={region}
                  onChange={(e) => handleChange(Number(e.target.value))}
                  className={styles.regionSel}
                >
                  {isLoading ? (
                    <option>Region</option>
                  ) : error ? (
                    <option>Some Error Occured. Please contact admin</option>
                  ) : (
                    data &&
                    data.map((item) => (
                      <option key={item.region_id} value={item.region_id}>
                        {item.region_name}
                      </option>
                    ))
                  )}
                </select>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
