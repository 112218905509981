import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";

import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import { useGetPaginatedInActiveStoresQuery } from "../../service/storeService";
import styles from "./storesscreen.module.css";

import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

import {
  addQueryParams,
  filterObject,
  removeQueryParams,
} from "../../utils/utils";

import { useNavigate, useSearchParams } from "react-router-dom";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { saveStoreTxt } from "../../service/filterSlice";

import ReactTable from "../../Componenets/ReactTable/ReactTable";
import Pagination from "../../Componenets/Pagination/Pagination";
import useDebounce from "../../custom_hooks/use-debounce";
import { MainButton } from "../../Componenets/buttons/button";
import { useAddActiveStoresMutation } from "../../service/activeStoresService";
import { SweetAlerts } from "../../Componenets/SweetAlerts/SweetAlerts";

const AddActiveStores = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(50);
  const region = useSelector((state) => state.region);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const srchTxt = searchParams.get("searchText");
  const { storeSearchText } = useSelector((state) => state.filterSlice);
  const [selectedRows, setSelectedRows] = useState([]);
  const [orignalValues, setOrignalValues] = useState([]);
  const [selectedStoreIds, setSelectedStoreIds] = useState([]);

  const userManagementId = Cookies.get("user_management_id");

  const [searchText, setSearchText] = useState(
    srchTxt ? srchTxt : storeSearchText
  );
  const [filteredPosts, setFilteredPosts] = useState([]);

  const debouncedSearchQuery = useDebounce(searchText, 500);

  const { data, error, isFetching } = useGetPaginatedInActiveStoresQuery({
    region,
    page: currentPage,
    searchTxt: debouncedSearchQuery,
  });

  const [addActiveStores, addActiveStoresInfo] = useAddActiveStoresMutation();

  useEffect(() => {
    const obj = {
      searchText,
    };
    const filterObj = filterObject(obj);
    let url = window.location.href;
    url = removeQueryParams(url);
    let refresh = addQueryParams(filterObj, url);
    window.history.replaceState({ path: refresh }, "", refresh);

    dispatch(saveStoreTxt(searchText));
    if (data && data.items.length > 0) {
      setFilteredPosts([...data.items]);
    } else if (data) {
      setFilteredPosts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  useEffect(() => {
    if (orignalValues.length > 0) {
      const storeIds = orignalValues.map((item) => item.store_id);
      setSelectedStoreIds(storeIds);
    }
  }, [orignalValues]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchText]);

  // useEffect(() => {
  //   if (userManagementId && region) setSkip(false);
  // }, [userManagementId, region]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Chain ID	",
        accessor: "chained_store_id",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Franchise",
        accessor: "franchisee",
      },
    ],
    []
  );

  const addToActiveStores = () => {
    const body = { stores: selectedStoreIds };
    addActiveStores(body);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (addActiveStoresInfo && addActiveStoresInfo.isSuccess) {
      navigate(`/supply-demand/${region}/active-stores`);
    } else if (addActiveStoresInfo && addActiveStoresInfo.isError) {
      SweetAlerts(addActiveStoresInfo, `Error Occured!`);
    }
  }, [addActiveStoresInfo, navigate, region]);

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Add Stores"} />
        <div className="card-box">
          <Row className="mt-2 no-gutters">
            <Col xs={12} md={6}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
            <Col xs={12} md={6}>
              <div className={styles.searchWrapper}>
                <SearchFilter
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </div>
            </Col>
          </Row>
          {isFetching ? (
            <Loader />
          ) : error ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="mt-3">
                  <ReactTable
                    columns={columns}
                    data={filteredPosts}
                    pageRows={postsPerPage}
                    showPagination={false}
                    allowRowSelect={true}
                    setSelectedRows={setSelectedRows}
                    getOriginal={true}
                    setOrignalValues={setOrignalValues}
                  />
                </div>
                <Pagination
                  onPageChange={setCurrentPage}
                  totalCount={data && data.total}
                  siblingCount={1}
                  currentPage={currentPage}
                  pageSize={postsPerPage}
                />
              </Col>
              {selectedStoreIds.length > 0 && (
                <div
                  className={`save-config mt-3 text-center ${styles.cfbtns}`}
                >
                  {addActiveStoresInfo.isLoading ? (
                    <MainButton>
                      Updating{" "}
                      <Spinner
                        style={{ marginLeft: "5px" }}
                        animation="border"
                        size="sm"
                      />
                    </MainButton>
                  ) : (
                    <MainButton handleClick={addToActiveStores}>
                      Update
                    </MainButton>
                  )}
                </div>
              )}
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

export default AddActiveStores;
