import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Container, Row, Alert, Spinner } from "react-bootstrap";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import Editor from "@monaco-editor/react";
import { useDropzone } from "react-dropzone";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  useGetYamlQuery,
  useLazyDownloadConfigQuery,
  useRunConfigMutation,
  useSaveYamlMutation,
  useUploadYamlMutation,
} from "../../service/configService";
import Loader from "../../Componenets/Loader/Loader";
import styles from "./configeditor.module.css";
import { MainButton, SecButton } from "../../Componenets/buttons/button";

import { BsCloudDownload } from "react-icons/bs";
import {
  useAddCronMutation,
  useGetCronsQuery,
  useUpdateCronMutation,
} from "../../service/cronService";

import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import TableButton from "../../Componenets/TableButtons/TableButton";
import { IoMdAddCircle } from "react-icons/io";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "antd/dist/antd.min.css";
import { Add, EditConfig, Pause, Play } from "../../Componenets/Icons/Icons";
import CronForm from "./CronForm";
import cronstrue from "cronstrue";
import cron from "cron-validate";
import TriggerConfigForm from "../ConfigManager/TriggerConfigForm";
import {
  convert,
  isEmpty,
  checkWriteAccess,
  checkExecAccess,
} from "../../utils/utils";
import { SweetAlerts } from "../../Componenets/SweetAlerts/SweetAlerts";
import {
  Mobile,
  Tablet,
} from "../../Componenets/react-responsive/responsiveDevices";

import { useSelector } from "react-redux";
import { WarningSweetAlerts } from "../../Componenets/SweetAlerts/WarningSweetAlerts";
import ReactTable from "../../Componenets/ReactTable/ReactTable";
import yaml from "js-yaml";

const MySwal = withReactContent(Swal);

function isValidJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

const ConfigEditor = () => {
  const editorRef = useRef(null);
  const cronValRef = useRef(null);
  const [yamlData, setYamlData] = useState(null);

  const [searchParams] = useSearchParams();

  const configType = searchParams.get("type");

  const { region, uid } = useParams();
  const [skip] = useState(uid ? false : true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(20);
  const [fileError, setFileError] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState(
    "Please select a valid file"
  );
  const { userAccess } = useSelector((state) => state);

  const [filteredPosts, setFilteredPosts] = useState([]);
  const [cronVal, setCronVal] = useState("* * * * *");
  cronValRef.current = cronVal;

  const currentTableData = useMemo(() => {
    if (filteredPosts && filteredPosts.length > 0) {
      const firstPageIndex = (currentPage - 1) * postsPerPage;
      const lastPageIndex = firstPageIndex + postsPerPage;
      return filteredPosts.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, filteredPosts, postsPerPage]);

  const { data, error, isFetching } = useGetYamlQuery(
    { region, uid },
    { skip }
  );

  const {
    data: cronData,
    error: cronErr,
    isFetching: cronLoading,
  } = useGetCronsQuery(uid, { skip });

  const [trigger] = useLazyDownloadConfigQuery();

  useEffect(() => {
    if (cronData && cronData.length > 0) {
      setFilteredPosts([...cronData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cronData]);

  const [activeTab, setActiveTab] = useState(uid ? "editor" : "upload");
  const [publishYaml, publishYamlInfo] = useSaveYamlMutation();
  const [uploadYamlFile, uploadYamlInfo] = useUploadYamlMutation();
  const [addCron] = useAddCronMutation();
  const [updateCron] = useUpdateCronMutation();
  const [selectedFiles, setSelectedFiles] = useState(undefined);

  const onDrop = useCallback(
    (files, rejectedFiles) => {
      setSelectedFiles(files);
      setFileError(false);
      console.log(files);
      let valid = true;
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const data = yaml.load(reader.result);
          if (data) {
            if (!data.metadata) {
              rejectedFiles.push(files);
              setSelectedFiles(undefined);
              setFileError(true);
              setFileErrorMessage("MetaData is required");
            } else if (
              !data.metadata.class_name ||
              !data.metadata.region_id ||
              !data.metadata.version ||
              !data.metadata.name
            ) {
              rejectedFiles.push(files);
              setSelectedFiles(undefined);
              setFileError(true);
              setFileErrorMessage(
                "class_name,region_id,version and name are required under metadata"
              );
            } else {
              setYamlData(data);
            }
          }
        } catch (error) {
          console.error(error);
        }
      };
      reader.readAsText(files[0]);
    },
    [setSelectedFiles, setFileError, setFileErrorMessage, setYamlData]
  );

  const dropzoneRef = createRef();

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
    rejectedFiles,
  } = useDropzone({
    maxFiles: 1,
    accept: {
      "text/yaml": [".yaml", ".yml"],
    },
    onDrop,
  });

  let files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  const saveYaml = () => {
    if (
      checkExecAccess(userAccess, "config") ||
      checkWriteAccess(userAccess, "config")
    ) {
      let type = uid ? "republish" : "publish";
      let valid = false;
      const data = yaml.load(editorRef.current.getValue());

      if (data) {
        if (!data.metadata) {
          setFileError(true);
          setFileErrorMessage("MetaData is required");
        } else if (
          !data.metadata.class_name ||
          !data.metadata.region_id ||
          !data.metadata.version ||
          !data.metadata.name
        ) {
          setFileError(true);
          setFileErrorMessage(
            "class_name,region_id,version and name are required under metadata"
          );
        } else {
          setYamlData(data);
          if (data.metadata.uuid && !uid) {
            MySwal.fire({
              icon: "question",
              html: "<p style='text-align:center'>Uuid is already present. Do you want to republish it?</p>",
              showDenyButton: false,
              showCloseButton: true,
              showCancelButton: false,
              confirmButtonText: "Republish",
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                type = "republish";
                publishYaml({ yaml: editorRef.current.getValue(), type });
              }
            });
          } else {
            // Swal.fire("Changes are not saved", "", "info");
            publishYaml({ yaml: editorRef.current.getValue(), type });
          }
        }
      }
    } else {
      WarningSweetAlerts();
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (uploadYamlInfo.isSuccess) {
      const uuid = uploadYamlInfo.data["UUID saved"];
      setSelectedFiles(undefined);
      if (!uid && uuid) {
        navigate(`/config-editor/1/${uuid}`, { replace: true });
      } else if (yamlData && yamlData.metadata && yamlData.metadata.uuid) {
        navigate(`/config-editor/1/${yamlData.metadata.uuid}`, {
          replace: true,
        });
        setActiveTab("editor");
      } else {
        setActiveTab("editor");
      }
    } else if (uploadYamlInfo.isError) {
      MySwal.fire(
        "",
        <p style={{ textAlign: "center" }}>
          Some error occured. Please contact Admin
        </p>,
        "error"
      );
    }
  }, [navigate, uid, uploadYamlInfo, yamlData]);

  useEffect(() => {
    if (publishYamlInfo.isSuccess && !uid) {
      const uuid = publishYamlInfo.data["UUID saved"];
      setSelectedFiles(undefined);
      if (!uid && uuid) {
        navigate(`/config-editor/1/${uuid}`, { replace: true });
      } else if (yamlData && yamlData.metadata && yamlData.metadata.uuid) {
        navigate(`/config-editor/1/${yamlData.metadata.uuid}`, {
          replace: true,
        });
      }
    } else if (publishYamlInfo.isError) {
      MySwal.fire(
        "",
        <p style={{ textAlign: "center" }}>
          Some error occured. Please contact Admin
        </p>,
        "error"
      );
    }
  }, [navigate, uid, publishYamlInfo, yamlData]);

  const uploadYaml = () => {
    if (
      checkExecAccess(userAccess, "config") ||
      checkWriteAccess(userAccess, "config")
    ) {
      if (!selectedFiles) {
        return setFileError(true);
      }
      let currentFile = selectedFiles[0];
      let formData = new FormData();

      formData.append("file", currentFile, currentFile.name);
      let type = uid ? "republish" : "publish";
      console.log(yamlData);

      if (yamlData && yamlData.metadata && yamlData.metadata.uuid && !uid) {
        MySwal.fire({
          icon: "question",
          html: "<p style='text-align:center'>Uuid is already present. Do you want to republish it?</p>",
          showDenyButton: false,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: "Republish",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            type = "republish";
            uploadYamlFile({ formData, type });
          }
        });
      } else {
        // Swal.fire("Changes are not saved", "", "info");
        uploadYamlFile({ formData, type });
      }
    } else {
      WarningSweetAlerts();
    }
  };

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const handleDownload = async (uuid) => {
    const { isSuccess, data, isError } = await trigger(uuid);

    if (isSuccess && data) {
      let anchor = document.createElement("a");
      let objectUrl = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      );
      anchor.href = objectUrl;
      anchor.download = `${uuid}.yaml`;
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    } else if (isError) {
      WarningSweetAlerts("Some error occurred", "error");
    }
  };

  const [params, setParams] = useState([{ fieldName: "", fieldValue: "" }]);

  const [kwargs, setKwargs] = useState([{ kwargName: "", kwargValue: "" }]);

  const paramsRef = useRef();
  paramsRef.current = params;

  const kwargsRef = useRef();
  kwargsRef.current = kwargs;

  const getParmas = () => paramsRef.current;
  const getKwargs = () => kwargsRef.current;

  const loadSwalFrom = useCallback(
    (
      action = "add",
      currentCronVal,
      scheduleId,
      uid,
      configFilter,
      kwargsFilter
    ) => {
      if (checkExecAccess(userAccess, "scheduler")) {
        setKwargs([{ kwargName: "", kwargValue: "" }]);

        const title = action === "add" ? `Add Cron` : `Edit Cron`;
        const confirmButtonText = action === "add" ? `Save` : `Update`;
        setParams([{ fieldName: "", fieldValue: "" }]);
        setForecastingType(null);
        let type = null;
        const values = [{ fieldName: "", fieldValue: "" }];
        const kwragsFields = [{ kwargName: "", kwargValue: "" }];

        if (action !== "add") {
          let configFiltArr = configFilter;
          let kwargsFilterArr = kwargsFilter;
          let kwargsFilterObject;

          if (kwargsFilter && isValidJSON(kwargsFilter)) {
            kwargsFilterObject = JSON.parse(kwargsFilter);
            kwargsFilterArr = Object.entries(kwargsFilterObject).map(
              ([key, value]) => ({ [key]: value })
            );
          } else {
            kwargsFilterArr = kwargsFilter;
          }

          if (typeof configFilter === "string" && configFilter !== "None") {
            configFiltArr = configFiltArr.replace(/'/g, '"');
            configFiltArr = JSON.parse(configFiltArr);
          }

          // if (typeof kwargsFilter === "string" && kwargsFilter !== "None") {
          //   kwargsFilterArr = kwargsFilterArr.replace(/'/g, '"');
          //   kwargsFilterArr = JSON.parse(kwargsFilterArr);
          // }

          if (
            configFiltArr &&
            Array.isArray(configFiltArr) &&
            configFiltArr.length > 0
          ) {
            values.shift();
            configFiltArr.forEach((item, index) => {
              const key = Object.keys(item)[0];
              if (key !== "forecast_type")
                values.push({
                  fieldName: key,
                  fieldValue: Object.values(item)[0],
                });
              else {
                setForecastingType(Object.values(item)[0]);
                type = Object.values(item)[0];
              }
            });
          }

          if (
            kwargsFilterArr &&
            Array.isArray(kwargsFilterArr) &&
            kwargsFilterArr.length > 0
          ) {
            kwragsFields.shift();
            kwargsFilterArr.forEach((item, index) => {
              const key = Object.keys(item)[0];
              kwragsFields.push({
                kwargName: key,
                kwargValue: Object.values(item)[0],
              });
            });
          }

          setParams(values);
          setKwargs(kwragsFields);
        }

        MySwal.fire({
          title: title,
          html: (
            <CronForm
              setCronVal={setCronVal}
              currentCronVal={currentCronVal}
              type={configType}
              setParams={setParams}
              setForecastingType={setForecastingType}
              params={values}
              forecastingType={type}
              kwrags={kwragsFields}
              setKwargs={setKwargs}
            />
          ),
          confirmButtonText,
          showCloseButton: true,
          focusConfirm: false,
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          preConfirm: () => {
            const cronInputVal = cronValRef.current;
            const cronResult = cron(cronInputVal);
            if (!cronResult.isValid()) {
              return MySwal.showValidationMessage(
                `Please enter valid cron expression`
              );
            }

            if (cronInputVal === "* * * * *") {
              return MySwal.showValidationMessage(
                `This cron value is not allowed`
              );
            }
            const forecast = getSelectedForecastType();

            if (!forecast && configType === "forecast") {
              return MySwal.showValidationMessage(
                `Please select a forecasting type`
              );
            }
            const params = getParmas();

            const kwargs = getKwargs();

            const config_filter = [];
            const kwargs_filter = [];

            kwargs.forEach((item) => {
              if (!isEmpty(item.kwargName) && !isEmpty(item.kwargValue)) {
                kwargs_filter.push({ [item.kwargName]: item.kwargValue });
              }
            });

            params.forEach((item) => {
              if (!isEmpty(item.fieldName) && !isEmpty(item.fieldValue)) {
                config_filter.push({ [item.fieldName]: item.fieldValue });
              }
            });

            if (configType === "forecast")
              config_filter.push({ forecast_type: forecast });

            let finalConfigArr = null;

            console.log("🚀 ~ ConfigEditor ~ config_filter:", config_filter);

            if (
              config_filter &&
              Array.isArray(config_filter) &&
              config_filter.length > 0
            ) {
              for (let i = 0; i < config_filter.length; i++) {
                // Check if the current item is an object and has valid keys
                if (
                  typeof config_filter[i] === "object" &&
                  Object.keys(config_filter[i]).length
                ) {
                  const keys = Object.keys(config_filter[i]);
                  console.log("🚀 ~ Object keys:", keys);
                  if (keys[0] !== "undefined") {
                    finalConfigArr = config_filter; // Set finalConfigArr if conditions are met
                  }
                } else {
                  console.warn(
                    "🚨 ~ config_filter element is not a valid object:",
                    config_filter[i]
                  );
                }
              }
            } else {
              console.log(
                "config_filter is either undefined or not a valid array"
              );
            }

            const data = {
              uuid: uid,
              cron: cronInputVal,
              status: "ACTIVE",
              kwargs: kwargs_filter,
            };

            if (finalConfigArr) {
              data["config_filter"] = finalConfigArr;
            }
            console.log("🚀 ~ ConfigEditor ~ data:", data);

            if (action === "add") {
              return addCron(data).then((response) => {
                if (response.error) {
                  Swal.showValidationMessage(
                    `Request failed: ${response.error.data.detail}`
                  );
                } else {
                  MySwal.fire(
                    "",
                    <p style={{ textAlign: "center" }}>
                      Cron scheduled successfully
                    </p>,
                    "success"
                  );
                }
              });
            } else {
              data.schedule_id = scheduleId;
              return updateCron(data).then((response) => {
                if (response.error) {
                  Swal.showValidationMessage(
                    `Request failed: ${response.error.data.detail}`
                  );
                } else {
                  MySwal.fire(
                    "",
                    <p style={{ textAlign: "center" }}>
                      Cron updated successfully
                    </p>,
                    "success"
                  );
                }
              });
            }
          },
          customClass: {
            container: styles.cron_form,
          },
        });
      } else {
        WarningSweetAlerts();
      }
    },
    [configType, userAccess]
  );

  const updateCronStatus = useCallback(
    (schedule_id, uuid, cron, status, config_filter, kwargs) => {
      if (checkExecAccess(userAccess, "scheduler")) {
        let configFiltArr;
        const data = {
          schedule_id,
          uuid,
          cron,
          status,
        };

        if (typeof config_filter === "string" && config_filter !== "None") {
          configFiltArr = config_filter.replace(/'/g, '"');
          configFiltArr = JSON.parse(configFiltArr);
          data["config_filter"] = configFiltArr;
        }
        // if (configFiltArr === null || configFiltArr === "None") {
        //   configFiltArr = [];
        // }

        const jsonObject = JSON.parse(kwargs);
        const transformedArray = [];

        for (const key in jsonObject) {
          if (jsonObject.hasOwnProperty(key)) {
            const obj = {};
            obj[key] = jsonObject[key];
            transformedArray.push(obj);
          }
        }
        data["kwargs"] = transformedArray;
        updateCron(data);
      } else {
        WarningSweetAlerts();
      }
    },
    [updateCron, userAccess]
  );

  const [frequency, setFrequency] = useState("date");
  const [forecastingType, setForecastingType] = useState();

  const frequencyRef = useRef();
  frequencyRef.current = frequency;

  const forecastingTypeRef = useRef();
  forecastingTypeRef.current = forecastingType;

  const [runConfig, runConfigInfo] = useRunConfigMutation();

  const getSelectedFrequency = () => frequencyRef.current;
  const getSelectedForecastType = () => forecastingTypeRef.current;

  const triggerConfig = () => {
    if (checkExecAccess(userAccess, "config")) {
      setFrequency("date");
      setForecastingType("");

      setParams([{ fieldName: "", fieldValue: "" }]);
      MySwal.fire({
        title: "Trigger Config",
        html: (
          <TriggerConfigForm
            frequency={frequency}
            setFrequency={setFrequency}
            frequencyRef={frequencyRef}
            setParams={setParams}
            type={configType}
            setForecastingType={setForecastingType}
            forecastingType={forecastingType}
          />
        ),
        confirmButtonText: "Trigger",
        focusConfirm: false,
        showLoaderOnConfirm: true,
        showCloseButton: true,
        preConfirm: () => {
          let reqStr = ``;
          const freq = getSelectedFrequency();
          const forecast = getSelectedForecastType();

          if (!forecast && configType === "forecast") {
            return MySwal.showValidationMessage(
              `Please select a forecasting type`
            );
          }
          const command = document.getElementById("command").value;
          if (isEmpty(command)) {
            if (freq === "date_range") {
              const startDate = document.getElementById("start_date").value;
              const endDate = document.getElementById("end_date").value;
              if (isEmpty(startDate) || isEmpty(endDate)) {
                return MySwal.showValidationMessage(
                  `Please enter a valid date`
                );
              } else {
                reqStr += `--start_date ${convert(
                  startDate
                )} --end_date ${convert(endDate)} `;
              }
            } else {
              const date = document.getElementById("date").value;
              if (isEmpty(date)) {
                return MySwal.showValidationMessage(
                  `Please enter a valid date`
                );
              } else {
                reqStr += `--date ${convert(date)} `;
              }
            }
            if (configType === "forecast" && forecast) {
              reqStr += `--forecast_type ${forecast} `;
            }
            const params = getParmas();

            params.forEach((item) => {
              if (!isEmpty(item.fieldName) && !isEmpty(item.fieldValue)) {
                const fieldName = item.fieldName.replace(/-/g, "_");
                reqStr += ` --${fieldName} ${item.fieldValue} `;
              }
            });

            return runConfig({
              uuid: uid,
              params: reqStr,
            });
          } else {
            return runConfig({
              uuid: uid,
              params: command,
            });
          }
        },
        customClass: {
          container: styles.triggerForm,
          actions: styles.triggerAction,
        },
      });
    } else {
      WarningSweetAlerts();
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Cron Expression",
        accessor: "cron",
      },
      {
        Header: "Cron",
        accessor: "region_name",
        Cell: ({ cell }) =>
          cell.row.original.cron
            ? cronstrue.toString(cell.row.original.cron)
            : "",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell }) => (
          <>
            <div
              title="Edit"
              className={styles.action_btn_links}
              style={{
                margin: "5px",
                display: "inline-block",
                cursor: "pointer",
              }}
              onClick={() =>
                loadSwalFrom(
                  "edit",
                  cell.row.original.cron,
                  cell.row.original.schedule_id,
                  cell.row.original.uuid,
                  cell.row.original.config_filter,
                  cell.row.original.kwargs
                )
              }
            >
              <EditConfig />
            </div>

            {cell.row.original.status === "SUSPENDED" ? (
              <div
                title="Edit"
                className={styles.action_btn_links}
                style={{
                  margin: "5px",
                  display: "inline-block",
                  cursor: "pointer",
                }}
                onClick={() =>
                  updateCronStatus(
                    cell.row.original.schedule_id,
                    cell.row.original.uuid,
                    cell.row.original.cron,
                    "ACTIVE",
                    cell.row.original.config_filter,
                    cell.row.original.kwargs
                  )
                }
              >
                <Play />
              </div>
            ) : (
              <div
                title="Edit"
                className={styles.action_btn_links}
                style={{
                  margin: "5px",
                  display: "inline-block",
                  cursor: "pointer",
                }}
                onClick={() =>
                  updateCronStatus(
                    cell.row.original.schedule_id,
                    cell.row.original.uuid,
                    cell.row.original.cron,
                    "SUSPENDED",
                    cell.row.original.config_filter,
                    cell.row.original.kwargs
                  )
                }
              >
                <Pause />
              </div>
            )}
          </>
        ),
        disableSortBy: true,
      },
    ],
    [loadSwalFrom, updateCronStatus]
  );

  useEffect(() => {
    SweetAlerts(runConfigInfo, `Config triggered successfully`);
  }, [runConfigInfo]);

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Config Editor"} />
        {checkExecAccess(userAccess, "config") ||
        checkWriteAccess(userAccess, "config") ? (
          <Row className="mb-5">
            <div className={`config-tabs ${styles.config_tabs}`}>
              <div className="d-flex mt-3">
                <ul className="nav nav-tabs flex-grow-1 flex-nowrap">
                  <li className={`nav-item ${styles.cf_tab}`}>
                    <Link
                      className={`nav-link ${
                        activeTab === "editor" ? styles.active : ""
                      } ${styles.cf_link}`}
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("editor");
                      }}
                    >
                      File Editor
                    </Link>
                  </li>
                  <li className={`nav-item ${styles.cf_tab}`}>
                    <Link
                      className={`nav-link ${
                        activeTab === "upload" ? styles.active : ""
                      } ${styles.cf_link}`}
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("upload");
                      }}
                    >
                      File Upload
                    </Link>
                  </li>
                  {uid && (
                    <li className={`nav-item ${styles.cf_tab}`}>
                      <Link
                        className={`nav-link ${
                          activeTab === "cron" ? styles.active : ""
                        } ${styles.cf_link}`}
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab("cron");
                        }}
                      >
                        Cron Scheduler
                      </Link>
                    </li>
                  )}
                </ul>
                {activeTab !== "cron" && uid && (
                  <span className="ml-auto text-nowrap border-bottom">
                    <Tablet>
                      <div
                        title="Download"
                        className={styles.action_btn_links}
                        rel="noreferrer"
                        style={{ margin: "5px" }}
                        onClick={() => handleDownload(uid)}
                      >
                        <TableButton>
                          <BsCloudDownload style={{ marginRight: "3px" }} />
                          Download
                        </TableButton>
                      </div>
                    </Tablet>
                    <Mobile>
                      <Link
                        title="Download"
                        className={styles.mob_action_btn_links}
                        to={`#`}
                        rel="noreferrer"
                        style={{ margin: "5px" }}
                        onClick={() => handleDownload(uid)}
                      >
                        <TableButton>
                          <BsCloudDownload style={{ marginRight: "3px" }} />
                        </TableButton>
                      </Link>
                    </Mobile>
                  </span>
                )}
              </div>

              {activeTab === "editor" ? (
                <div
                  className={`cf-editor mt-3 ${
                    activeTab === "editor" ? "visible" : "hide"
                  }`}
                >
                  <Col xs={12}>
                    {isFetching ? (
                      <Loader />
                    ) : error ? (
                      <>
                        <Alert variant="danger mt-4">
                          Some Error occured. Please contant admin
                        </Alert>
                      </>
                    ) : (
                      <>
                        <div className="editor">
                          <Editor
                            height="52vh"
                            defaultLanguage="yaml"
                            defaultValue={data}
                            theme="vs-dark"
                            options={{
                              minimap: {
                                enabled: false,
                              },

                              glyphMargin: false,
                              folding: false,
                              lineDecorationsWidth: 0,
                              lineNumbersMinChars: 0,
                              wordWrapColumn: 80,
                              wordWrapMinified: true,
                              autoIndent: "advanced",
                              disableLayerHinting: false,
                              automaticLayout: true,
                            }}
                            onMount={handleEditorDidMount}
                          />
                          <p className={styles.error_text}>
                            {fileError && fileErrorMessage}
                          </p>
                        </div>
                        <div
                          className={`save-config mt-3 text-center ${styles.cfbtns}`}
                        >
                          {uid && (
                            <SecButton handleClick={triggerConfig}>
                              Trigger Config
                            </SecButton>
                          )}
                          {publishYamlInfo.isLoading ? (
                            <MainButton>
                              Updating{" "}
                              <Spinner
                                style={{ marginLeft: "5px" }}
                                animation="border"
                                size="sm"
                              />
                            </MainButton>
                          ) : (
                            <MainButton handleClick={saveYaml}>
                              Update
                            </MainButton>
                          )}
                        </div>
                      </>
                    )}
                  </Col>
                </div>
              ) : activeTab === "upload" ? (
                <div
                  className={`cf-editor mt-3 ${
                    activeTab === "upload" ? "visible" : "hide"
                  }`}
                >
                  <Col xs={12}>
                    <div className="card-box">
                      <div className="row">
                        <div className="col-12">
                          <span className="header-title mb-3">
                            Upload YAML File
                          </span>
                        </div>
                      </div>

                      <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                        <em>(Only .yaml & .yml will be accepted)</em>
                        <button
                          type="button"
                          className={`${styles.file_selector}`}
                          onClick={openDialog}
                        >
                          Select File
                        </button>
                      </div>
                      <aside>
                        <ul>{selectedFiles && files}</ul>
                      </aside>
                      <p className={styles.error_text}>
                        {fileError && fileErrorMessage}
                      </p>
                      <div className="save-config mt-3 text-center">
                        {uploadYamlInfo.isLoading ? (
                          <MainButton>
                            Uploading{" "}
                            <Spinner
                              style={{ marginLeft: "5px" }}
                              animation="border"
                              size="sm"
                            />
                          </MainButton>
                        ) : (
                          <MainButton handleClick={uploadYaml}>
                            Upload
                          </MainButton>
                        )}
                      </div>
                    </div>
                  </Col>
                </div>
              ) : activeTab === "cron" && uid ? (
                <div className="card-box mt-3">
                  <Row className="mt-2 no-gutters">
                    <Col xs={7}>
                      <PostPerPage
                        limit={postsPerPage}
                        setPostPerPage={setPostPerPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </Col>
                    <Col xs={3}></Col>
                    <Col xs={2}>
                      <Tablet>
                        <div
                          onClick={() => loadSwalFrom("add", null, null, uid)}
                        >
                          <TableButton>
                            <IoMdAddCircle /> <span> Add New</span>
                          </TableButton>
                        </div>
                      </Tablet>

                      <Mobile>
                        <div className="addNewBtn">
                          <div
                            to={`#`}
                            onClick={() => loadSwalFrom("add", null, null, uid)}
                          >
                            <Add />
                          </div>
                        </div>
                      </Mobile>
                    </Col>
                  </Row>
                  {cronLoading ? (
                    <Loader />
                  ) : cronErr ? (
                    <>
                      <Alert variant="danger mt-4">
                        Some Error occured. Please contant admin
                      </Alert>
                    </>
                  ) : (
                    <>
                      {cronData && cronData.length > 0 ? (
                        <Row>
                          <Col xs={12}>
                            <div className="mt-3">
                              <ReactTable
                                columns={columns}
                                data={currentTableData ? currentTableData : []}
                                pageRows={postsPerPage}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <Alert variant="primary mt-4">No Cron Jobs Found</Alert>
                      )}
                    </>
                  )}
                </div>
              ) : null}
            </div>
            <></>
          </Row>
        ) : (
          <Alert variant="info mt-4">
            You don't have permission to perform this action. Please contact
            admin.
          </Alert>
        )}
      </Container>
    </div>
  );
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default ConfigEditor;
