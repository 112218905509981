import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpBaseUrl } from "../constants/constants";

export const subNotificationsApi = createApi({
  reducerPath: "subNotificationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),

  endpoints: (builder) => ({
    getSubNotifications: builder.query({
      query: ({ region, date }) =>
        `notification/sub_processes/?region_id=${region}&date=${date}&topic=OrderProcess`,
      providesTags: (result) => {
        const res =
          result && result.length > 0
            ? [
                ...result.map(({ process_id }) => ({
                  type: "SubNotifications",
                  process_id,
                })),
                { type: "SubNotifications", process_id: "LIST" },
              ]
            : [{ type: "SubNotifications", process_id: "LIST" }];

        return res;
      },
    }),
  }),
});

export const { useGetSubNotificationsQuery } = subNotificationsApi;
