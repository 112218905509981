import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { IoMdAddCircle } from "react-icons/io";
import { Add, Delete, Edit } from "../../Componenets/Icons/Icons";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import TableButton from "../../Componenets/TableButtons/TableButton";
import styles from "./usermanager.module.css";
import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import { SweetAlerts } from "../../Componenets/SweetAlerts/SweetAlerts";
import { useGetRegionsQuery } from "../../service/regionsService";
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "../../service/userService";
import {
  checkWriteAccess,
  filterByVal,
  isEmail,
  isEmpty,
  MySwal,
} from "../../utils/utils";
import AddUserForm from "./AddUserForm";

import {
  Desktop,
  Mobile,
  Tablet,
} from "../../Componenets/react-responsive/responsiveDevices";
import { useSelector } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ReactTable from "../../Componenets/ReactTable/ReactTable";
import { WarningSweetAlerts } from "../../Componenets/SweetAlerts/WarningSweetAlerts";

// const MySwal = withReactContent(Swal);

const UserManager = () => {
  const [setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(20);

  const [searchText, setSearchText] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const { userAccess } = useSelector((state) => state);

  const [selectedRegions, setSelectedRegions] = useState([]);
  const selectedRegionsRef = useRef();
  selectedRegionsRef.current = selectedRegions;

  const { data, error, isLoading } = useGetUsersQuery();
  const [createUser, responseInfo] = useCreateUserMutation();
  const [deleteUser, deleteInfo] = useDeleteUserMutation();
  const [updateUser, updateInfo] = useUpdateUserMutation();

  // const currentTableData = useMemo(() => {
  //   if (filteredPosts && filteredPosts.length > 0) {
  //     const firstPageIndex = (currentPage - 1) * postsPerPage;
  //     const lastPageIndex = firstPageIndex + postsPerPage;
  //     return filteredPosts.slice(firstPageIndex, lastPageIndex);
  //   }
  // }, [currentPage, filteredPosts, postsPerPage]);

  const {
    data: regionData,
    error: regionError,
    isLoading: regionLoading,
  } = useGetRegionsQuery();

  const getSelectedRegions = () => selectedRegionsRef.current;

  const loadSwalFrom = useCallback(
    (action = "add", user = null) => {
      const title = action === "add" ? `Add User` : `Edit User`;
      const confirmButtonText = action === "add" ? `Save` : `Update`;
      MySwal.fire({
        title: title,
        html: (
          <AddUserForm
            regions={{ regionData, regionError, regionLoading }}
            selectedOptions={selectedRegions}
            setSelectedRegions={setSelectedRegions}
            user={user}
          />
        ),
        confirmButtonText,
        focusConfirm: false,
        showLoaderOnConfirm: true,
        showCloseButton: true,

        preConfirm: () => {
          let regions = getSelectedRegions();
          const newUser = validateUser(regions);
          console.log(newUser);
          if (newUser && action === "add") {
            setSelectedRegions([]);
            return createUser(newUser);
          } else if (newUser) {
            setSelectedRegions([]);
            return updateUser({ body: newUser, id: user.yum_id });
          }
        },
      });
    },
    [
      createUser,
      regionData,
      regionError,
      regionLoading,
      selectedRegions,
      updateUser,
    ]
  );

  const addUser = async () => {
    checkWriteAccess(userAccess, "user_manager")
      ? loadSwalFrom()
      : WarningSweetAlerts(
          "You don't have permission to add or edit users. Please contact admin."
        );
  };

  const editUser = useCallback(
    (id) => {
      if (checkWriteAccess(userAccess, "user_manager")) {
        const user = data.filter((item) => item.yum_id === id);
        loadSwalFrom("edit", user[0]);
      } else {
        WarningSweetAlerts();
      }
    },
    [data, loadSwalFrom, userAccess]
  );

  useEffect(() => {
    SweetAlerts(responseInfo, `User added successfully`);
  }, [responseInfo]);

  useEffect(() => {
    SweetAlerts(deleteInfo, `User deleted successfully`);
  }, [deleteInfo]);

  useEffect(() => {
    SweetAlerts(updateInfo, `User updated successfully`);
  }, [updateInfo]);

  const getRegionName = useCallback(
    (regionID) => {
      if (regionData && regionData.length > 0) {
        const region = regionData.filter((item) => item.region_id === regionID);
        return region.length > 0 ? region[0].region_name : "";
      }
      return "Loading";
    },
    [regionData]
  );

  useEffect(() => {
    if (!isEmpty(searchText) && data && data.length > 0) {
      const matchingRes = filterByVal(data, searchText);
      setFilteredPosts(matchingRes);
    } else if (data && data.length > 0) {
      setFilteredPosts([...data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  const handleDelete = useCallback(
    (id) => {
      checkWriteAccess(userAccess, "user_manager")
        ? MySwal.fire({
            title: "Are you sure?",
            icon: "warning",
            // showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
            showCloseButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              deleteUser(id);
            }
          })
        : WarningSweetAlerts(
            "You don't have permission to delete any user. Please contact admin"
          );
    },
    [deleteUser, userAccess]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Yum ID	",
        accessor: "yum_id",
      },
      {
        Header: "Status",
        accessor: "active",
        Cell: ({ cell }) => (cell.row.original.active ? "Active" : "Inactive"),
      },
      {
        Header: "Regions",
        accessor: "access",
        Cell: ({ cell }) =>
          cell.row.original.access &&
          cell.row.original.access.hasOwnProperty("dfp-admin") &&
          cell.row.original.access["dfp-admin"].write.includes("region")
            ? "All Regions"
            : cell.row.original.access &&
              cell.row.original.access.hasOwnProperty("dfp-admin") &&
              cell.row.original.access["dfp-admin"].region.map(
                (region, i, items) => {
                  if (i === items.length - 1) return getRegionName(region);
                  return (
                    !isEmpty(getRegionName(region)) &&
                    getRegionName(region) + ", "
                  );
                }
              ),
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell }) => (
          <>
            {" "}
            <Edit handleClick={editUser} id={cell.row.original.yum_id} />
            <Delete handleClick={handleDelete} id={cell.row.original.yum_id} />
          </>
        ),
        disableSortBy: true,
      },
    ],
    [editUser, getRegionName, handleDelete]
  );

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"User Manager"} />
        <div className="card-box">
          <Row className="mt-2 no-gutters">
            <Col lg={5} md={10} xs={10}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
            <Desktop>
              <Col xs={5}>
                <div
                  className={styles.searchWrapper}
                  style={{ float: "right" }}
                >
                  <SearchFilter
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Desktop>
            {regionData && regionData.length > 0 && (
              <Col xs={2}>
                <Tablet>
                  <TableButton handleClick={addUser}>
                    <IoMdAddCircle /> <span> Add New</span>
                  </TableButton>
                </Tablet>
                <Mobile>
                  <div onClick={addUser} className="addNewBtn">
                    <Add />
                  </div>
                </Mobile>
              </Col>
            )}
            <Mobile>
              <Col xs={12}>
                <div className="mt-3 mb-3">
                  <SearchFilter
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Mobile>
          </Row>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="mt-3">
                  <ReactTable
                    columns={columns}
                    data={filteredPosts}
                    pageRows={postsPerPage}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};
const validateUser = (selectedRegions, selectedRegionsRef) => {
  const yumID = document.getElementById("yum_id").value;

  let regionId = selectedRegions.map((region) => region.value);
  regionId = [...new Set(regionId)];

  const userMg = document.getElementById("user_mg").value;
  const regionMg = document.getElementById("region_mg").value;
  const stores = document.getElementById("stores").value;
  const markedDemand = document.getElementById("md_permission").value;
  const configPermission = document.getElementById("cf_permission").value;
  const schedularPermission = document.getElementById(
    "schedular_permission"
  ).value;
  const helios = document.getElementById("helios_permission").value;

  if (isEmpty(yumID) || !isEmail(yumID)) {
    return MySwal.showValidationMessage(`Please enter valid yum id`);
  }
  console.log(regionId);
  if (regionId.length === 0 && !helios) {
    return MySwal.showValidationMessage(`Please select atleast one region`);
  }
  let brand, config;
  if (helios) {
    brand = document.getElementById("brand").value;
    config = document.getElementById("config").value;
    if (isEmpty(brand)) {
      return MySwal.showValidationMessage(
        `Please select the brand for the user`
      );
    }
    if (isEmpty(config)) {
      return MySwal.showValidationMessage(
        `Please enter the config for the user`
      );
    }
  }

  if (
    isEmpty(userMg) &&
    isEmpty(regionMg) &&
    isEmpty(stores) &&
    isEmpty(markedDemand) &&
    isEmpty(configPermission) &&
    isEmpty(schedularPermission) &&
    isEmpty(helios)
  ) {
    return MySwal.showValidationMessage(`Please select atleast one permission`);
  }
  const write = [];
  const read = [];
  const execute = [];

  userMg && write.push("user_manager");
  regionMg && write.push("region");
  stores && write.push("stores");
  // helios && write.push("helios");

  schedularPermission && execute.push("scheduler");

  configPermission === "write" && write.push("config");
  configPermission === "read" && read.push("config");
  configPermission === "execute" && execute.push("config");

  markedDemand === "write" && write.push("market-demand");
  markedDemand === "read" && read.push("market-demand");

  const event = new Date();

  return {
    yum_id: yumID,
    access: {
      "market-demand": {
        access: 1,
        region: regionId,
        execute: [],
        read: [],
        write: [],
      },
      "dfp-admin": {
        region: regionId,
        execute: execute,
        read: read,
        write: write,
      },
      helios: {
        access: helios && true,
        brand,
        config,
      },
    },
    active: true,
    last_logoin_timestamp: event.toISOString(),
  };
};
export default UserManager;
