import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpApi } from "../constants/constants";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpApi,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: ({ region, store, startDate, endDate }) =>
        `/supply/orders/${region}/${store}/${startDate}/${endDate}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Orders", id })),
              { type: "Orders", id: "OrdersList" },
            ]
          : [{ type: "Orders", id: "OrdersList" }],
      // transformResponse: (response) => {
      //   response.forEach((item, i) => {
      //     item.id = i + 1;
      //   });
      //   return response;
      // },
    }),
    getDayOrders: builder.query({
      query: ({ region, store, startDate }) =>
        `/supply/orders/${region}/${store}/${startDate}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Orders", id })),
              { type: "Orders", id: "OrdersList" },
            ]
          : [{ type: "Orders", id: "OrdersList" }],
    }),
  }),
});

export const { useGetOrdersQuery, useLazyGetDayOrdersQuery } = ordersApi;
