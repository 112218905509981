import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Loader from "../../Componenets/Loader/Loader";
import PageTitle from "../../Componenets/PageTitle/PageTitle";
import PostPerPage from "../../Componenets/PostPerPage/PostPerPage";
import SearchFilter from "../../Componenets/SearchFilter/SearchFilter";
import styles from "./distributor.module.css";

import { useDispatch, useSelector } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { filter } from "smart-array-filter";
import { addParams, checkWriteAccess, isEmpty } from "../../utils/utils";

import { IoMdAddCircle } from "react-icons/io";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Add, DeleteIcon, FileIcon } from "../../Componenets/Icons/Icons";
import {
  Desktop,
  Mobile,
  Tablet,
} from "../../Componenets/react-responsive/responsiveDevices";
import ReactTable from "../../Componenets/ReactTable/ReactTable";
import { SweetAlerts } from "../../Componenets/SweetAlerts/SweetAlerts";
import { WarningSweetAlerts } from "../../Componenets/SweetAlerts/WarningSweetAlerts";
import TableButton from "../../Componenets/TableButtons/TableButton";
import {
  useBulkSaveDistributorMutation,
  useDeleteDistributorMutation,
  useGetDistributorQuery,
  useGetRegionalDistributorQuery,
} from "../../service/distributorService";
import { setDistributorTxt } from "../../service/filterSlice";
import AddDistributors from "./AddDistributor";

const MySwal = withReactContent(Swal);

const DistributorsScreen = () => {
  const dispatch = useDispatch();

  const { userAccess } = useSelector((state) => state);
  const { stores, store_id } = useParams();

  const [setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(20);
  const [selectedDist, setSelectedDist] = useState();

  const [distKind, setDistKind] = useState("");

  const selectedDistRef = useRef();
  selectedDistRef.current = selectedDist;
  const getSelectedDist = () => selectedDistRef.current;

  const region = useSelector((state) => state.region);

  const [searchParams] = useSearchParams();
  const srchTxt = searchParams.get("searchText");
  const { distributorSearchText } = useSelector((state) => state.filterSlice);

  const [searchText, setSearchText] = useState(
    srchTxt ? srchTxt : distributorSearchText
  );

  const [filteredPosts, setFilteredPosts] = useState([]);
  const { data, error, isFetching } = useGetDistributorQuery(stores);

  const {
    data: regDistData,
    error: regDistErr,
    isFetching: regDistFetching,
  } = useGetRegionalDistributorQuery(region);

  const [deleteDistributor, deleteInfo] = useDeleteDistributorMutation();

  const [bulkSaveDistributor] = useBulkSaveDistributorMutation();

  const handleDelete = useCallback(
    (store, distributor_id) => {
      console.log(distributor_id, "distributor_id");
      let distributorId = distributor_id;
      checkWriteAccess(userAccess, "stores")
        ? MySwal.fire({
            title: "Are you sure?",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
            showCloseButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              console.log();
              deleteDistributor({ store, distributorId });
            }
          })
        : WarningSweetAlerts(
            "You don't have permission to delete any user. Please contact admin"
          );
    },
    [deleteDistributor, userAccess]
  );

  useEffect(() => {
    SweetAlerts(deleteInfo, `Distributor deleted successfully`);
  }, [deleteInfo]);

  useEffect(() => {
    addParams(searchText);
    dispatch(setDistributorTxt(searchText));
    if ((!isEmpty(searchText) || distKind) && data && data.length > 0) {
      const distributorArr = [];
      data.forEach((item) => distributorArr.push(item));
      const result = distributorArr.map((obj) =>
        Object.fromEntries(
          Object.entries(obj).map(([key, val]) => [key, String(val)])
        )
      );
      let matchingRes = filter(result, {
        keywords: searchText,
        includePaths: [
          "DISTRIBUTOR_ID",
          "DISTRIBUTOR_NAME",
          "DISTRIBUTOR_KIND",
          "STORE_DISTRIBUTOR_ID",
        ],
        excludedPaths: [],
        caseSensitive: false,
      });

      if (distKind) {
        matchingRes = matchingRes.filter(
          (data) => data.DISTRIBUTOR_KIND === distKind
        );
      }
      console.log({ matchingRes, distKind });
      setFilteredPosts(matchingRes);
    } else if (data && data.length > 0) {
      setFilteredPosts([...data]);
    } else if (data) {
      setFilteredPosts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText, distKind]);

  const editDist = useCallback((id) => {}, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Distributor ID",
        accessor: "DISTRIBUTOR_ID",
      },
      {
        Header: "Distributor Name",
        accessor: "DISTRIBUTOR_NAME",
      },
      {
        Header: "Distributor Kind",
        accessor: "DISTRIBUTOR_KIND",
      },
      {
        Header: "Store distributor id",
        accessor: "STORE_DISTRIBUTOR_ID",
      },

      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell }) => (
          <>
            {cell.row.original.DISTRIBUTOR_KIND === "Integrated" && (
              <Link
                to={`/${region}/distributors/order-guide/${store_id}/${cell.row.original.DISTRIBUTOR_ID}`}
                style={{ margin: "5px", cursor: "pointer" }}
              >
                <FileIcon />
              </Link>
            )}

            <span
              onClick={() =>
                handleDelete(store_id, cell.row.original.DISTRIBUTOR_ID)
              }
              style={{ margin: "5px", cursor: "pointer" }}
            >
              <DeleteIcon id={cell.row.original.yum_id} />
            </span>
          </>
        ),
        disableSortBy: true,
      },
    ],
    [handleDelete, region, store_id]
  );

  const addNew = () => {
    MySwal.fire({
      title: `Select Distributor`,
      html: (
        <AddDistributors
          distributors={{ regDistData, regDistErr }}
          setSelectedDist={setSelectedDist}
          storeDist={data}
        />
      ),
      confirmButtonText: `Save`,
      focusConfirm: false,
      showLoaderOnConfirm: true,
      showCloseButton: true,
      preConfirm: () => {
        const selectedDist = getSelectedDist();

        if (selectedDist && selectedDist.length > 0) {
          const data = [];
          selectedDist.forEach((element) => {
            data.push({ distributor_id: element, store_id: store_id });
          });
          return bulkSaveDistributor(data).then((response) => {
            if (response.error) {
              Swal.showValidationMessage(
                `Request failed: Failed to add distributors.`
              );
            } else {
              MySwal.fire(
                "",
                <p style={{ textAlign: "center" }}>
                  Distributors added successfully
                </p>,
                "success"
              );
            }
          });
        } else {
          return MySwal.showValidationMessage(
            `Please select atleast one distributor`
          );
        }
      },
    });
  };

  return (
    <div className="mt-5">
      <Container className="no-gutter-mob">
        <PageTitle title={"Distributors"} />
        <div className="card-box">
          <Row className="mt-2 no-gutters">
            <Col lg={2} md={2} xs={2}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
            <Col xs={4}>
              <label
                className={styles.distName}
                style={{ margin: "0 5px", fontSize: "16px", fontWeight: 400 }}
              >
                Distributor Kind
                <select
                  name="datatable_length"
                  aria-controls="datatable"
                  className={`custom-select custom-select-sm form-control form-control-sm`}
                  style={{
                    width: "auto",
                    display: "inline-block",
                    margin: "0 5px",
                  }}
                  onChange={(e) => setDistKind(e.target.value)}
                  value={distKind}
                >
                  <option value="">Select</option>
                  <option value="Integrated">Integrated</option>
                  <option value="Non-Integrated">Non-Integrated</option>
                </select>
              </label>
            </Col>
            <Desktop>
              <Col xs={3}>
                <div className={styles.searchWrapper}>
                  <SearchFilter
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Desktop>
            {!regDistFetching && (
              <Col xs={3} style={{ float: "right" }}>
                <Tablet>
                  <TableButton handleClick={addNew}>
                    <IoMdAddCircle /> <span> Add New</span>
                  </TableButton>
                </Tablet>
                <Mobile>
                  <div className="addNewBtn">
                    <Add />
                  </div>
                </Mobile>
              </Col>
            )}
            <Mobile>
              <Col xs={12}>
                <div className="mt-3 mb-3">
                  <SearchFilter
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
              </Col>
            </Mobile>
          </Row>
          {isFetching ? (
            <Loader className="mt-2 " />
          ) : error ? (
            <>
              <Alert variant="danger mt-4">
                Some Error occured. Please contant admin
              </Alert>
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="mt-3">
                  <ReactTable
                    columns={columns}
                    data={filteredPosts}
                    pageRows={postsPerPage}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

export default DistributorsScreen;
