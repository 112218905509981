import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import style from "./pagetitle.module.css";

const PageTitle = ({ title, isHome = false, navigateTo }) => {
  let navigate = useNavigate();

  const goHome = () => {
    navigate(-1);
  };
  return (
    <Row xs={12} className="page-title-box mb-1">
      <Col xs={8} className="">
        <h4 className="page-title">{title}</h4>
      </Col>
      {!isHome && window.history.length > 1 && (
        <Col xs={4} className={style.back_wrapper}>
          <span onClick={() => goHome()} className={style.back_btn}>
            <BiArrowBack /> Back
          </span>
        </Col>
      )}
    </Row>
  );
};

export default PageTitle;
