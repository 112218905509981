import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { aibotApiUrl } from "../constants/constants";
import Cookies from "js-cookie";

export const aibotApi = createApi({
  reducerPath: "aibotApi",

  baseQuery: fetchBaseQuery({
    baseUrl: aibotApiUrl,
    // prepareHeaders: (headers, { getState }) => {
    //   const accessToken = Cookies.get("jwt_access_token");
    //   if (accessToken) {
    //     headers.set("Authorization", `Bearer ${accessToken}`);
    //   }
    //   return headers;
    // },
    // credentials: "include",
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getResponses: builder.query({
      query: (queryParams) => `history?${queryParams}`,
    }),
  }),
});

export const { useGetResponsesQuery } = aibotApi;
