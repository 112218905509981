import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dfpBaseUrl } from "../constants/constants";

import Cookies from "js-cookie";

export const bookmarksApi = createApi({
  reducerPath: "bookmarksApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  keepUnusedDataFor: 86400,

  endpoints: (builder) => ({
    getBookmarkedStores: builder.query({
      query: ({ region, userId }) =>
        `new_bookmark_list/${region}/?user_management_id=${userId}`,
      providesTags: (result) => {
        const res = result
          ? [
              ...result.map(({ store_id }) => ({
                type: "Bookmarks",
                store_id,
              })),
              { type: "Bookmarks", store_id: "LIST" },
            ]
          : [{ type: "Bookmarks", store_id: "LIST" }];

        return res;
      },
    }),
    addBookmark: builder.mutation({
      query: ({ body, region }) => ({
        url: `bookmark/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted({ body, region }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          bookmarksApi.util.updateQueryData(
            "getBookmarkedStores",
            { region, userId: body.user_management_id },
            (draft) => {
              console.log(body, "body");
              draft.push(body.store);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    removeBookmark: builder.mutation({
      query: ({ type, value, user_management_id, region }) => ({
        url: `bookmark/${type}/${value}?user_management_id=${user_management_id}`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(
        { type, value, user_management_id, region, store },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          bookmarksApi.util.updateQueryData(
            "getBookmarkedStores",
            { region, userId: user_management_id },
            (draft) => {
              const index = draft.findIndex((item) => {
                return item.store_id === value;
              });
              if (index > -1) {
                draft.splice(index, 1);
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetBookmarkedStoresQuery,
  useAddBookmarkMutation,
  useRemoveBookmarkMutation,
} = bookmarksApi;
