import React from "react";

import styles from "./searchfilter.module.css";

const SearchFilter = ({
  searchText,
  setSearchText,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  // const count = preGlobalFilteredRows.length ? preGlobalFilteredRows.length : 0;
  // const [value, setValue] = React.useState(globalFilter);
  // const onChange = useAsyncDebounce((value) => {
  //   setGlobalFilter(value || undefined);
  // }, 200);

  return (
    <>
      <label
        htmlFor="store-filter-search-string"
        className={styles.searchLabel}
      >
        Search
      </label>
      <input
        id={styles.storesFilter}
        type="text"
        className="mb-2 mr-sm-2"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
          // setValue(e.target.value);
          // onChange(e.target.value);
        }}
      />
    </>
  );
};

export default SearchFilter;
