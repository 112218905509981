import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { dfpApi } from "../constants/constants";

export const inventoryApi = createApi({
  reducerPath: "inventoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dfpApi,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getInventory: builder.query({
      query: ({ store, startDate, endDate }) =>
        `/supply/inventory/${store}/${startDate}?end_date=${endDate}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Inventory", id })),
              { type: "Inventory", id: "InventoryList" },
            ]
          : [{ type: "Inventory", id: "InventoryList" }],
    }),
  }),
});

export const { useGetInventoryQuery } = inventoryApi;
